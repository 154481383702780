.vnd-partners-feedback-wrapper {
    border-radius: $border-radius-20;
    backdrop-filter: blur(3px);
    max-height: 340px;

    .vnd-partner-wrapper {
        display: flex;
        align-items: center;

        h5,
        h6 {
            margin: 0;
            text-transform: capitalize;
        }

        h5 {
            margin-bottom: 5px;
            font-weight: bold;
            text-transform: uppercase;
            color: $dark-color;
        }

        h6 {
            color: $primary-color;
            font-weight: 600;
        }

        .vnd-partner-image-wrapper {
            padding: 2px;
            margin-right: 15px;
            background-color: $white-color;
            border-radius: $border-radius-50;
            // box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.2), -2px -2px 5px rgba($color: $dark-color, $alpha: 0.2);

            .vnd-partner-image {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 65px;
                height: 65px;
                border-radius: $border-radius-50;
            }
        }
    }

    p {
        margin-top: 15px;
        font-size: $font-size-16;
    }
}