// 
// user chat.scss
//

// user chat

.user-chat {
    background-color: $card-bg;
    transition: all .4s;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    transform: translateX(100%);
    z-index: 99;
    box-shadow: 0 0px 10px rgba(0 0 0 / 15%);

    &.user-chat-show {
        transform: translateX(0);
    }

    @media (min-width: 1199.98px) {
        width: calc(100% - 450px);

        &.left-menu-is-open {
            width: calc(100% - 650px);
        }
    }

}

.styled-image {
    border-radius: 8px;
    box-shadow: 0 0 15px 0px rgba(0, 46, 129, 0.3);
}

.user-chat-nav {
    .nav-btn {
        height: 40px;
        width: 40px;
        line-height: 40px;
        box-shadow: none;
        padding: 0;
        font-size: 20px;
        color: $gray-600;
        border-radius: 10px;
    }

    @media (max-width:575.98px) {
        display: flex;
        justify-content: flex-end;
    }
}

.user-profie-top-menu {
    .user-chat-nav {
        display: flex !important;

        li {
            flex: 1;
            gap: 5px;
        }

        .nav-btn {
            width: 100%;
            background-color: $sidebar-bg;
            border: 1px solid $sidebar-bg;
            // transition: 0.3s;

            i {
                color: $white;
            }

            &:hover {
                background-color: transparent;

                i {
                    color: $sidebar-bg;
                }
            }
        }
    }
}

.vnd--dashboard-rigth-side {
    position: relative;
    width: 100%;
    // margin-left: 400px;

    @media (min-width: 1200px) {
        // margin-left: 475px;
        max-width: calc(100vw - 720px);
    }

    @media (max-width: 1199.98px) {
        max-width: calc(100vw - 416px);
    }

    @media (max-width: 991.98px) {
        display: none;
    }
}

.chat-conversation {
    li {
        clear: both;
    }

    .chat-avatar {

        margin: 0 16px 0 0
            /*rtl:0 0 0 16px*/
        ;
        position: absolute;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
    }

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 12px;

        .title {
            background-color: $border-color;
            position: relative;
            font-size: 13px;
            z-index: 1;
            padding: 6px 12px;
            border-radius: 5px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: $border-color;
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }

    .sender {
        .user-chat-content {
            margin-left: 45px;
        }
    }

    .right {
        .chat-avatar {
            right: 0;
        }

        .user-chat-content {
            margin-right: 45px;
        }
    }

    .conversation-list {
        display: inline-flex;
        position: relative;
        align-items: flex-end;

        .ctext-wrap {
            display: flex;
            margin-bottom: 5px;
        }

        .ctext-wrap-content {
            padding: 15px 20px 30px 20px;
            background-color: $gray-100;
            border-radius: 8px;
            color: $white;
            position: relative;
            max-width: 500px;
            min-width: 100px;

            .message {
                font-size: 15px;
                color: $gray-700;
                font-weight: 300;
            }
        }

        .conversation-name {
            font-weight: $font-weight-medium;
            font-size: 14px;
        }

        .dropdown {
            .dropdown-toggle {
                font-size: 18px;
                padding: 4px;
                color: $gray-600;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

        }

        .chat-time {
            font-size: 11px;
            margin-top: 4px;
            text-align: right;
            display: flex;
            align-items: center;
            color: $gray-600;
            position: absolute;
            font-weight: 400;
            right: 10px;
            bottom: 5px;

            i {
                margin-right: 3px;
            }

            .ri-check-line {
                font-size: 14px;
            }
        }

        .message-img {
            border-radius: .2rem;
            position: relative;

            .message-img-single {
                width: 100% !important;
            }

            .message-img-list {
                position: relative;
                border: 1px solid $gray-500;
                border-radius: 5px;
                margin: 5px;
                height: auto;
                width: calc(50% - 10px);

                >a {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    height: calc(100% - 10px);
                }
            }

            img {
                // max-width: 150px;
                width: 100%;
                min-height: 100px;
                max-height: 200px;
            }

            .message-img-link {
                position: absolute;
                right: 10px
                    /*rtl:auto*/
                ;
                left: auto
                    /*rtl:0*/
                ;
                bottom: 10px;

                li {
                    >a {
                        font-size: 18px;
                        color: $white;
                        display: inline-block;
                        line-height: 30px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .right {
        margin: 5px 0;

        .chat-avatar {
            order: 3;
            margin-right: 0px;
            margin-left: 16px;
        }

        .chat-time {
            text-align: left;
            color: $gray-600;
            left: 10px;
            right: 0;
        }

        .conversation-list {
            float: right;
            text-align: right;

            .ctext-wrap {
                justify-content: flex-end;

                .ctext-wrap-content {
                    order: 2;
                    background-color: $gray-100;
                    color: $dark;
                    text-align: right;
                    border-radius: 8px;
                    padding: 15px 20px 30px 20px;

                    &:before {
                        border: 5px solid transparent;
                        border-top-color: $chat-secondary-bg;
                        border-right-color: $chat-secondary-bg;
                        left: auto;
                        right: 0px;
                    }
                }
            }

            .dropdown {
                order: 1;
            }
        }

        .dot {
            background-color: $dark;
        }
    }
}

.vnd--message-typing {
    position: absolute;
    left: 0;
    padding: 5px 20px;
    bottom: 0px;
    background-color: $gray-200;
    font-size: 12px;
    font-style: italic;
    word-break: break-word;
    box-shadow: 0 -2px 10px rgb(0 0 0 / 10%);
    border-top-right-radius: 5px;
}

.chat-conversation {
    // height: calc(100vh - 235px); // 184
    height: 100%;
    // @media (max-width: 991.98px) {
    //     height: calc(100vh - 195px);
    // }
}

.chat-input-links {
    .list-inline-item:not(:last-child) {
        margin: 0;
    }
}

.animate-typing {
    .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: -1px;
        animation: wave 1.3s linear infinite;
        opacity: 0.6;

        &:nth-child(2) {
            animation-delay: -1.1s;
        }

        &:nth-child(3) {
            animation-delay: -0.9s;
        }
    }
}

.chat-user-list {
    .animate-typing {
        .dot {
            background: $white;
        }
    }
}

.message-img-list {
    h5 {
        max-width: 90px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.typing-message-block {
    .ctext-wrap-content {
        padding: 15px 20px !important;
    }

    .animate-typing {
        .dot {
            background: $dark !important;
        }
    }
}

@keyframes wave {

    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-5px);
    }
}