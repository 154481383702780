$primary-color: #002E81;
$primary-color-50: #7f92b5;
$primary-color-30: #b2bdd3;
$primary-color-20: #ccd4e2;
$primary-color-10: #e5e9f0;

$dark-color: #222F3E;
$dark-color-50: #90979e;
$dark-color-30: #bcc0c5;
$dark-color-20: #d3d5d8;
$dark-color-10: #e8eaeb;

$secondary-color: #F8A921;

$message-color: #011625;
$message-color-10: #f5f5f5;

$white-color: #FFFFFF;
$black-color: #121A21;

$icon-color: #6FACE3;
$light-blue: #264C92;
$body-color: #FBFBFB;

$success-color: #0e962b;
$danger-color: #cb2d3e;
$warning-color: #ffcc00;
$info-color: #63c0df;

$primary-gradient: linear-gradient(to right, $primary-color 0%, #0575E6 51%, $primary-color 100%);
$secondary-gradient: linear-gradient(to right, $secondary-color 0%, #FFC837 51%, $secondary-color 100%);
$danger-gradient: linear-gradient(to right, $danger-color 0%, #ef473a 51%, $danger-color 100%);
$info-gradient: linear-gradient(to right, $info-color 0%, #12D8FA 51%, $info-color 100%);
$warning-gradient: linear-gradient(to right, $warning-color 0%, #fff237 51%, $warning-color 100%);
$success-gradient: linear-gradient(to right, $success-color 0%, #00e363 51%, $success-color 100%);
$dark-gradient: linear-gradient(to right, $dark-color 0%, #334b66 51%, $dark-color 100%);

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-35: 35px;
$font-size-40: 40px;
$font-size-45: 45px;
$font-size-50: 50px;
$font-size-55: 55px;
$font-size-60: 60px;
$font-size-70: 70px;
$font-size-100: 100px;
$font-size-130: 130px;

$font-system: "Montserrat";
$font-famely: $font-system,
sans-serif;


$border-radius-5: 5px;
$border-radius-10: 10px;
$border-radius-15: 15px;
$border-radius-20: 20px;
$border-radius-30: 30px;
$border-radius-50: 50%;

$transition-3: 0.3s;
$transition-5: 0.5s;

$duration-20: .2s;
$duration-30: .3s;
$duration-50: .5s;