.spinner-layer {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--vnd-color-white);
    top: 0;
    z-index: 1002;
    opacity: 0.9;

    .sweet-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.chat-spinner-layer,
.users-spinner-layer,
.right-section-spinner-layer {
    width: 100%;
    background-color: var(--vnd-color-white);
    top: 0;
    z-index: 1000;
    position: absolute;
    height: 100%;
    opacity: 0.9;

    .sweet-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        opacity: 0.9;
    }
}

.connecting-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    .layer {
        position: absolute;
        background: var(--vnd-black-color);
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.2;
    }

    .connecting-loader-block {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--vnd-color-white);
        border-radius: 5px;
        padding: 30px;
        min-width: 400px;

        .title {
            font-size: 22px;
            font-weight: 500;
        }

        .text {
            font-size: 16px;
            margin: 0;
        }

        .dot {
            background-color: var(--vnd-black-color);
            margin-right: 1px !important;
        }

    }
}