// 
// _table.scss
// 

.table {
  th {
    font-weight: $font-weight-bold;
  }

  td {
    font-size: 16px;
  }
}

//Table centered
.table-centered {

  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}

.pricing-table> :not(:last-child)> :last-child>* {
  /* border-bottom-color: currentColor; */
  // border-bottom-color: $sidebar-menu-item-color;
  border-bottom-color: lighten($sidebar-menu-item-color, 20%);
}

.pricing-table {
  border-collapse: collapse;
  border-radius: 20px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #d3d5d8;

  table> :not(:last-child)> :last-child>* {
    border-bottom-color: inherit;
  }

  tbody {
    tr {
      td {
        padding: 1rem;
        align-content: center;
      }

      td:nth-child(4) {
        background-color: $sidebar-sub-bg;
        border-right: 1px solid var(--primary-color);
      }

      td:nth-child(3) {
        border-right: 1px solid var(--primary-color);
      }

      td:nth-child(1) {}
    }
  }

  thead {
    tr {
      th:nth-child(4) {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);

        p {
          opacity: 0.7;
          color: var(--white-color);
        }
      }

      th:nth-child(3) {
        border-right: 1px solid var(--primary-color);
      }
    }
  }
}