.vnd-block {
    border-radius: $border-radius-20;
    padding: 2.5rem;
}

.vnd--m-block {
    border-radius: $border-radius-20;
    padding: 1.5rem;
}

.vnd-border {
    border: 1px solid $dark-color-20;
}

.vnd-text-block {
    padding: 1rem 1.5rem;
    border-radius: $border-radius-20;

    p {
        color: $danger-color;
    }
}

.vnd--info-block {
    font-size: $font-size-16;
    display: flex;
    align-items: start;

    p {
        color: $primary-color !important;
        flex: 1;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
        margin-top: 0.25rem;

        * {
            fill: $primary-color;
        }
    }
}