.burger-menu-wrapper {
    position: relative;
    z-index: 25;

    .unsigned-customer {
        padding: 0 .75rem !important;

        input {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            opacity: 0;
            z-index: 15;
            -webkit-touch-callout: none;

            &:checked~span {
                opacity: 1;
                transform: rotate(45deg) translate(4px, -6px);
                background-color: $white-color;
            }

            &:checked~span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            &:checked~span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0px, 10px);
                background-color: $white-color;
            }
        }
    }

    .burger-icon {
        display: block;
        width: 40px;
        height: 3px;
        margin: 5px 0;
        position: relative;
        right: 0;
        background: $dark-color;
        border-radius: 3px;

        z-index: 1;

        transform-origin: 4px 0px;

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-child {
            transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
    }

    .burger-menu {
        position: fixed;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 50px;
        text-align: center;
        left: 0;
        top: 0;
        background: $dark-color;
        -webkit-font-smoothing: antialiased;
        overflow-y: auto;

        transform-origin: 0% 0%;
        transform: translate(0, -120%);

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

        ul {
            list-style-type: none;
            padding: 0;
            margin-top: 30px;

            li {
                font-size: $font-size-16;
                width: fit-content;
                margin: 0 auto;

                a {
                    padding: 10px 40px;
                    border: 1px solid transparent;
                    display: block;
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: $white-color;
                    margin: 10px 0;
                }

                .vnd-customer-avatar {
                    width: 60px;
                    height: 60px;
                    margin: 0 auto;

                    .signed-avatar {
                        width: 50px;
                        height: 50px;
                    }
                }
            }

            li.active {
                border-radius: $border-radius-10;
                background-color: lighten($color: $dark-color, $amount: 10);
            }

            .hr {
                border-bottom: 1px solid $white-color;
                padding: 1px;
                width: 150px;
                opacity: 0.3;
                margin: 15px auto;
            }

            .signed-cutomer-name {
                margin-top: 20px;
                margin-bottom: 0;
                color: $white-color;
                font-size: $font-size-14;
                @extend .font-light;
                text-transform: capitalize;
            }
        }
    }

    input:checked~.burger-menu {
        transform: none;
    }
}

.signed-customer-modal-wrapper {
    display: flex !important;
    align-items: center;
    // padding: 10px 0;

    .vnd-signed-nav-link {
        padding: 0 .75rem !important;
        position: relative;

        input {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            opacity: 0;
            z-index: 15;
            -webkit-touch-callout: none;

            &:checked~span {
                opacity: 1;
                transform: rotate(45deg) translate(4px, -6px);
                background-color: $white-color;
            }

            &:checked~span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            &:checked~span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0px, 10px);
                background-color: $white-color;
            }
        }

        input:checked~.signed-user-modal,
        input:checked~.burgers {
            display: block;
        }

        .vnd-customer-avatar {
            cursor: pointer;
            width: 35px;
            height: 35px;
            box-shadow: 0px 0px 5px rgba($color: $black-color, $alpha: 0.25);

            .signed-avatar {
                width: 35px;
                height: 35px;
                border-radius: $border-radius-50;
            }
        }

        .signed-user-modal {
            display: none;
            position: absolute;
            list-style: none;
            min-width: 280px;
            right: 0;
            padding: 0;
            margin-top: 25px;
            background-color: $white-color;
            text-align: right;
            border-radius: $border-radius-5;
            box-shadow: 2px 2px 6px 1px rgba($color: $black-color, $alpha: 0.15);
            overflow: hidden;

            li {
                // margin: 5px 0;

                .signed-cutomer-name {
                    font-size: $font-size-16;
                    padding: 15px 30px;
                    margin: 0;
                    background-color: $dark-color;
                    font-weight: 800;
                    color: $white-color;
                    white-space: nowrap;
                }

                a {
                    font-size: $font-size-15;
                    display: block;
                    padding: 10px 20px;
                    color: $dark-color-50;
                    @extend .font-default;

                    &:hover {
                        background-color: $dark-color-10;
                    }

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            .hr {
                border-bottom: 1px solid $dark-color-10;
                margin: 0;
            }

            // display: none;
        }
    }
}