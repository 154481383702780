.vnd-form-card {
    padding: 30px;

    a {
        font-size: $font-size-16;
    }

    label {
        @extend .font-light;
    }
}

@media screen and (min-width: 768px) {
    .vnd-form-card {
        padding: 50px;
    }
}

.vnd-image-card {
    border-radius: $border-radius-20;
    position: relative;
    width: 50%;
    overflow: hidden;
}

.vnd-supporter-form-title {
    p {
        q {
            color: $primary-color;
        }
    }
}