/*#region buttons style*/
@import '../Css/additional.css';
@import '../Css/widget.css';
button,
input[type=submit],
input[type=reset],
input[type=button],
.link-button {
  cursor: pointer;
  transition: cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.3s;
  text-decoration: none;
  border-radius: 5px;
  padding: 13px 45px;
  display: block;
}
button::first-letter,
input[type=submit]::first-letter,
input[type=reset]::first-letter,
input[type=button]::first-letter,
.link-button::first-letter {
  display: block;
  text-transform: capitalize;
}
button img,
input[type=submit] img,
input[type=reset] img,
input[type=button] img,
.link-button img {
  height: 25px;
}

@media (min-width: 768px) {
  button,
  input[type=submit],
  input[type=reset],
  input[type=button],
  .link-button {
    font-size: 14px !important;
  }
}
@media (max-width: 767.98px) {
  button,
  input[type=submit],
  input[type=reset],
  input[type=button],
  .link-button {
    font-size: 14px !important;
  }
}
@media (max-width: 575.98px) {
  button,
  input[type=submit],
  input[type=reset],
  input[type=button],
  .link-button {
    width: 100%;
  }
}
button .sweet-loading {
  min-height: 25px !important;
  display: flex;
  align-items: center;
}

.vnd-copy-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.link-button {
  text-align: center;
}
.link-button:hover {
  color: #FFFFFF;
}

.vnd-btn {
  color: #FFFFFF;
  border: none;
  display: block;
  text-transform: capitalize;
  border-radius: 10px !important;
  font-size: 18px;
  text-align: center;
  transition: ease 0.3s;
  background-size: 200% auto;
}
.vnd-btn:hover {
  background-position: right center;
}

.vnd-small-btn {
  padding: 0.5rem 1.5rem;
  font-size: 14px !important;
  font-weight: 400;
}

/* Basic Buttons */
.vnd-primary-btn {
  background-image: linear-gradient(to right, #002E81 0%, #0575E6 51%, #002E81 100%);
}

.vnd-dark-btn {
  background-image: linear-gradient(to right, #222F3E 0%, #334b66 51%, #222F3E 100%);
}

.vnd-secondary-btn {
  background-image: linear-gradient(to right, #F8A921 0%, #FFC837 51%, #F8A921 100%);
}

.vnd-danger-btn {
  background-image: linear-gradient(to right, #cb2d3e 0%, #ef473a 51%, #cb2d3e 100%);
}

.vnd-success-btn {
  background-image: linear-gradient(to right, #0e962b 0%, #00e363 51%, #0e962b 100%);
}

.vnd-warning-btn {
  background-image: linear-gradient(to right, #ffcc00 0%, #fff237 51%, #ffcc00 100%);
}

/* Outline Buttons */
.vnd-outline-primary-btn {
  background-color: transparent;
  border: 1px solid #002E81;
  color: #002E81;
}
.vnd-outline-primary-btn:hover {
  background-color: #002E81;
  color: #FFFFFF;
}

.vnd-outline-dark-btn {
  background-color: transparent;
  border: 1px solid #222F3E;
  color: #222F3E;
}
.vnd-outline-dark-btn:hover {
  background-color: #222F3E;
  color: #FFFFFF;
}

.vnd-outline-secondary-btn {
  background-color: transparent;
  border: 1px solid #F8A921;
  color: #F8A921;
}
.vnd-outline-secondary-btn:hover {
  background-color: #F8A921;
  color: #FFFFFF;
}

.vnd-outline-danger-btn {
  background-color: transparent;
  border: 1px solid #cb2d3e;
  color: #cb2d3e;
}
.vnd-outline-danger-btn:hover {
  background-color: #cb2d3e;
  color: #FFFFFF;
}

.vnd-outline-success-btn {
  background-color: transparent;
  border: 1px solid #0e962b;
  color: #0e962b;
}
.vnd-outline-success-btn:hover {
  background-color: #0e962b;
  color: #FFFFFF;
}

.vnd-outline-warning-btn {
  background-color: transparent;
  border: 1px solid #ffcc00;
  color: #ffcc00;
}
.vnd-outline-warning-btn:hover {
  background-color: #ffcc00;
  color: #FFFFFF;
}

.vnd-gradient-btn {
  background-image: linear-gradient(to right, #fc00ff 0%, #00dbde 51%, #fc00ff 100%);
  transition: 0.5s;
  background-size: 200% auto;
  color: #FFFFFF !important;
  display: block;
}
.vnd-gradient-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
}

.vnd-outline-btn {
  border: 2px solid #002E81;
  background-color: #FFFFFF;
  display: block;
  border-radius: 10px;
  text-transform: capitalize;
}
.vnd-outline-btn .vnd-warning-btn {
  background-color: #ffcc00 !important;
  color: #222F3E !important;
}
.vnd-outline-btn:hover {
  color: #FFFFFF;
}

.vnd-primary-outline-btn {
  color: #002E81;
  border-color: #002E81;
}
.vnd-primary-outline-btn:hover {
  background-color: #002E81;
}

.vnd-dark-outline-btn {
  color: #222F3E;
  border-color: #222F3E;
}
.vnd-dark-outline-btn:hover {
  background-color: #222F3E;
}

.vnd-danger-outline-btn {
  color: #cb2d3e;
  border-color: #cb2d3e;
}
.vnd-danger-outline-btn:hover {
  background-color: #cb2d3e;
}

.vnd-success-outline-btn {
  color: #0e962b;
  border-color: #0e962b;
}
.vnd-success-outline-btn:hover {
  background-color: #0e962b;
}

.vnd-warning-outline-btn {
  color: #ffcc00;
  border-color: #ffcc00;
}
.vnd-warning-outline-btn:hover {
  background-color: #ffcc00;
}

.vnd--back-textbtn {
  margin-right: 20px;
  color: #222F3E;
  opacity: 0.5;
}

.vnd-input-button {
  position: relative;
}
.vnd-input-button button {
  position: absolute;
  padding: 6px 15px;
  border-radius: 5px !important;
  font-size: 12px;
  right: 10px;
  top: 8.5px;
  margin: 0;
  width: auto;
}
.vnd-input-button button:hover {
  box-shadow: none;
}
.vnd-input-button .copied {
  background-image: linear-gradient(to right, #0e962b 0%, #00e363 51%, #0e962b 100%) !important;
}

/*#endregion*/
.vnd-partners-feedback-wrapper {
  border-radius: 20px;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  max-height: 340px;
}
.vnd-partners-feedback-wrapper .vnd-partner-wrapper {
  display: flex;
  align-items: center;
}
.vnd-partners-feedback-wrapper .vnd-partner-wrapper h5,
.vnd-partners-feedback-wrapper .vnd-partner-wrapper h6 {
  margin: 0;
  text-transform: capitalize;
}
.vnd-partners-feedback-wrapper .vnd-partner-wrapper h5 {
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
  color: #222F3E;
}
.vnd-partners-feedback-wrapper .vnd-partner-wrapper h6 {
  color: #002E81;
  font-weight: 600;
}
.vnd-partners-feedback-wrapper .vnd-partner-wrapper .vnd-partner-image-wrapper {
  padding: 2px;
  margin-right: 15px;
  background-color: #FFFFFF;
  border-radius: 50%;
}
.vnd-partners-feedback-wrapper .vnd-partner-wrapper .vnd-partner-image-wrapper .vnd-partner-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.vnd-partners-feedback-wrapper p {
  margin-top: 15px;
  font-size: 16px;
}

/*#region form styles*/
input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=datetime],
input[type=date],
input[type=tel],
textarea,
.vnd-text-input {
  margin: 0;
  display: block;
  width: 100%;
  color: #243442;
  font-size: 16px;
  border-radius: 10px;
  padding: 14px 20px;
  border: 1px solid #e8eaeb;
}
input[type=text]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=password]::-moz-placeholder, input[type=number]::-moz-placeholder, input[type=datetime]::-moz-placeholder, input[type=date]::-moz-placeholder, input[type=tel]::-moz-placeholder, textarea::-moz-placeholder, .vnd-text-input::-moz-placeholder {
  color: #121A21;
  opacity: 0.3;
  text-transform: initial;
}
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=number]::placeholder,
input[type=datetime]::placeholder,
input[type=date]::placeholder,
input[type=tel]::placeholder,
textarea::placeholder,
.vnd-text-input::placeholder {
  color: #121A21;
  opacity: 0.3;
  text-transform: initial;
}
input[type=text]:hover,
input[type=email]:hover,
input[type=password]:hover,
input[type=number]:hover,
input[type=datetime]:hover,
input[type=date]:hover,
input[type=tel]:hover,
textarea:hover,
.vnd-text-input:hover {
  border: 1px solid rgba(16, 128, 232, 0.384);
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=datetime]:focus,
input[type=date]:focus,
input[type=tel]:focus,
textarea:focus,
.vnd-text-input:focus {
  outline: none;
  border: 2px solid rgba(16, 128, 232, 0.384);
}
input[type=text]:disabled,
input[type=email]:disabled,
input[type=password]:disabled,
input[type=number]:disabled,
input[type=datetime]:disabled,
input[type=date]:disabled,
input[type=tel]:disabled,
textarea:disabled,
.vnd-text-input:disabled {
  color: #7794b5 !important;
  background-color: white;
}

.vnd--select .relamy-select__control {
  padding: 9px 10px;
  border-radius: 10px;
  border: 1px solid #e8eaeb;
}
.vnd--select .relamy-select__control .relamy-select__single-value {
  color: #243442;
}
.vnd--select .relamy-select__control::-moz-placeholder {
  color: #121A21;
  opacity: 0.3;
  text-transform: initial;
}
.vnd--select .relamy-select__control::placeholder {
  color: #121A21;
  opacity: 0.3;
  text-transform: initial;
}
.vnd--select .relamy-select__control:hover {
  border: 1px solid rgba(16, 128, 232, 0.384);
  box-shadow: none !important;
}
.vnd--select .relamy-select__control:focus {
  outline: none;
  border: 2px solid rgba(16, 128, 232, 0.384);
}
.vnd--select .relamy-select__control:disabled {
  color: #7794b5 !important;
  background-color: white;
}

.vnd-text-input {
  width: 100%;
  background-color: #222F3E;
  border: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #bcc0c5;
  box-shadow: inset 3px 3px 5px rgba(0, 46, 129, 0.15);
  padding-right: 80px;
  border: 1px solid transparent;
}

.invalid-value {
  border: 1.5px solid #cb2d3e !important;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
}

.form-control-label {
  color: #222F3E;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.vnd-input-description {
  font-size: 12px;
}

.form-group label {
  font-size: 14px !important;
}
.form-group label img {
  width: 20px;
  margin-left: 5px;
}
.form-group .disabled-text {
  margin: 0;
  display: block;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  padding: 13px 20px;
  background-color: white;
  border: 2px solid transparent;
  position: relative;
}
.form-group .disabled-text p,
.form-group .disabled-text pre {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  color: #121A21;
  opacity: 0.7;
  font-size: 16px;
  margin-right: 30px;
}
.form-group .disabled-text img {
  position: absolute;
  background-color: whitesmoke;
  padding: 10px;
  box-sizing: content-box;
  cursor: pointer;
  width: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.form-group .disabled-text #copy {
  cursor: pointer;
}
.form-group small {
  font-style: italic;
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}
.form-group .vnd-complexe-input {
  position: relative;
}
.form-group .vnd-complexe-input .vnd-complexe-btn {
  background-color: #002E81;
  padding: 3px 10px;
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 9px;
  width: auto;
  transform: translateY(-50%);
  border-radius: 5px;
  cursor: pointer;
}
.form-group .vnd-complexe-input .vnd-complexe-btn img {
  width: 15px;
  display: block;
}
.form-group .vnd-complexe-input .vnd-complexe-btn:nth-child(1) {
  background-color: #0e962b;
}
.form-group .vnd-complexe-input input {
  padding-right: 100px;
}
.form-group .faq.vnd-added-item-container {
  overflow: auto !important;
}
.form-group .faq.vnd-added-item-container .vnd-added-item-wrapper {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.form-group .vnd-added-item-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
@media (max-width: 991.98px) and (min-width: 576px) {
  .form-group .vnd-added-item-container {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .form-group .vnd-added-item-container .vnd-added-item-inline-wrapper {
    width: calc(33.33% - 10px) !important;
  }
}
@media (min-width: 768px) {
  .form-group .vnd-added-item-container .vnd-added-item-inline-wrapper {
    width: calc(50% - 10px) !important;
  }
}
.form-group .vnd-added-item-container .vnd-added-item-wrapper {
  padding: 15px 20px;
  border: 1px solid #002E81;
  border-radius: 5px;
  color: #002E81;
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
}
@media (min-width: 1440px) {
  .form-group .vnd-added-item-container .vnd-added-item-wrapper {
    width: calc(50% - 10px);
  }
}
.form-group .vnd-added-item-container .vnd-added-item-wrapper img {
  position: absolute;
  width: 12px;
  padding: 5px;
  box-sizing: content-box;
  left: 0px;
  top: 0px;
  cursor: pointer;
}
.form-group .vnd-added-item-container .vnd-added-item-wrapper .ri-delete-bin-line {
  color: #cb2d3e;
}
.form-group .vnd-added-item-container .vnd-added-item-wrapper p {
  color: #002E81;
  margin-bottom: 0;
}
.form-group .vnd-added-item-container .vnd-added-item-wrapper .text {
  margin-right: 2.5rem;
}
.form-group .vnd-added-item-container .vnd-added-item-wrapper small {
  display: block;
  color: #90979e;
  font-weight: 300;
  font-style: normal;
}

.vnd--auth-image {
  max-width: calc(12vw + 150px);
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
}

.vnd--alternative-auth-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.vnd--alternative-auth-section .vnd--alternative-auth-item {
  margin: 0.25rem;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  flex: 1;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
  justify-content: center;
}
.vnd--alternative-auth-section .vnd--alternative-auth-item:hover {
  border: 1px solid #002E81;
  background-color: transparent !important;
}
.vnd--alternative-auth-section .vnd--alternative-auth-item span {
  margin-left: 0.5rem;
  font-size: 14px;
}

.emoji-block {
  position: absolute;
  right: 0;
  top: 30px;
}

/*#endregion*/
/*#region fonts*/
.font-default, .custom-accordion h5, .vnd-accordion-left-menu .accordion-item .accordion-header button, .vnd-list-menu a, a, label, body, .vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item p,
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item ul, #agent .chat-leftsidebar .chat-list .sup-companies .custom-control-wrapper .custom-control .custom-control-label, .signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal li a, .vnd-text-input, .form-group .disabled-text p,
.form-group .disabled-text pre, .form-group .vnd-added-item-container .vnd-added-item-wrapper p {
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal;
  font-style: normal;
}

.font-default-itralic, .vnd-input-description {
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal;
  font-style: italic;
}

.font-black, .vnd-step p, .vnd-block-subtitle, .vnd-section-title,
.vnd-section-sub-title, .footer-wrapper .contact-us-title, .price-card-wrapper .price-card-price, .price-card-wrapper .price-card-name, .customer-profile .profile-head-wrapper .profile-info h1, .vnd-cover-wrapper .vnd-cover-content h1, .vnd--title, .vnd--text-50, .vnd--text-24, .vnd--upp-text-22, .vnd--upp-text-20, .vnd--upp-text-16 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 900;
  font-style: normal;
}

.font-bold, .vnd--list-info .vnd--list-info-item-wrapper .vnd--user-info-item:last-child, b, .footer-wrapper .footer-menu .fotter-menu-title, .payment-duration-wrapper .payment-info .total-payment span, .payment-duration-wrapper .payment-info .price, .payment-duration-wrapper .payment-duration, .vnd-adv-wrapper .vnd-adv-body h5, aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link,
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-mobile-menu-link, .right-section .vnd--inline-info-list li span, .vnd--feedback-name {
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold;
  font-style: normal;
}

.font-semi-bold, .vnd-section-name, .backspace span, table th, .active-technical-assistants-cost, .payment-duration-wrapper .payment-info .payment-discount, .trnasaction-faild, .trnasaction-canceled, .trnasaction-panding, .trnasaction-completed, .vnd-header .vnd-navbar .vnd-navbar-nav .vnd-nav-item .vnd-nav-link:hover, .vnd-header .vnd-navbar, button,
input[type=submit],
input[type=reset],
input[type=button],
.link-button, .vnd-btn, .vnd--back-textbtn, .form-control-label {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-style: normal;
}

.font-medium, .vnd--fixed-list-menu li:hover a, .vnd-accordion-left-menu .accordion-item .accordion-header.active-content button, .vnd-accordion-left-menu .accordion-item .accordion-header:hover button, .vnd--payment-method-wrapper, .right-section .vnd--user-info-block .vnd--user-info-title, .customer-profile .customer-sucscription small, .form-group label {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-style: normal;
}

.font-light, .vnd--list-info .vnd--list-info-item-wrapper .vnd--user-info-item:first-child, p, .price-card-wrapper .price-card-price span, .payment-duration-wrapper .payment-info .price span, .vnd-cover-list li, .vnd-form-card label, .burger-menu-wrapper .burger-menu ul .signed-cutomer-name, .vnd-header .vnd-navbar .vnd-navbar-nav .vnd-nav-item .vnd-nav-link, input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=datetime],
input[type=date],
input[type=tel],
textarea,
.vnd-text-input, .vnd--select .relamy-select__control {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-style: normal;
}

.font-light-italic {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-style: italic;
}

/*#endregion*/
.vnd-block {
  border-radius: 20px;
  padding: 2.5rem;
}

.vnd--m-block {
  border-radius: 20px;
  padding: 1.5rem;
}

.vnd-border {
  border: 1px solid #d3d5d8;
}

.vnd-text-block {
  padding: 1rem 1.5rem;
  border-radius: 20px;
}
.vnd-text-block p {
  color: #cb2d3e;
}

.vnd--info-block {
  font-size: 16px;
  display: flex;
  align-items: start;
}
.vnd--info-block p {
  color: #002E81 !important;
  flex: 1;
}
.vnd--info-block svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
}
.vnd--info-block svg * {
  fill: #002E81;
}

.orange-background {
  background-color: #ffcc00 !important;
}

.brand-background {
  background-color: #002E81 !important;
}

.dark-background {
  background-color: #121A21 !important;
}

.secondary-background {
  background-color: #F8A921 !important;
}

.success-background {
  background-color: #0e962b !important;
}

.dark-light-background, .vnd--global-service-container .vnd--global-services .vnd--global-service-item {
  background-color: #d3d5d8 !important;
}

.white-background {
  background-color: #FFFFFF !important;
}

.light-gray-background {
  background-color: #F0F0F0 !important;
}

.danger-background {
  background-color: #FBE9E7 !important;
}

.light-brand-background, .vnd-accordion-left-menu .accordion-item .accordion-collapse, .backspace, .vnd--alternative-auth-section .vnd--alternative-auth-item {
  background-color: #EDF4F9 !important;
}

.light-brand-opacity-background {
  background-color: rgba(111, 172, 227, 0.2) !important;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.vnd--title, .vnd--text-50, .vnd--text-24, .vnd--upp-text-22, .vnd--upp-text-20, .vnd--upp-text-16 {
  color: #222F3E;
}

.vnd--upp-text-16 {
  font-size: 16px;
  text-transform: uppercase;
}

.vnd--upp-text-20 {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
}

.vnd--upp-text-22 {
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
}

.vnd--text-24 {
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
}

.vnd--text-50 {
  font-size: 50px;
  text-transform: capitalize;
}

.vnd--opacity-50 {
  opacity: 0.5;
}

.vnd--opacity-30 {
  opacity: 0.3;
}

.vnd--feedback-name {
  font-size: 20px;
}

.vnd--text-primary {
  color: #002E81;
}

.vnd--text-dark {
  color: #222F3E;
}

.vnd--text-success {
  color: #0e962b;
}

.vnd--text-warning {
  color: #ffcc00;
}

.vnd--text-danger {
  color: #cb2d3e;
}

.vnd--text-info {
  color: #63c0df;
}

.vnd--text-secondary {
  color: #F8A921;
}

.vnd--section-title-wrapper {
  margin-bottom: 1rem;
}

.word-break-break-all {
  word-break: break-all;
}

.word-break-break-word {
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.inline-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*#region text styles*/
.text-for-copy {
  padding-right: 160px;
  position: relative;
  padding-right: 110px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.text-for-copy button {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.overflow-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/*#endregion */
.vnd--borderd {
  border: 1px solid #d3d5d8;
}

@media (min-width: 992px) {
  .border-left {
    border-left: 1px solid #e8eaeb !important;
  }
  .border-right {
    border-right: 1px solid #e8eaeb !important;
  }
  .border-top {
    border-top: 1px solid #e8eaeb !important;
  }
  .border-bottom {
    border-bottom: 1px solid #e8eaeb !important;
  }
}
@media (max-width: 575.98px) {
  .border-sm-left {
    border-left: 1px solid #e8eaeb !important;
  }
  .border-sm-right {
    border-right: 1px solid #e8eaeb !important;
  }
  .border-sm-top {
    border-top: 1px solid #e8eaeb !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #e8eaeb !important;
  }
}
@media (max-width: 767.98px) {
  .border-md-left {
    border-left: 1px solid #e8eaeb !important;
  }
  .border-md-right {
    border-right: 1px solid #e8eaeb !important;
  }
  .border-md-top {
    border-top: 1px solid #e8eaeb !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #e8eaeb !important;
  }
}
@media (max-width: 991.98px) {
  .border-lg-left {
    border-left: 1px solid #e8eaeb !important;
  }
  .border-lg-right {
    border-right: 1px solid #e8eaeb !important;
  }
  .border-lg-top {
    border-top: 1px solid #e8eaeb !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #e8eaeb !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .border-md-left {
    border-left: 1px solid #e8eaeb !important;
  }
  .border-md-right {
    border-right: 1px solid #e8eaeb !important;
  }
  .border-md-top {
    border-top: 1px solid #e8eaeb !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #e8eaeb !important;
  }
}
.list-style-none {
  list-style: none;
}

.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  border: none !important;
  color: var(--vnd-black-color) !important;
  opacity: 0.5 !important;
  text-transform: uppercase !important;
  border-bottom: 3px solid transparent !important;
}
.nav-tabs .nav-link.active {
  border-radius: 0 !important;
  color: var(--vnd-black-color) !important;
  opacity: 0.8 !important;
  border-bottom: 3px solid var(--vnd-color-orange-100) !important;
  background-color: transparent !important;
}

.spinner-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: var(--vnd-color-white);
  top: 0;
  z-index: 1002;
  opacity: 0.9;
}
.spinner-layer .sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chat-spinner-layer,
.users-spinner-layer,
.right-section-spinner-layer {
  width: 100%;
  background-color: var(--vnd-color-white);
  top: 0;
  z-index: 1000;
  position: absolute;
  height: 100%;
  opacity: 0.9;
}
.chat-spinner-layer .sweet-loading,
.users-spinner-layer .sweet-loading,
.right-section-spinner-layer .sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.9;
}

.connecting-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.connecting-loader .layer {
  position: absolute;
  background: var(--vnd-black-color);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.2;
}
.connecting-loader .connecting-loader-block {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--vnd-color-white);
  border-radius: 5px;
  padding: 30px;
  min-width: 400px;
}
.connecting-loader .connecting-loader-block .title {
  font-size: 22px;
  font-weight: 500;
}
.connecting-loader .connecting-loader-block .text {
  font-size: 16px;
  margin: 0;
}
.connecting-loader .connecting-loader-block .dot {
  background-color: var(--vnd-black-color);
  margin-right: 1px !important;
}

@media (min-width: 1200px) {
  .dashboard-menu-wrapper {
    width: 300px;
  }
  .dashboard-menu-wrapper .dashboard-mobile-menu-brand-name {
    display: none;
  }
  .dashboard-menu-wrapper .dashboard-menu {
    padding: 0 15px;
  }
  .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-link {
    display: block;
  }
  .dashboard-menu-wrapper .dashboard-menu .dashboard-mobile-menu-link {
    display: none;
  }
  .right-section {
    margin-left: 300px;
  }
  .right-section .vnd-header,
  .right-section .vnd--poor-footer-contaiber {
    width: calc(100% - 300px);
  }
}
@media (min-width: 768px) {
  .vnd-cover-content h1 {
    font-size: 55px;
  }
  .vnd-cover-content h1 mark {
    padding: 10px 25px;
  }
  .vnd-cover-content .vnd-cover-list {
    display: flex;
    align-items: center;
  }
  .h-section {
    padding: 120px 0;
  }
  .section {
    margin: 40px 0;
  }
  .vnd-section-name {
    font-size: 20px;
  }
  .vnd-section-title {
    font-size: 35px;
  }
  .vnd-section-sub-title {
    font-size: 24px;
  }
  .community img {
    height: 35px;
  }
  .profile-info h1 {
    font-size: 24px;
  }
  .customer-profile-menu strong {
    font-size: 16px;
  }
  .customer-profile-menu-list .customer-profile-menu {
    margin: 5px;
    border: 1px solid transparent;
    transition: 0.3s;
    padding: 15px 25px;
    border-radius: 20px;
  }
  .customer-profile-menu-list .customer-profile-menu img {
    width: 30px;
    padding-top: 5px;
  }
  .customer-profile-menu-list .customer-profile-menu strong {
    padding: 5px 0;
    display: block;
    text-transform: uppercase;
    color: #222F3E;
  }
  .customer-profile-menu-list .customer-profile-menu:hover {
    border: 1px solid #002E81;
  }
  .customer-profile-menu-list .customer-profile-menu.active {
    border: 1px solid #002E81;
  }
  .right-section .vnd-navbar {
    justify-content: flex-end;
  }
  .top-section-steps {
    margin: 140px 0;
  }
}
@media (min-width: 576px) {
  .vnd-input-button .copied {
    background-color: #0e962b !important;
  }
}
@media (min-width: 992px) {
  .list-fixed {
    position: fixed;
    top: 75px;
  }
  .card-1,
  .card-2 {
    width: 50%;
  }
  .position-button {
    width: 25px;
    height: 25px;
  }
  .vnd-laptop-wrapper img {
    width: 400px;
  }
  .vnd-laptop-wrapper .top-left {
    top: 30px;
    left: 30px;
  }
  .vnd-laptop-wrapper .top-center {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .vnd-laptop-wrapper .top-right {
    top: 30px;
    right: 30px;
  }
  .vnd-laptop-wrapper .bottom-left {
    bottom: 125px;
    left: 30px;
  }
  .vnd-laptop-wrapper .bottom-center {
    bottom: 125px;
    left: 50%;
    transform: translate(-50%);
  }
  .vnd-laptop-wrapper .bottom-right {
    bottom: 125px;
    right: 30px;
  }
}
@media (max-width: 1199.98px) and (min-width: 768px) {
  .dashboard-menu-wrapper {
    display: block;
  }
  .dashboard-menu-wrapper .dashboard-menu-brand-name {
    display: none;
  }
  .dashboard-menu-wrapper .dashboard-menu {
    padding: 0 10px;
    text-align: center;
  }
  .dashboard-menu-wrapper .dashboard-menu .dashboard-mobile-menu-link {
    display: block;
  }
  #main .dashboard-menu-link {
    display: none !important;
  }
  .layout-wrapper .dashboard-menu-link {
    display: block !important;
  }
  .right-section {
    margin-left: 75px;
  }
  .right-section .vnd-header,
  .right-section .vnd--poor-footer-contaiber {
    width: calc(100% - 75px);
  }
}
@media (max-width: 575.98px) {
  .vnd-laptop-wrapper img {
    width: 300px;
  }
  .vnd-laptop-wrapper .top-left {
    top: 20px;
    left: 20px;
  }
  .vnd-laptop-wrapper .top-center {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .vnd-laptop-wrapper .top-right {
    top: 20px;
    right: 20px;
  }
  .vnd-laptop-wrapper .bottom-left {
    bottom: 90px;
    left: 20px;
  }
  .vnd-laptop-wrapper .bottom-center {
    bottom: 90px;
    left: 50%;
    transform: translate(-50%);
  }
  .vnd-laptop-wrapper .bottom-right {
    bottom: 90px;
    right: 20px;
  }
  .top-section-steps {
    margin-bottom: 230px !important;
  }
}
@media (max-width: 767.98px) {
  .vnd-cover-content {
    text-align: center;
  }
  .vnd-cover-content h1 {
    font-size: 30px;
  }
  .vnd-cover-content h1 mark {
    padding: 5px 15px;
  }
  .h-section {
    padding: 70px 0;
  }
  .section {
    margin: 30px 0;
  }
  .vnd-section-name {
    font-size: 18px;
  }
  .vnd-section-title {
    font-size: 24px;
  }
  .vnd-section-sub-title {
    font-size: 18px;
  }
  .community img {
    height: 30px;
  }
  .profile-info h1 {
    font-size: 18px;
  }
  .customer-profile-menu strong {
    font-size: 14px;
  }
  .customer-profile-menu-list {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: #FFFFFF;
    box-shadow: -3px -3px 20px rgba(0, 0, 0, 0.1);
  }
  .customer-profile-menu-list .customer-profile-menu {
    flex: 1 auto;
    text-align: center;
    box-shadow: none;
    padding: 20px 0;
    border-radius: 0 !important;
  }
  .customer-profile-menu-list .customer-profile-menu img {
    width: 25px;
  }
  .customer-profile-menu-list .customer-profile-menu strong {
    display: none;
  }
  .customer-profile-menu-list .customer-profile-menu.active {
    border-bottom: 2px solid #002E81;
  }
  .scroll-top.dashboard-scrool-top-profile {
    bottom: 20px;
  }
  .right-section .vnd-navbar {
    justify-content: flex-end;
  }
  .dashboard-menu-wrapper {
    display: none !important;
  }
  .layout-wrapper .dashboard-menu-wrapper {
    display: block !important;
  }
  .right-section .vnd-navbar {
    justify-content: space-between;
  }
  .top-section-steps {
    margin: 100px 0 200px 0;
  }
}
@media (max-width: 991.98px) {
  .vnd-navbar-brand img {
    max-width: 120px !important;
  }
  .position-button {
    width: 20px;
    height: 20px;
  }
  .vnd--inline-info-list {
    justify-content: center;
  }
  .create-ts-btn {
    margin: auto;
    margin-top: 15px;
  }
  .vnd-block {
    padding: 1.5rem !important;
  }
  .price-card-price {
    font-size: 30px !important;
  }
  .vnd--upp-text-16 {
    font-size: 14px;
    text-transform: uppercase;
  }
  .vnd--upp-text-20 {
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
  }
  .vnd--upp-text-22 {
    margin: 0;
    font-size: 18px !important;
    text-transform: uppercase;
  }
  .vnd--text-24 {
    margin: 0;
    font-size: 20px !important;
    text-transform: uppercase;
  }
  .vnd--text-50 {
    font-size: 35px;
    text-transform: capitalize !important;
  }
}
.vnd-modal-window {
  position: relative;
}
.vnd-modal-window .vnd-modal-window-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1010;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  left: 0;
}
.vnd-modal-window .vnd-modal-window-wrapper .vnd-modal {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 20px;
  max-width: 600px;
  width: calc(100% - 30px);
  padding: 25px;
}
.vnd-modal-window .vnd-modal-window-wrapper .vnd-modal img {
  width: 25px;
  margin-right: 15px;
  margin-top: 3px;
}

.vnd-header {
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001 !important;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.vnd-header .vnd-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}
.vnd-header .vnd-navbar .vnd-navbar-brand {
  text-decoration: none;
  color: #002E81;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 800;
}
.vnd-header .vnd-navbar .vnd-navbar-nav {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-left: 20px;
}
.vnd-header .vnd-navbar .vnd-navbar-nav .vnd-nav-item .vnd-nav-link {
  display: block;
  padding: 1rem 1.5rem;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  transition: 0.2s;
  color: #222F3E;
}
.vnd-header .vnd-navbar .vnd-navbar-nav .vnd-nav-item .vnd-nav-link:hover {
  color: #002E81;
}
.vnd-header .vnd-navbar .vnd-navbar-nav > .active .vnd-nav-link {
  color: #002E81;
  font-weight: 600;
}
.vnd-header .vnd-navbar .current-language {
  margin: 0 10px;
  width: 25px;
  height: auto;
  position: relative;
}
.vnd-header .vnd-navbar .current-language img {
  width: 25px;
  opacity: 1 !important;
}
.vnd-header .vnd-navbar .current-language input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}
.vnd-header .vnd-navbar .current-language input:checked ~ .falges {
  transform: scale(1);
}
.vnd-header .vnd-navbar .falges {
  padding: 0;
  position: absolute;
  top: 35px;
  transform: scale(0);
  transform-origin: top;
  list-style: none;
  transition: 0.2s;
  background-color: #FFFFFF;
  padding: 8px;
  border-radius: 5px;
  left: -10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.vnd-header .vnd-navbar .falges li {
  margin: 0;
}
.vnd-header .vnd-navbar .falges li .flag {
  height: auto;
  position: relative;
}
.vnd-header .vnd-navbar .falges li .flag img {
  width: 25px;
  margin: 5px 0;
  opacity: 1 !important;
  cursor: pointer;
  position: relative;
}
.vnd-header .vnd-navbar .falges li .flag:hover::after {
  content: attr(rel);
  position: absolute;
  right: -100%;
  top: 50%;
  background-color: #222F3E;
  transform: translate(50%, -50%);
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  text-align: center;
  color: #FFFFFF;
  z-index: 1;
  width: -moz-max-content;
  width: max-content;
}
.vnd-header .vnd-navbar .falges::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateX(-50%);
  background-color: #FFFFFF;
  top: 0;
  left: 50%;
}

.burger-menu-wrapper {
  position: relative;
  z-index: 25;
}
.burger-menu-wrapper .unsigned-customer {
  padding: 0 0.75rem !important;
}
.burger-menu-wrapper .unsigned-customer input {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  z-index: 15;
  -webkit-touch-callout: none;
}
.burger-menu-wrapper .unsigned-customer input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(4px, -6px);
  background-color: #FFFFFF;
}
.burger-menu-wrapper .unsigned-customer input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.burger-menu-wrapper .unsigned-customer input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0px, 10px);
  background-color: #FFFFFF;
}
.burger-menu-wrapper .burger-icon {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px 0;
  position: relative;
  right: 0;
  background: #222F3E;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.burger-menu-wrapper .burger-icon:first-child {
  transform-origin: 0% 0%;
}
.burger-menu-wrapper .burger-icon:nth-last-child(2) {
  transform-origin: 0% 100%;
}
.burger-menu-wrapper .burger-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 50px;
  text-align: center;
  left: 0;
  top: 0;
  background: #222F3E;
  -webkit-font-smoothing: antialiased;
  overflow-y: auto;
  transform-origin: 0% 0%;
  transform: translate(0, -120%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.burger-menu-wrapper .burger-menu ul {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
}
.burger-menu-wrapper .burger-menu ul li {
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.burger-menu-wrapper .burger-menu ul li a {
  padding: 10px 40px;
  border: 1px solid transparent;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #FFFFFF;
  margin: 10px 0;
}
.burger-menu-wrapper .burger-menu ul li .vnd-customer-avatar {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
.burger-menu-wrapper .burger-menu ul li .vnd-customer-avatar .signed-avatar {
  width: 50px;
  height: 50px;
}
.burger-menu-wrapper .burger-menu ul li.active {
  border-radius: 10px;
  background-color: #34485f;
}
.burger-menu-wrapper .burger-menu ul .hr {
  border-bottom: 1px solid #FFFFFF;
  padding: 1px;
  width: 150px;
  opacity: 0.3;
  margin: 15px auto;
}
.burger-menu-wrapper .burger-menu ul .signed-cutomer-name {
  margin-top: 20px;
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 14px;
  text-transform: capitalize;
}
.burger-menu-wrapper input:checked ~ .burger-menu {
  transform: none;
}

.signed-customer-modal-wrapper {
  display: flex !important;
  align-items: center;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link {
  padding: 0 0.75rem !important;
  position: relative;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link input {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  z-index: 15;
  -webkit-touch-callout: none;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(4px, -6px);
  background-color: #FFFFFF;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.signed-customer-modal-wrapper .vnd-signed-nav-link input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0px, 10px);
  background-color: #FFFFFF;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link input:checked ~ .signed-user-modal,
.signed-customer-modal-wrapper .vnd-signed-nav-link input:checked ~ .burgers {
  display: block;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .vnd-customer-avatar {
  cursor: pointer;
  width: 35px;
  height: 35px;
  box-shadow: 0px 0px 5px rgba(18, 26, 33, 0.25);
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .vnd-customer-avatar .signed-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal {
  display: none;
  position: absolute;
  list-style: none;
  min-width: 280px;
  right: 0;
  padding: 0;
  margin-top: 25px;
  background-color: #FFFFFF;
  text-align: right;
  border-radius: 5px;
  box-shadow: 2px 2px 6px 1px rgba(18, 26, 33, 0.15);
  overflow: hidden;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal li .signed-cutomer-name {
  font-size: 16px;
  padding: 15px 30px;
  margin: 0;
  background-color: #222F3E;
  font-weight: 800;
  color: #FFFFFF;
  white-space: nowrap;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal li a {
  font-size: 15px;
  display: block;
  padding: 10px 20px;
  color: #90979e;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal li a:hover {
  background-color: #e8eaeb;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal li a::first-letter {
  text-transform: capitalize;
}
.signed-customer-modal-wrapper .vnd-signed-nav-link .signed-user-modal .hr {
  border-bottom: 1px solid #e8eaeb;
  margin: 0;
}

.vnd-decoreation {
  position: absolute;
  z-index: -1;
  width: 100%;
}

.vnd-decoration-left {
  left: 0;
}

.vnd-decoration-top {
  top: 0 !important;
}

.vnd-decoration-right {
  right: 0;
}

.vnd-decoration-center {
  left: 50%;
  transform: translateX(-50%);
}

.vnd-star {
  max-width: 450px;
  top: -60px;
}

.vnd-home-rocket {
  width: calc(10vw + 100px);
  z-index: 1;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.vnd-hiring-top-left {
  bottom: 150px;
  left: -30px;
  transform: scaleX(-1) rotate(-60deg);
  z-index: 1;
  max-width: 200px;
}

.vnd-hiring-bottom-right {
  bottom: 0;
  right: -50px;
  z-index: 1;
  max-width: 250px;
}

.vnd-group-chat-services {
  width: calc(5vw + 200px);
  bottom: 15px;
  z-index: 1;
  right: 0;
}

.vnd-sevices-list {
  width: calc(5vw + 200px);
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
  z-index: 1;
}

.vnd-feedback {
  width: 100px;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.vnd-orange-planet {
  top: 200px;
  max-width: 150px;
}

.vnd-violet-planet {
  top: 55%;
  left: 50%;
  max-width: 150px;
}

.vnd-blue-planet {
  max-width: 300px;
}

.vnd-pink-planet {
  max-width: 120px;
  top: -30px;
  z-index: 1;
}

.vnd-red-planet {
  max-width: 120px;
  top: -30px;
  z-index: 1;
}

.vnd-astronaut-1 {
  max-width: calc(9vw + 6em);
  top: -50px;
  z-index: 1;
}

.vnd-astronaut-3 {
  max-width: 300px;
  z-index: 1;
  bottom: 0;
}

.vnd-astronaut-4 {
  max-width: 100px;
  z-index: 1;
  bottom: 0;
}

.vnd-astronaut-5,
.vnd-astronaut-6 {
  max-width: 150px;
  top: -65px;
  z-index: 1;
}

.vnd-astronaut-7 {
  max-width: 200px;
  z-index: 1;
  bottom: 0;
}

.vnd-yellow-component {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 250px;
  width: 100%;
}

.vnd-yellow-right {
  top: 20%;
  transform: translateX(-50%) rotate(25deg);
}

.vnd-yellow-left {
  top: -20%;
  transform: translateX(-50%) rotate(-45deg);
}

@media (max-width: 992px) {
  .vnd-astronaut-3 {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .vnd-orange-planet,
  .vnd-violet-planet {
    max-width: 200px;
  }
  .vnd-blue-planet {
    max-width: 350px;
  }
  .vnd-pink-planet {
    max-width: 250px;
    top: -100px;
  }
  .vnd-red-planet {
    max-width: 120px;
    top: -170px;
  }
  .vnd-astronaut-4 {
    max-width: 150px;
  }
  .vnd-astronaut-5,
  .vnd-astronaut-7 {
    max-width: 150px;
  }
  .vnd-astronaut-6 {
    max-width: 250px;
  }
  .vnd-yellow-component {
    max-width: 300px;
  }
  .vnd-yellow-right {
    top: -40%;
    transform: translateX(-50%) rotate(-45deg);
  }
  .vnd-yellow-left {
    top: 0;
    transform: translateX(-50%) rotate(45deg);
  }
}
.vnd-form-card {
  padding: 30px;
}
.vnd-form-card a {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .vnd-form-card {
    padding: 50px;
  }
}
.vnd-image-card {
  border-radius: 20px;
  position: relative;
  width: 50%;
  overflow: hidden;
}

.vnd-supporter-form-title p q {
  color: #002E81;
}

.vnd-cover-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 1;
  background-color: #FFFFFF;
}
.vnd-cover-wrapper .vnd-cover-content {
  position: absolute;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border-radius: 20px;
  max-width: 1140px;
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.4509803922);
  width: calc(100% - 60px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #d3d5d8;
}
.vnd-cover-wrapper .vnd-cover-content h1,
.vnd-cover-wrapper .vnd-cover-content p {
  color: #222F3E;
}
.vnd-cover-wrapper .vnd-cover-content h1 {
  line-height: 1.5;
}
.vnd-cover-wrapper .vnd-cover-content h1 mark {
  color: #222F3E;
  background-color: #ffcc00 !important;
  border-radius: 15px;
}

.vnd-cover-list {
  list-style: none;
  margin-top: 1rem;
  text-align: left;
  padding: 0;
  display: flex;
  align-items: center;
}
.vnd-cover-list li {
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  font-size: 18px;
}
.vnd-cover-list li img {
  width: 15px;
  margin-right: 0.25rem;
}

.customer-profile .profile-head-wrapper {
  display: flex;
  align-items: center;
}
.customer-profile .profile-head-wrapper .vnd-customer-avatar {
  width: 55px;
  height: 55px;
}
.customer-profile .profile-head-wrapper .vnd-customer-avatar .vnd-customer-avatar-img {
  width: 55px;
  height: 55px;
}
.customer-profile .profile-head-wrapper .profile-info {
  margin-left: 25px;
}
.customer-profile .profile-head-wrapper .profile-info h1 {
  text-transform: uppercase;
  position: relative;
  margin-bottom: 15px;
}
.customer-profile .profile-head-wrapper .profile-info h1::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  border-radius: 5px;
  background-color: #002E81;
  left: 0;
  bottom: -10px;
}
.customer-profile .profile-head-wrapper .profile-info small {
  text-transform: capitalize;
  font-size: 12px;
}
.customer-profile .customer-sucscription small {
  text-transform: capitalize;
  margin-left: 25px;
}
.customer-profile .customer-sucscription small span {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.customer-profile .customer-sucscription small .subscription-active {
  background-color: #0e962b;
}
.customer-profile .customer-sucscription small .subscription-passive {
  background-color: #cb2d3e;
}
.customer-profile .customer-profile-menu-list {
  display: flex;
  flex-wrap: wrap;
}
.customer-profile .customer-profile-menu-list .customer-profile-menu {
  background-color: #FFFFFF;
  flex: 1 auto;
  text-align: center;
}
.customer-profile .customer-profile-menu-list .customer-profile-menu img {
  height: auto;
}
.customer-profile .vnd-customer-edit-avatar {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
}
.customer-profile .vnd-customer-edit-avatar .vnd-customer-avatar-img {
  width: 100px;
  height: 100px;
}
.customer-profile .vnd-customer-edit-avatar .vnd-edit-tols {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}
.customer-profile .vnd-customer-edit-avatar .vnd-edit-tols img {
  display: block;
  border-radius: 50%;
  padding: 3px;
  box-sizing: content-box;
  width: 28px;
  cursor: pointer;
}

/*#region text styles*/
.trnasaction-completed {
  color: #0e962b;
}

.trnasaction-panding {
  color: #ffcc00;
}

.trnasaction-canceled {
  color: #002E81;
}

.trnasaction-faild {
  color: #cb2d3e;
}

/*#endregion*/
.parners-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.parners-wrapper .to-partner {
  display: flex;
  transition: 0.2s;
  border-radius: 15px;
  padding: 10px 15px;
  height: 100px;
  align-items: center;
  justify-content: center;
  flex: 50%;
}
.parners-wrapper .to-partner img {
  opacity: 0.8;
  filter: grayscale(100%);
  max-width: 200px;
  width: 100%;
  max-height: 75px;
}
.parners-wrapper .to-partner:hover {
  background-color: #ffcc00;
}
.parners-wrapper .to-partner:hover img {
  opacity: 1;
}

/*#region partner dsahboard*/
.vnd--pp-assitents-count-wrapper {
  margin-top: 10px;
}
.vnd--pp-assitents-count-wrapper .form-group {
  flex: 0 1 auto;
  margin-right: 15px;
}
.vnd--pp-assitents-count-wrapper .form-group input {
  width: 100px;
}
.vnd--pp-assitents-count-wrapper p {
  margin: 0;
}
.vnd--pp-assitents-count-wrapper small {
  flex: 1;
}

.vnd--aside-title-block {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.vnd--aside-title-block .slash {
  display: block;
  margin: 0 10px;
}

.dashboard-menu-brand-name img {
  max-height: 35px;
}

.dashboard-mobile-menu-brand-name {
  text-align: center;
}
.dashboard-mobile-menu-brand-name img {
  max-height: 35px;
}

.right-section .vnd-header .vnd-navbar {
  padding: 0 15px;
}
.right-section .avatar {
  border: none;
}
.right-section .vnd-customer-edit-avatar {
  max-width: 500px;
  width: auto;
  height: auto;
}
.right-section .vnd-customer-edit-avatar .vnd-customer-avatar-img {
  width: 100%;
  height: 200px;
  margin: auto;
  border-radius: 10px;
}
.right-section .vnd-customer-edit-avatar .vnd-edit-tols {
  position: absolute;
  transform: none;
  right: 0;
  top: 0;
}
.right-section .vnd--upload-image-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.right-section .vnd--upload-image-btn span {
  margin: 10px;
}
.right-section .vnd--user-info-block .vnd--user-info-title {
  margin-bottom: 15px;
}
.right-section .vnd--user-info-block .vnd--user-info-title::first-letter {
  text-transform: uppercase;
  font-size: 16px;
}
.right-section .vnd--inline-info-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}
.right-section .vnd--inline-info-list li span {
  display: block;
  line-height: 1;
  font-size: 45px;
}

.dashboard-menu-container .dashboard-menu-wrapper {
  position: fixed;
  background-color: #002E81;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1003;
  display: flex;
  flex-direction: column;
  min-width: 75px;
}
.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu-brand {
  height: 65px;
}
.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu-brand .dashboard-mobile-menu-brand-name {
  text-align: center;
}
.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu-brand .dashboard-mobile-menu-brand-name img {
  max-height: 35px;
}

aside.dashboard-menu-container {
  display: block;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu-brand {
  display: block;
  padding: 20px 15px;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu {
  list-style: none;
  padding: 0 10px;
  margin: 10px 0;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link,
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-mobile-menu-link {
  padding: 10px 15px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  border-radius: 10px;
  margin: 5px 0;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link i,
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-mobile-menu-link i {
  font-size: 24px;
  color: #6face3;
  font-weight: 200;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link:hover,
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-mobile-menu-link:hover {
  background-color: #264C92;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link {
  align-items: center;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link i {
  margin-right: 20px;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-mobile-menu-link.active {
  background-color: #264C92;
}
aside.dashboard-menu-container .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-link.active {
  background-color: #264C92;
}

#agent .dashboard-menu-wrapper .dashboard-menu-brand {
  display: block;
  padding: 20px 15px;
}
#agent .dashboard-menu-wrapper .dashboard-menu-brand .dashboard-mobile-menu-brand-name {
  max-height: 35px;
}
#agent .dashboard-menu-wrapper .dashboard-menu {
  list-style: none;
  padding: 0 10px;
  text-align: center;
}
#agent .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link i {
  font-size: 24px;
  color: #6face3;
}
#agent .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-item .dashboard-menu-link:hover {
  background-color: #264C92;
}
#agent .dashboard-menu-wrapper .dashboard-menu .dashboard-menu-link.active {
  background-color: #264C92;
}
#agent .dashboard-menu-wrapper .profile-user {
  height: 100% !important;
  width: 100% !important;
  background-color: #6FACE3;
  padding: 2px;
}
#agent .dropdown-bottom {
  transform: translateY(-45px) !important;
}
#agent .chat-search-box .input-group {
  border: 1px solid #bcc0c5;
  border-radius: 5px;
}
#agent .chat-search-box .input-group .form-group {
  margin: 0;
  flex: 1;
}
#agent .chat-search-box .input-group .form-group input {
  border: none;
}
#agent .chat-search-box .input-group span {
  padding: 5px 15px;
  line-height: 2;
  cursor: pointer;
}
#agent .chat-search-box .input-group span .search-icon-btn {
  font-size: 16px;
  position: absolute;
  right: 13px;
  top: 0;
}
#agent .chat-search-box.disabled {
  display: none;
}
#agent .chat-leftsidebar {
  top: 0;
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers,
#agent .chat-leftsidebar .chat-list .sup-companies {
  transition: 0.3s;
  margin-bottom: 5px;
  position: relative;
  background-color: #FFFFFF !important;
  border-radius: 5px;
  transform: 0.3s;
  border: 1px solid #e8eaeb;
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers:hover,
#agent .chat-leftsidebar .chat-list .sup-companies:hover {
  box-shadow: 0 4px 6px -1px rgba(34, 47, 62, 0.1), 0 2px 4px -2px rgba(34, 47, 62, 0.1);
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers.active .cl-chat-wrapper-item {
  border-left: 4px solid #0e962b;
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers .vnd--link-message {
  padding: 3px 0 0 0;
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers .cl-chat-wrapper-item {
  border-radius: 0;
  border-left: 2px solid transparent;
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers .menu-item-wrapper .chat-user-message {
  margin: 0;
  opacity: 0.6;
  font-size: 13px;
}
#agent .chat-leftsidebar .chat-list .cl-chat-wrappers .cl-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#agent .chat-leftsidebar .chat-list .sup-companies .custom-control-wrapper {
  padding: 10px 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
#agent .chat-leftsidebar .chat-list .sup-companies .custom-control-wrapper .custom-control .custom-control-label {
  font-size: 14px;
}
#agent .chat-leftsidebar .chat-list .sup-companies .custom-control-wrapper a {
  font-size: 14px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 2px;
}
#agent .chat-leftsidebar .chat-list .sup-companies.active .border-bottom {
  border-bottom: 2px solid #0e962b !important;
}
#agent .chat-leftsidebar .card .card-header {
  padding: 15px 20px;
}
#agent .chat-leftsidebar .card .card-header h5 {
  color: #222F3E;
}
#agent .empty-block-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
#agent .empty-block-wrapper rect {
  fill: transparent;
}
@media screen and (max-width: 992px) {
  #agent .empty-block-wrapper {
    display: none !important;
  }
}
#agent ::-webkit-scrollbar {
  width: 6px;
}
#agent ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}
#agent ::-webkit-scrollbar-thumb {
  background-color: #d3d5d8;
  border-radius: 5px;
}
#agent .card .card-header {
  border: 1px solid #e8eaeb;
}
#agent .card .card-body input {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
}

/*#endregion*/
/*#region support dsahboard*/
.side-menu {
  background-color: #002E81 !important;
}

@media (min-width: 1199.98px) {
  .vw-300 {
    width: calc(100vw - 300px) !important;
  }
}

/*#endregion*/
.vnd-adv-wrapper {
  border: 2px solid #002E81;
  border-radius: 20px;
  padding: 20px 25px;
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  transition: 0.3s;
  min-height: 134px;
  background-color: #FFFFFF;
  min-width: 100%;
}
.vnd-adv-wrapper img {
  max-width: 40px;
}
@media screen and (max-width: 992px) {
  .vnd-adv-wrapper {
    min-height: auto;
    padding: 15px 20px;
  }
  .vnd-adv-wrapper img {
    max-width: 40px;
  }
}
.vnd-adv-wrapper .vnd-adv-body {
  margin-left: 25px;
}
.vnd-adv-wrapper .vnd-adv-body h5 {
  text-transform: capitalize;
  font-size: 20px;
}
.vnd-adv-wrapper .vnd-adv-body p {
  font-style: italic;
  line-height: normal;
  font-size: 18px !important;
  transition: all 0.8s;
}
.vnd-adv-wrapper:hover {
  border-color: transparent;
  transform-origin: center center;
  transform: scale(1.02);
  z-index: 10;
  box-shadow: 0 0 30px rgba(0, 46, 129, 0.15);
}
.vnd-adv-wrapper:hover p {
  -webkit-line-clamp: 5;
}

.paymant-package-types {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  position: relative;
}
.paymant-package-types .paymant-package-inactive-types {
  list-style: none;
  display: flex;
  padding: 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: end;
}
.paymant-package-types .paymant-package-inactive-types .active-payment-package-name {
  position: absolute;
  left: 0;
}
.paymant-package-types .paymant-package-inactive-types div {
  padding: 0 10px;
}
.paymant-package-types .paymant-package-inactive-types div:not(.active-payment-package-name) {
  padding: 0 10px;
  opacity: 0.3;
}

.payment-duration-wrapper {
  min-height: 200px;
  overflow: hidden;
  position: relative;
  border: 2px solid #d3d5d8;
  cursor: pointer;
  transition: 0.2s;
  padding: 20px;
}
.payment-duration-wrapper .payment-duration {
  font-size: 130px;
  color: #222F3E;
  opacity: 0.2;
  position: absolute;
  top: -65px;
  right: 15px;
}
.payment-duration-wrapper .payment-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 160px;
}
.payment-duration-wrapper .payment-info .price {
  font-size: 30px;
}
.payment-duration-wrapper .payment-info .price span {
  font-size: 12px;
  display: block;
}
.payment-duration-wrapper .payment-info .payment-discount {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: #002E81;
}
.payment-duration-wrapper .payment-info .total-payment {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 12px;
}
.payment-duration-wrapper:hover {
  border: 2px solid #002E81;
}

.active-payment-package {
  border: 2px solid #002E81 !important;
}

.payment-process-wrapper .payment-process-info .vnd--list-info-item-wrapper span:last-child {
  color: #002E81 !important;
}
.payment-process-wrapper .payment-process-types {
  flex: 1;
}
.payment-process-wrapper .payment-process-types button {
  display: block;
  width: 100%;
  margin: 10px 0;
}

.vnd--payment-method-wrapper {
  text-align: center;
  margin: 5px 0;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  flex: 1;
  margin: 0.25rem;
  background-color: #EDF4F9;
  min-width: 140px;
}
.vnd--payment-method-wrapper img {
  max-height: 30px;
}
.vnd--payment-method-wrapper:hover {
  border-color: #002E81;
}
.vnd--payment-method-wrapper.active {
  border: 1px solid #0e962b !important;
  background-color: #FFFFFF;
}

.vnd--payment-method-wrapper.active {
  border-color: #002E81;
}

.price-card-wrapper {
  padding: 30px;
  border-radius: 20px;
  transition: 0.2s;
  background-color: #FFFFFF;
  min-height: 555px;
}
.price-card-wrapper.vnd-good-price {
  position: relative;
  background-color: #002E81;
  color: #FFFFFF;
  z-index: 5;
}
.price-card-wrapper.vnd-good-price .price-card-name,
.price-card-wrapper.vnd-good-price .price-card-price {
  color: #FFFFFF;
}
.price-card-wrapper.vnd-good-price del {
  color: #FFFFFF;
  opacity: 0.7;
}
.price-card-wrapper .vnd-btn {
  background-color: #F8A921;
}
.price-card-wrapper .price-card-name {
  color: #222F3E;
  opacity: 0.3;
  text-transform: uppercase;
  font-size: 20px !important;
  letter-spacing: 3px;
  margin: 0;
}
.price-card-wrapper .link-button {
  text-transform: uppercase;
}
.price-card-wrapper .price-card-price {
  font-size: 40px;
}
.price-card-wrapper .price-card-price span {
  font-size: 14px;
  line-height: 1;
  width: -moz-fit-content;
  width: fit-content;
}
.price-card-wrapper:hover {
  transform: scale(1.01);
}

.price-list li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.price-list li img {
  width: 15px;
  margin-right: 7px;
}
.price-list li:last-child {
  margin: 0 !important;
}

.footer-wrapper .footer-menu {
  flex: 1;
  color: #FFFFFF;
}
.footer-wrapper .footer-menu li {
  word-break: break-word;
}
.footer-wrapper .footer-menu li a {
  padding: 5px 0;
  display: block;
  text-decoration: none;
  color: #FFFFFF;
}
.footer-wrapper .footer-menu li a i {
  font-size: 45px;
}
.footer-wrapper .footer-menu li a:hover {
  text-decoration: none;
  opacity: 0.8;
}
.footer-wrapper .footer-menu .fotter-menu-title {
  font-size: 18px;
  letter-spacing: 2px;
  margin-bottom: 15px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.footer-wrapper .contact-us-title {
  color: #FFFFFF;
  text-transform: uppercase;
  line-height: 1.3;
  font-size: 30px;
}
.footer-wrapper form {
  margin-top: 1.5rem;
}
.footer-wrapper form .footer-form-group {
  margin-bottom: 20px;
}
.footer-wrapper form .footer-form-group input,
.footer-wrapper form .footer-form-group textarea {
  background-color: transparent;
  border: none;
  color: #FFFFFF;
  padding-left: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
  resize: none;
}
.footer-wrapper form .footer-form-group input::-moz-placeholder, .footer-wrapper form .footer-form-group textarea::-moz-placeholder {
  color: #FFFFFF;
}
.footer-wrapper form .footer-form-group input::placeholder,
.footer-wrapper form .footer-form-group textarea::placeholder {
  color: #FFFFFF;
}
@media screen and (min-width: 992px) {
  .footer-wrapper .contact-us-title {
    font-size: 35px;
  }
}
.footer-wrapper .service li:not(:first-child),
.footer-wrapper .community li:not(:first-child) {
  display: inline;
}
.footer-wrapper .service li:not(:first-child) a,
.footer-wrapper .community li:not(:first-child) a {
  display: inline !important;
  padding-right: 10px;
}
.footer-wrapper .community li {
  display: block !important;
  position: relative;
}
.footer-wrapper .community li img {
  width: auto;
  opacity: 0.8;
}

.contact-card .contact-card-item {
  margin-bottom: 0;
}
.contact-card .contact-card-item a {
  margin-right: 1.25rem;
  transition: 0.3s;
}
.contact-card .contact-card-item a i {
  font-size: 45px;
  color: #222F3E;
}
.contact-card .contact-card-item a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.vnd--poor-footer-contaiber {
  border-top: 1px solid #d3d5d8;
  height: 65px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #FBFBFB;
  position: absolute;
  bottom: 0;
}
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  width: 100%;
}
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item p,
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item ul {
  margin: 0;
  font-size: 14px !important;
}
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item p a,
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item ul a {
  color: #222F3E;
}
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item p a:hover,
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item ul a:hover {
  color: #264C92;
  text-decoration: underline !important;
}
.vnd--poor-footer-contaiber .vnd--poor-footer-wrapper .vnd--poor-footer-item ul {
  display: flex;
  list-style: none;
  gap: 0.5rem;
  margin: 0;
}

.vnd--steps-footer {
  border-top: 1px solid #d3d5d8;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 20px 0;
  background-color: #FFFFFF;
  z-index: 1000;
}
.vnd--steps-footer .vnd--steps-footer-description {
  color: #002E81;
  font-weight: 900;
}
.vnd--steps-footer .vnd--steps-footer-logo {
  color: #F8A921;
}

.copyright {
  background-color: #19232e;
  padding: 10px 0;
  text-align: center;
}
.copyright small {
  margin: 0;
  opacity: 0.6;
  color: #FFFFFF;
  font-size: 12px;
}

.vnd--custom-carousel .react-multi-carousel-dot-list {
  display: flex;
}
.vnd--custom-carousel .react-multi-carousel-dot-list .react-multi-carousel-dot button {
  width: 8px;
  height: 8px;
  border: none;
  background-color: #002E81;
}
.vnd--custom-carousel .react-multi-carousel-dot-list .react-multi-carousel-dot--active button {
  width: 30px;
  border-radius: 10px;
  background-color: #F8A921;
}

.dots-start .react-multi-carousel-dot-list {
  justify-content: start;
}

.dots-center .react-multi-carousel-dot-list {
  justify-content: center;
}

.dots-end .react-multi-carousel-dot-list {
  justify-content: end;
}

.vnd--carousel-wrapper {
  position: relative;
  padding-bottom: 3rem;
}
.vnd--carousel-wrapper ul {
  display: flex;
  align-items: center;
}
.vnd--carousel-wrapper ul .vnd--carousel-partner-logo {
  margin: 0 2rem;
  text-align: center;
}
.vnd--carousel-wrapper ul .vnd--carousel-partner-logo a {
  display: block;
}
.vnd--carousel-wrapper ul .vnd--carousel-partner-logo a img {
  max-height: 40px;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.loader-line {
  width: 100%;
  height: 3px;
  position: fixed;
  overflow: hidden;
  background-color: #e8eaeb;
  z-index: 10000;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 100%;
  width: 40%;
  background-color: #002E81;
  animation: lineAnim 1s linear infinite;
  border-radius: 20px;
}

.active-technical-assistants-cost {
  border-radius: 10px;
  background-color: #002E81;
}
.active-technical-assistants-cost span {
  color: #FFFFFF;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
.help-right-menu img {
  max-width: 100%;
}

.change-gradient-colors {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  transform: rotate(45deg);
  cursor: pointer;
}
.change-gradient-colors::after {
  content: "" !important;
  z-index: -1;
}

.user-chat-nav .nav-btn {
  transition: 0.2s;
  border: 1px solid transparent;
}
.user-chat-nav .nav-btn:hover {
  border-color: #90979e;
}

/*#region global styles*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-padding-top: 80px;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background-color: #FBFBFB !important;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

.text-underline {
  text-decoration: underline !important;
}

.list-style-disc {
  list-style-type: disc;
}

.list-style-circle {
  list-style-type: circle;
}

.z-index-10 {
  z-index: 10;
}

.capitalize {
  text-transform: capitalize;
}
.capitalize * {
  text-transform: capitalize;
}

.max-h-initial {
  max-height: initial;
}

.vnd-marked {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  z-index: 2;
}
.vnd-marked::before {
  content: "";
  height: -moz-fit-content;
  height: fit-content;
  border: 3px solid #ffcc00;
  border-top: none;
  display: block;
  width: 80%;
  position: absolute;
  height: 10px;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  z-index: -1;
}

.cookie-modal .cookie-modal-block {
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1003;
}
.cookie-modal:after {
  content: " ";
  background: #121A21;
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1002;
}

#main p {
  font-size: 16px;
}

p {
  color: #222F3E;
  margin: 0.5rem 0;
  line-height: 1.7;
  font-size: 16px;
}

label {
  font-size: 16px !important;
}

b {
  font-size: 18px;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.vnd--icon-wrapper {
  background-color: #EDF4F9;
  padding: 5px 20px;
  border-radius: 5px;
}
.vnd--icon-wrapper svg {
  opacity: 0.5;
  transition: 0.3s;
}
.vnd--icon-wrapper svg * {
  fill: #002E81;
}
.vnd--icon-wrapper:hover svg {
  opacity: 1;
}

@media (max-width: 575.98px) {
  p {
    font-size: 16px;
  }
}
table th {
  text-transform: capitalize;
}
table td {
  min-width: 65px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(86%) sepia(19%) saturate(7499%) hue-rotate(183deg) brightness(108%) contrast(101%);
}

.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 65px;
  padding: 10px;
  border-radius: 10px;
  box-sizing: content-box;
  background-color: #002E81;
  z-index: 1002;
  box-shadow: 0 0 2px 1px #FFFFFF;
  cursor: pointer;
}
.scroll-top img {
  width: 30px;
}

.vnd-list-menu a {
  color: #222F3E;
  margin: 0;
  transition: 0.2s;
  display: block;
}
.vnd-list-menu a:hover {
  opacity: 0.8;
  transform: translateX(5px);
}

.avatar {
  border: 1px solid #002E81;
  border-radius: 50%;
  box-sizing: content-box;
  padding: 4px;
}
.avatar .vnd-customer-avatar-img {
  border-radius: 50%;
}

.top-section {
  margin: 100px 0;
  min-height: 550px;
}

.location-to {
  display: flex;
  align-items: center;
  text-decoration: underline;
  display: block;
}
.location-to img {
  width: 15px;
  margin-left: 5px;
  transition: 30px;
}
.location-to:hover {
  text-decoration: underline;
}
.location-to:hover img {
  transform: translateX(5px);
}
.location-to::first-letter {
  text-transform: capitalize;
}

.backspace {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}
.backspace img {
  width: 10px;
  margin-right: 5px;
}
.backspace span {
  color: #002E81;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  opacity: 0.5;
  transition: 0.3s;
}
.backspace:hover span {
  opacity: 1;
  text-decoration: none;
}

.flex-1 {
  flex: 1;
}

.text-brand {
  color: #002E81 !important;
}

.position-relative {
  position: relative;
}

.vnd-box-shadow {
  box-shadow: 0px 3px 10px rgba(0, 46, 129, 0.15);
}

.vnd-header-shadow {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.vnd-inset-box-shadow, .vnd-body-inset-card {
  box-shadow: inset 3px 3px 5px rgba(0, 46, 129, 0.15);
}

.vnd-body-inset-card {
  padding: 15px;
  border-radius: 10px;
  background-color: #FBFBFB;
}

.vnd-p-block {
  padding: 25px;
}

.vnd--list-info {
  list-style: none;
  padding: 0;
}
.vnd--list-info .vnd--list-info-item-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 14px;
}
.vnd--list-info .vnd--list-info-item-wrapper .vnd--user-info-item:last-child {
  text-align: end;
}

.vnd-p2-block {
  padding: 10px 20px;
}

.vnd-section-name {
  color: #002E81;
  text-transform: uppercase;
  position: relative;
  margin-top: 15px;
  z-index: 2;
}
.vnd-section-name::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 50px;
  background-color: #002E81;
  top: -8px;
  left: 0;
}

.vnd-section-title,
.vnd-section-sub-title {
  color: #222F3E;
  text-transform: uppercase;
  display: block;
  position: relative;
  z-index: 2;
  word-break: break-word;
}

.vnd-block-subtitle {
  text-transform: uppercase;
  color: #222F3E;
  font-size: 18px;
  opacity: 0.3;
}

.vnd-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vnd-step p {
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.3;
}
.vnd-step a {
  font-weight: 600;
  color: #002E81;
}
.vnd-step a:hover {
  opacity: 0.8;
}

.vnd-laptop-container {
  text-align: center;
}
.vnd-laptop-container .vnd-laptop-wrapper {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.vnd-laptop-container .vnd-laptop-wrapper .position-button {
  background-color: #002E81;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
}
.vnd-laptop-container .vnd-laptop-wrapper .active {
  opacity: 1;
  background-color: #F8A921;
}

.rc-slider .rc-slider-track {
  background-color: #264C92;
}
.rc-slider .rc-slider-handle {
  border-color: #264C92;
}
.rc-slider .rc-slider-handle.rc-slider-handle-dragging {
  border-color: #F8A921;
  box-shadow: 0 0 0 5px #F8A921;
}

.vnd--sample-html {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  display: block;
  padding: 15px;
  background-color: #FBFBFB;
  border-radius: 10px;
  box-shadow: inset 3px 3px 5px rgba(0, 46, 129, 0.15);
}
.vnd--sample-html img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .vnd-laptop-wrapper img {
    width: 450px;
  }
  .vnd-laptop-wrapper .top-left {
    top: 30px;
    left: 30px;
  }
  .vnd-laptop-wrapper .top-center {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .vnd-laptop-wrapper .top-right {
    top: 30px;
    right: 30px;
  }
  .vnd-laptop-wrapper .bottom-left {
    bottom: 135px;
    left: 30px;
  }
  .vnd-laptop-wrapper .bottom-center {
    bottom: 135px;
    left: 50%;
    transform: translate(-50%);
  }
  .vnd-laptop-wrapper .bottom-right {
    bottom: 135px;
    right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .vnd-laptop-wrapper img {
    width: 300px;
  }
  .vnd-laptop-wrapper .top-left {
    top: 20px;
    left: 20px;
  }
  .vnd-laptop-wrapper .top-center {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .vnd-laptop-wrapper .top-right {
    top: 20px;
    right: 20px;
  }
  .vnd-laptop-wrapper .bottom-left {
    bottom: 90px;
    left: 20px;
  }
  .vnd-laptop-wrapper .bottom-center {
    bottom: 90px;
    left: 50%;
    transform: translate(-50%);
  }
  .vnd-laptop-wrapper .bottom-right {
    bottom: 90px;
    right: 20px;
  }
}
@media (max-width: 767.98px) {
  .vnd--upp-text-20 {
    font-size: 18px;
  }
}
.vnd-bkg-cover-img, .vnd-cover-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.vnd-bkg-contain-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.vnd-image-dark-layer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #222F3E;
}

.vnd-blur-block {
  position: relative;
  top: 0;
  z-index: 1;
  background: rgba(237, 244, 249, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-radius: 10px;
}
.vnd-blur-block .vnd-blur-block-text-wrapper {
  padding: 10px;
}

@media (min-width: 992px) {
  .group-side-width {
    width: 400px !important;
    position: sticky;
  }
}
.dash-line {
  max-width: 300px;
}

.order-list li {
  position: relative;
  margin-left: 25px;
  font-size: 16px;
}
.order-list li img {
  width: 15px;
  margin-right: 7px;
  position: absolute;
  left: -25px;
  top: 5px;
}

.vnd-side-image-wrapper img {
  width: 100%;
}

.vnd-freelancer-image-wrapper {
  position: relative;
}
.vnd-freelancer-image-wrapper .vnd-freelancer-left,
.vnd-freelancer-image-wrapper .vnd-freelancer-right,
.vnd-freelancer-image-wrapper .vnd-freelancer-center {
  position: absolute;
  font-size: 15px;
  max-width: 180px;
  text-align: center;
  color: #002E81;
  font-weight: bold;
}
@media screen and (min-width: 992px) {
  .vnd-freelancer-image-wrapper .vnd-freelancer-left,
  .vnd-freelancer-image-wrapper .vnd-freelancer-right,
  .vnd-freelancer-image-wrapper .vnd-freelancer-center {
    font-size: 20px;
  }
}
.vnd-freelancer-image-wrapper .vnd-freelancer-left {
  left: 0;
  bottom: 35%;
}
.vnd-freelancer-image-wrapper .vnd-freelancer-center {
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.vnd-freelancer-image-wrapper .vnd-freelancer-right {
  right: 0;
  top: 30%;
}

/*#endregion*/
/*#region color picker*/
.vnd--color-picker {
  width: 100%;
}
.vnd--color-picker .varied-colors {
  border: 1px solid #e8eaeb;
  border-radius: 10px;
  padding: 10px;
}
.vnd--color-picker .base-colors,
.vnd--color-picker .text-colors,
.vnd--color-picker .varied-colors {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 10px 0;
}
.vnd--color-picker .base-colors .color,
.vnd--color-picker .base-colors .color-var,
.vnd--color-picker .text-colors .color,
.vnd--color-picker .text-colors .color-var,
.vnd--color-picker .varied-colors .color,
.vnd--color-picker .varied-colors .color-var {
  float: left;
  border-radius: 50%;
  cursor: pointer;
}
.vnd--color-picker .base-colors .color,
.vnd--color-picker .text-colors .color,
.vnd--color-picker .varied-colors .color {
  transition: all 0.2s;
  width: 45px;
  height: 45px;
  margin: 10px;
  border: 0.5px solid #e8eaeb;
}
.vnd--color-picker .base-colors .color.active,
.vnd--color-picker .text-colors .color.active,
.vnd--color-picker .varied-colors .color.active {
  transform: scale(1.2, 1.2);
}
.vnd--color-picker .base-colors .color-var,
.vnd--color-picker .text-colors .color-var,
.vnd--color-picker .varied-colors .color-var {
  transform: scale(0, 0);
  width: 25px;
  height: 25px;
  margin: 10px;
}
.vnd--color-picker .base-colors .color-var.visible,
.vnd--color-picker .text-colors .color-var.visible,
.vnd--color-picker .varied-colors .color-var.visible {
  transform: scale(1, 1);
}
.vnd--color-picker .base-colors .color-var.active,
.vnd--color-picker .text-colors .color-var.active,
.vnd--color-picker .varied-colors .color-var.active {
  transform: scale(1.2, 1.2);
}
.vnd--color-picker .color-picker {
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  margin: 10px 15px;
  position: relative;
  border: 2px solid #e8eaeb;
}
.vnd--color-picker .color-picker::after {
  content: url(/src/assets/icons/color-picker.svg);
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  top: 50%;
  padding: 7px;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.vnd--color-picker .color-picker input {
  height: 40px;
  padding: 0;
  border: none;
  cursor: pointer;
  visibility: hidden;
}

/*#endregion*/
/*#region bootstrap overrides*/
.dropdown button {
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  background-color: #FFFFFF;
  color: #243442;
  border: 1px solid #e8eaeb;
}
.dropdown button:hover {
  background-color: transparent;
  color: #243442;
  border: 1px solid rgba(16, 128, 232, 0.384);
}
.dropdown button::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.dropdown button:focus {
  outline: none;
  border: 1px solid rgba(16, 128, 232, 0.384);
}

.create-ts-btn {
  flex: 0 0 auto;
}

/*#endregion*/
.opacity-0 {
  opacity: 0;
}

.disabled {
  opacity: 0.4;
  pointer-events: none !important;
}

.disabled_04 {
  opacity: 0.4;
  pointer-events: none !important;
}

.react-confirm-alert-svg {
  display: none;
}

.vnd-accordion-left-menu {
  margin: 0;
}
.vnd-accordion-left-menu .accordion-item {
  background-color: transparent;
}
.vnd-accordion-left-menu .accordion-item .accordion-collapse {
  margin: 0;
  padding-left: 1.25rem;
  border-radius: 10px;
}
.vnd-accordion-left-menu .accordion-item .accordion-collapse .accordion-body {
  padding: 0.5rem 1.25rem 0.5rem 0 !important;
}
.vnd-accordion-left-menu .accordion-item .accordion-header {
  position: relative;
  transition: 0.2s;
}
.vnd-accordion-left-menu .accordion-item .accordion-header button {
  padding: 0.6em 0;
  background-color: transparent;
  font-size: 18px;
}
.vnd-accordion-left-menu .accordion-item .accordion-header:hover {
  margin-left: 10px;
}
.vnd-accordion-left-menu .accordion-item .accordion-header:hover button {
  color: #002E81;
}
.vnd-accordion-left-menu .accordion-item .accordion-header.active-content {
  margin-left: 10px;
}
.vnd-accordion-left-menu .accordion-item .accordion-header.active-content button {
  color: #002E81;
}
.vnd-accordion-left-menu .accordion-item .accordion-header.active-content::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #002E81;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: -1rem;
  line-height: 0;
}
.vnd-accordion-left-menu .accordion-item .without-arrow button::after {
  display: none !important;
}
.vnd-accordion-left-menu .accordion-item .vnd-nav-accordion-body {
  padding-top: 0;
}
.vnd-accordion-left-menu .accordion-item .vnd-nav-accordion-body .vnd-nav-burger-item {
  margin: 7px 0;
}

.session-hide-btn a {
  display: flex;
  background-color: #F8A921;
  border-radius: 5px;
  margin-right: 10px;
}
.session-hide-btn a i {
  color: #002E81;
}

@media (min-width: 767.98px) {
  .left-bar-header {
    display: flex;
    justify-content: space-between;
  }
}

.vnd--list-group-horizontal {
  list-style: none;
  padding-left: 50px;
}
.vnd--list-group-horizontal small {
  opacity: 0.7;
}
@media (min-width: 1199.98px) {
  .vnd--list-group-horizontal {
    display: flex;
    width: 100%;
    padding: inherit;
    justify-content: space-around;
    margin: 0 10px;
  }
}

.page-link {
  border-radius: 5px;
}

.left-navigation-menu-dropdown {
  position: absolute;
  z-index: 1;
}

.nowrap {
  white-space: nowrap;
}

.content-icon {
  padding: 2px 10px;
}
.content-icon:hover {
  transform: scale(1.1);
}

.vnd--body-container {
  margin-bottom: 60px;
  overflow: auto;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.vnd--ticket-form form {
  position: absolute;
  width: calc(100% - 48px);
  background-color: #FFFFFF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 46, 129, 0.15);
}
.vnd--ticket-form::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #222F3E;
  position: fixed;
  z-index: 1;
  opacity: 0.9;
}

.vnd-added-item-container {
  overflow: initial !important;
  width: 100%;
}
@media (max-width: 991.98px) and (min-width: 576px) {
  .vnd-added-item-container {
    width: calc(50% - 0.25rem);
    gap: 5px;
  }
}
@media (max-width: 1400px) and (min-width: 992px) {
  .vnd-added-item-container {
    width: 100%;
  }
}
.vnd-added-item-container .vnd--primary-bordered-block {
  padding: 10px 25px;
  border: 1px solid #002E81;
  border-radius: 5px;
  color: #002E81;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.vnd-added-item-container .vnd--primary-bordered-block.active {
  background-color: #002E81;
  color: #FFFFFF;
}

.vnd--styled-icon {
  padding: 0px 10px;
  border-radius: 5px;
}
.vnd--styled-icon.vnd--styled-secondary-icon {
  background-color: #F8A921;
}
.vnd--styled-icon.vnd--styled-primary-icon {
  background-color: #002E81;
  color: #FFFFFF;
}
.vnd--styled-icon.vnd--styled-success-icon {
  background-color: #0e962b;
  color: #FFFFFF;
}
.vnd--styled-icon.vnd--styled-danger-icon {
  background-color: #cb2d3e;
  color: #FFFFFF;
}
.vnd--styled-icon.vnd--styled-warning-icon {
  background-color: #ffcc00;
}

.card-body {
  padding: 0.5rem !important;
}

.user-profie-top-menu {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
}

.vnd--widget-bottom-icon {
  color: #bcc0c5;
}

.attached-session {
  border-color: transparent;
  animation: pulse 1.5s ease-in-out infinite;
}
@keyframes pulse {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #0e962b;
  }
  100% {
    border-color: transparent;
  }
}

.schedule-dates-block {
  height: 110px;
  overflow-y: auto;
  padding: 10px 20px !important;
  border-radius: 10px;
  background-color: #EDF4F9;
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
}
.schedule-dates-block {
  scrollbar-width: auto;
  scrollbar-color: #d3d5d8 #FFFFFF;
}
.schedule-dates-block::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}
.schedule-dates-block::-webkit-scrollbar-thumb {
  background-color: #d3d5d8;
  border-radius: 5px;
}

.vnd--fixed-list-menu {
  list-style: none;
}
.vnd--fixed-list-menu li {
  position: relative;
  transition: 0.2s;
}
.vnd--fixed-list-menu li a {
  text-decoration: none !important;
  display: block;
  padding: 0.3em 0;
}
.vnd--fixed-list-menu li:hover {
  margin-left: 1rem;
}
.vnd--fixed-list-menu li:hover::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #002E81;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: -1rem;
  line-height: 0;
}

.vnd--global-service-container .vnd--global-services {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.vnd--global-service-container .vnd--global-services .vnd--global-service-item {
  margin: 0.25rem;
  padding: 0.75rem 1.25rem;
  display: flex;
  flex: 1;
  align-items: center;
  text-transform: capitalize;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
  justify-content: start;
  min-width: 230px;
}
@media (min-width: 992px) {
  .vnd--global-service-container .vnd--global-services .vnd--global-service-item {
    min-width: calc(33% - 0.5rem);
  }
}
.vnd--global-service-container .vnd--global-services .vnd--global-service-item .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0e962b;
}
.vnd--global-service-container .vnd--global-services .vnd--global-service-item .custom-control-label::before {
  background-color: #FFFFFF;
}
.vnd--global-service-container .vnd--global-services .vnd--global-service-item:hover {
  background-color: #002E81 !important;
  color: #FFFFFF;
}
.vnd--global-service-container .vnd--global-services .vnd--global-service-item.active {
  background-color: #002E81 !important;
  color: #FFFFFF;
}
.vnd--global-service-container .vnd--global-services .vnd--global-service-item span {
  margin-left: 0.5rem;
  font-size: 14px;
}/*# sourceMappingURL=styles.css.map */