:root{
    --vnd-color-black: #000000;
    --vnd-color-white: #FFFFFF;
    --vnd-color-brand-150: #00266C;
    --vnd-color-brand-100: #00266C;
    --vnd-color-brand-2-100: #fc0;
    --vnd-color-orange-100: #F8A921;
    --vnd-color-orange-150: #CF8D1C;
    --vnd-color-dark-150: #0F161C;
    --vnd-color-dark-100: #121a21;
    --vnd-color-dark-25: #262D34;
    --vnd-color-danger-150: #CB382D;
    --vnd-color-danger-100: #F44336;
    --vnd-color-success-150: #159622;
    --vnd-color-success-100: #21A82E;
    --vnd-color-warning-150: #D5A106;
    --vnd-color-warning-100: #ffc107;
    --vnd-color-gray-100: #ececec;
    --vnd-color-gray-150: #cacaca;
    --vnd-color-light-gray: #f8f9fb;
    --vnd-color-light-blue: #264C92;
    --vnd-color-icon: #6FACE3; 
    --vnd-default-font-color: var(--vnd-color-dark-100);
    --vnd-color-body: #f7f7ff;
    
    --vnd-color-string: #ffb87d;
    --vnd-color-keyword: #83c3ff;
    --vnd-color-class-name: #76ffa4;
    --vnd-color-number: #d5ec93;
    --vnd-color-method: #ffefa6;
    --vnd-color-statment: #e08ccb;
    --vnd-color-tag-style: #8d8d8d;
}