/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
  color: $gray-700;
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
}

a {
  text-decoration: none !important;
}

label {
  font-weight: 500;
}

// @media (min-width:1400px) {
//   .container {
//     max-width: 1140px;
//   }
// }

