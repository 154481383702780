.vnd-modal-window {
    position: relative;
    .vnd-modal-window-wrapper {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 1010;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(2px);
        left: 0;

        .vnd-modal {
            position: fixed;
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
            background-color: $white-color;
            border-radius: $border-radius-20;
            max-width: 600px;
            width: calc(100% - 30px);
            padding: 25px;

            img {
                width: 25px;
                margin-right: 15px;
                margin-top: 3px;
            }
        }

    }
}