.paymant-package-types {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    position: relative;

    .paymant-package-inactive-types {
        list-style: none;
        display: flex;
        padding: 0;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: end;

        .active-payment-package-name {
            position: absolute;
            left: 0;

        }

        div {
            padding: 0 10px;
        }

        div:not(.active-payment-package-name) {
            padding: 0 10px;
            opacity: .3;
        }
    }
}

.payment-duration-wrapper {
    min-height: 200px;
    overflow: hidden;
    position: relative;
    border: 2px solid $dark-color-20;
    cursor: pointer;
    transition: $duration-20;
    padding: 20px;

    .payment-duration {
        @extend .font-bold;
        font-size: $font-size-130;
        color: $dark-color;
        opacity: .2;
        position: absolute;
        top: -65px;
        right: 15px;
    }

    .payment-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 160px;

        .price {
            @extend .font-bold;
            font-size: $font-size-30;

            span {
                @extend .font-light;
                font-size: $font-size-12;
                display: block;
            }
        }

        .payment-discount {
            width: fit-content;
            position: absolute;
            top: 20px;
            left: 20px;
            padding: 5px 10px;
            @extend .font-semi-bold;
            font-size: $font-size-12;
            border-radius: $border-radius-10;
            color: $white-color;
            background-color: $primary-color;
        }

        .total-payment {
            position: absolute;
            bottom: 20px;
            left: 20px;
            font-size: $font-size-12;

            span {
                @extend .font-bold;
            }
        }
    }

    &:hover {
        border: 2px solid $primary-color;
    }
}

.active-payment-package {
    border: 2px solid $primary-color !important;
}

.payment-process-wrapper {
    .payment-process-info {
        .vnd--list-info-item-wrapper {
            span {

                &:last-child {
                    color: $primary-color !important;
                }
            }
        }
    }

    .payment-process-types {
        flex: 1;

        button {
            display: block;
            width: 100%;
            margin: 10px 0;
        }
    }
}

.vnd--payment-method-wrapper {
    text-align: center;
    margin: 5px 0;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: $border-radius-10;
    @extend .font-medium;
    cursor: pointer;
    transition: 0.3s;
    flex: 1;
    margin: 0.25rem;
    background-color: #EDF4F9;
    min-width: 140px;

    img {
        max-height: 30px;
    }

    &:hover {
        border-color: $primary-color;
    }

    &.active {
        border: 1px solid $success-color !important;
        background-color: $white-color;
    }
}

.vnd--payment-method-wrapper.active {
    border-color: $primary-color;
}

.price-card-wrapper {
    padding: 30px;
    border-radius: $border-radius-20;
    transition: $duration-20;
    background-color: $white-color;
    min-height: 555px;

    &.vnd-good-price {
        position: relative;
        background-color: $primary-color;
        color: $white-color;
        z-index: 5;

        .price-card-name,
        .price-card-price {
            color: $white-color;
        }

        del {
            color: $white-color;
            opacity: 0.7;
        }
    }

    .vnd-btn {
        background-color: $secondary-color;
    }

    .price-card-name {
        color: $dark-color;
        opacity: 0.3;
        @extend .font-black;
        text-transform: uppercase;
        font-size: $font-size-20 !important;
        letter-spacing: 3px;
        margin: 0;
    }

    .link-button {
        text-transform: uppercase;
    }

    .price-card-price {
        font-size: $font-size-40;
        @extend .font-black;

        span {
            font-size: $font-size-14;
            line-height: 1;
            width: fit-content;
            @extend .font-light;
        }
    }

    &:hover {
        transform: scale(1.01);
    }
}

.price-list {
    li {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        img {
            width: 15px;
            margin-right: 7px;
        }

        &:last-child {
            margin: 0 !important;
        }
    }
}