.vnd--borderd {
    border: 1px solid $dark-color-20;
}

@media (min-width: 992px) {
    .border-left {
        border-left: 1px solid $dark-color-10 !important;
    }

    .border-right {
        border-right: 1px solid $dark-color-10 !important;
    }

    .border-top {
        border-top: 1px solid $dark-color-10 !important;
    }

    .border-bottom {
        border-bottom: 1px solid $dark-color-10 !important;
    }
}


@media (max-width: 575.98px) {
    .border-sm-left {
        border-left: 1px solid $dark-color-10 !important;
    }

    .border-sm-right {
        border-right: 1px solid $dark-color-10 !important;
    }

    .border-sm-top {
        border-top: 1px solid $dark-color-10 !important;
    }

    .border-sm-bottom {
        border-bottom: 1px solid $dark-color-10 !important;
    }
}

@media (max-width: 767.98px) {
    .border-md-left {
        border-left: 1px solid $dark-color-10 !important;
    }

    .border-md-right {
        border-right: 1px solid $dark-color-10 !important;
    }

    .border-md-top {
        border-top: 1px solid $dark-color-10 !important;
    }

    .border-md-bottom {
        border-bottom: 1px solid $dark-color-10 !important;
    }
}

@media (max-width: 991.98px) {
    .border-lg-left {
        border-left: 1px solid $dark-color-10 !important;
    }

    .border-lg-right {
        border-right: 1px solid $dark-color-10 !important;
    }

    .border-lg-top {
        border-top: 1px solid $dark-color-10 !important;
    }

    .border-lg-bottom {
        border-bottom: 1px solid $dark-color-10 !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .border-md-left {
        border-left: 1px solid $dark-color-10 !important;
    }

    .border-md-right {
        border-right: 1px solid $dark-color-10 !important;
    }

    .border-md-top {
        border-top: 1px solid $dark-color-10 !important;
    }

    .border-md-bottom {
        border-bottom: 1px solid $dark-color-10 !important;
    }
}