.orange-background {
    background-color: $warning-color !important;
}

.brand-background {
    background-color: $primary-color !important;
}

.dark-background {
    background-color: $black-color !important;
}

.secondary-background {
    background-color: $secondary-color !important;
}



.success-background {
    background-color: $success-color !important;
}

.dark-light-background {
    background-color: $dark-color-20 !important;
}

.white-background {
    background-color: $white-color !important;
}

.light-gray-background {
    background-color: #F0F0F0 !important;
}

.danger-background {
    background-color: #FBE9E7 !important;
}

.light-brand-background {
    background-color: #EDF4F9 !important;
}

.light-brand-opacity-background {
    background-color: rgba($color: #6FACE3, $alpha: 0.2) !important;
    backdrop-filter: blur(3px);
}