.customer-profile {
    .profile-head-wrapper {
        display: flex;
        align-items: center;

        .vnd-customer-avatar {
            width: 55px;
            height: 55px;

            .vnd-customer-avatar-img {
                width: 55px;
                height: 55px;
            }
        }

        .profile-info {
            margin-left: 25px;

            h1 {
                @extend .font-black;
                text-transform: uppercase;
                position: relative;
                margin-bottom: 15px;

                &::after {
                    content: '';
                    position: absolute;
                    width: 50px;
                    height: 5px;
                    border-radius: 5px;
                    background-color: $primary-color;
                    left: 0;
                    bottom: -10px;
                }
            }

            small {
                text-transform: capitalize;
                font-size: $font-size-12;
            }
        }
    }

    .customer-sucscription {
        small {
            text-transform: capitalize;
            margin-left: 25px;
            @extend .font-medium;

            span {
                position: absolute;
                width: 8px;
                height: 8px;
                top: 50%;
                left: -15px;
                transform: translateY(-50%);
                border-radius: $border-radius-50;
            }

            .subscription-active {
                background-color: $success-color;
            }

            .subscription-passive {
                background-color: $danger-color;
            }
        }
    }

    .customer-profile-menu-list {
        display: flex;
        flex-wrap: wrap;

        .customer-profile-menu {
            background-color: $white-color;
            flex: 1 auto;
            text-align: center;

            img {
                height: auto;
            }
        }
    }

    .vnd-customer-edit-avatar {
        width: 100px;
        height: 100px;
        position: relative;
        margin: 0 auto;

        .vnd-customer-avatar-img {
            width: 100px;
            height: 100px;
        }

        .vnd-edit-tols {
            position: absolute;
            right: -15px;
            top: 50%;
            transform: translateY(-50%);

            img {
                display: block;
                border-radius: $border-radius-50;
                padding: 3px;
                box-sizing: content-box;
                width: 28px;
                cursor: pointer;
            }
        }
    }
}