.vnd-decoreation {
    position: absolute;
    z-index: -1;
    width: 100%;
}

.vnd-decoration-left {
    left: 0;
}

.vnd-decoration-top {
    top: 0 !important;
}

.vnd-decoration-right {
    right: 0;
}

.vnd-decoration-center {
    left: 50%;
    transform: translateX(-50%);
}

.vnd-star {
    max-width: 450px;
    top: -60px;
}

.vnd-home-rocket {
    width: calc(10vw + 100px);
    z-index: 1;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.vnd-hiring-top-left {
    bottom: 150px;
    left: -30px;
    transform: scaleX(-1) rotate(-60deg);
    z-index: 1;
    max-width: 200px;
}

.vnd-hiring-bottom-right {
    bottom: 0;
    right: -50px;
    z-index: 1;
    max-width: 250px;
}

.vnd-group-chat-services {
    width: calc(5vw + 200px);
    bottom: 15px;
    z-index: 1;
    right: 0;
}

.vnd-sevices-list {
    width: calc(5vw + 200px);
    top: 50%;
    transform: translateY(-50%);
    right: -50px;
    z-index: 1;
}

.vnd-feedback {
    width: 100px;
    bottom: 20px;
    right: 20px;
    z-index: 1;
}


.vnd-orange-planet {
    top: 200px;
    max-width: 150px;
}

.vnd-violet-planet {
    top: 55%;
    left: 50%;
    max-width: 150px;
}

.vnd-blue-planet {
    max-width: 300px;
}

.vnd-pink-planet {
    max-width: 120px;
    top: -30px;
    z-index: 1;
}

.vnd-red-planet {
    max-width: 120px;
    top: -30px;
    z-index: 1;
}

.vnd-astronaut-1 {
    max-width: calc(9vw + 6em);
    top: -50px;
    z-index: 1;
}

.vnd-astronaut-3 {
    max-width: 300px;
    z-index: 1;
    bottom: 0;
}

.vnd-astronaut-4 {
    max-width: 100px;
    z-index: 1;
    bottom: 0;
}

.vnd-astronaut-5,
.vnd-astronaut-6 {
    max-width: 150px;
    top: -65px;
    z-index: 1;
}

.vnd-astronaut-7 {
    max-width: 200px;
    z-index: 1;
    bottom: 0;
}

.vnd-yellow-component {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 250px;
    width: 100%;
}

.vnd-yellow-right {
    top: 20%;
    transform: translateX(-50%) rotate(25deg);
}

.vnd-yellow-left {
    top: -20%;
    transform: translateX(-50%) rotate(-45deg);
}

@media (max-width: 992px) {
    .vnd-astronaut-3 {
        display: none;
    }
}

@media screen and (min-width: 768px) {

    .vnd-orange-planet,
    .vnd-violet-planet {
        max-width: 200px;
    }

    .vnd-blue-planet {
        max-width: 350px;
    }

    .vnd-pink-planet {
        max-width: 250px;
        top: -100px;
    }

    .vnd-red-planet {
        max-width: 120px;
        top: -170px;
    }

    .vnd-astronaut-4 {
        max-width: 150px;
    }

    .vnd-astronaut-5,
    .vnd-astronaut-7 {
        max-width: 150px;
    }

    .vnd-astronaut-6 {
        max-width: 250px;
    }

    .vnd-yellow-component {
        max-width: 300px;
    }

    .vnd-yellow-right {
        top: -40%;
        transform: translateX(-50%) rotate(-45deg);
    }

    .vnd-yellow-left {
        top: 0;
        transform: translateX(-50%) rotate(45deg);
    }
}