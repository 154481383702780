// extra large devices
@media (min-width: 1200px) {
    .dashboard-menu-wrapper {
        width: 300px;

        .dashboard-mobile-menu-brand-name {
            display: none;
        }

        .dashboard-menu {
            padding: 0 15px;

            .dashboard-menu-link {
                display: block;
            }

            .dashboard-mobile-menu-link {
                display: none;
            }
        }
    }

    .right-section {
        margin-left: 300px;

        .vnd-header,
        .vnd--poor-footer-contaiber {
            width: calc(100% - 300px);
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .vnd-cover-content {
        h1 {
            font-size: $font-size-55;

            mark {
                padding: 10px 25px;
            }
        }

        .vnd-cover-list {
            display: flex;
            align-items: center;
        }
    }

    .h-section {
        padding: 120px 0;
    }

    .section {
        margin: 40px 0;
    }

    .vnd-section-name {
        font-size: $font-size-20;
    }

    .vnd-section-title {
        font-size: $font-size-35;
    }

    .vnd-section-sub-title {
        font-size: $font-size-24;
    }

    // .footer-wrapper {
    //     display: flex;

    .community {
        img {
            height: 35px;
        }
    }

    // }

    .profile-info {
        h1 {
            font-size: $font-size-24;
        }
    }

    .customer-profile-menu {
        strong {
            font-size: $font-size-16;
        }
    }

    .customer-profile-menu-list {
        .customer-profile-menu {
            margin: 5px;
            border: 1px solid transparent;
            transition: $duration-30;
            padding: 15px 25px;
            border-radius: $border-radius-20;

            img {
                width: 30px;
                padding-top: 5px;
            }

            strong {
                padding: 5px 0;
                display: block;
                text-transform: uppercase;
                color: $dark-color;
            }

            &:hover {
                border: 1px solid $primary-color;
            }
        }

        .customer-profile-menu.active {
            border: 1px solid $primary-color;
        }
    }

    .right-section {
        .vnd-navbar {
            justify-content: flex-end;
        }
    }

    .top-section-steps {
        margin: 140px 0;
    }

}

@media (min-width: 576px) {
    .vnd-input-button {
        .copied {
            background-color: $success-color !important;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .list-fixed {
        position: fixed;
        top: 75px;
    }

    .card-1,
    .card-2 {
        width: 50%;
    }

    .position-button {
        width: 25px;
        height: 25px;
    }

    .vnd-laptop-wrapper {
        img {
            width: 400px;
        }

        .top-left {
            top: 30px;
            left: 30px;
        }

        .top-center {
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
        }

        .top-right {
            top: 30px;
            right: 30px;
        }

        .bottom-left {
            bottom: 125px;
            left: 30px;
        }

        .bottom-center {
            bottom: 125px;
            left: 50%;
            transform: translate(-50%);
        }

        .bottom-right {
            bottom: 125px;
            right: 30px;
        }
    }
}

// 
@media (max-width: 1199.98px) and (min-width: 768px) {
    .dashboard-menu-wrapper {
        display: block;

        .dashboard-menu-brand-name {
            display: none;
        }

        .dashboard-menu {
            padding: 0 10px;
            text-align: center;

            .dashboard-mobile-menu-link {
                display: block;
            }
        }
    }

    #main {
        .dashboard-menu-link {
            display: none !important;
        }
    }

    .layout-wrapper {
        .dashboard-menu-link {
            display: block !important;
        }
    }

    .right-section {
        margin-left: 75px;

        .vnd-header,
        .vnd--poor-footer-contaiber {
            width: calc(100% - 75px);
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .vnd-laptop-wrapper {
        img {
            width: 300px;
        }

        .top-left {
            top: 20px;
            left: 20px;
        }

        .top-center {
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
        }

        .top-right {
            top: 20px;
            right: 20px;
        }

        .bottom-left {
            bottom: 90px;
            left: 20px;
        }

        .bottom-center {
            bottom: 90px;
            left: 50%;
            transform: translate(-50%);
        }

        .bottom-right {
            bottom: 90px;
            right: 20px;
        }
    }

    .top-section-steps {
        margin-bottom: 230px !important;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .vnd-cover-content {
        text-align: center;

        h1 {
            font-size: $font-size-30;

            mark {
                padding: 5px 15px;
            }
        }
    }

    .h-section {
        padding: 70px 0;
    }

    .section {
        margin: 30px 0;
    }

    .vnd-section-name {
        font-size: $font-size-18;
    }

    .vnd-section-title {
        font-size: $font-size-24;
    }

    .vnd-section-sub-title {
        font-size: $font-size-18;
    }

    .community {
        img {
            height: 30px;
        }
    }

    .profile-info {
        h1 {
            font-size: $font-size-18;
        }
    }

    .customer-profile-menu {
        strong {
            font-size: $font-size-14;
        }
    }

    .customer-profile-menu-list {
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        background-color: $white-color;
        box-shadow: -3px -3px 20px rgba(0, 0, 0, 0.1);

        .customer-profile-menu {
            flex: 1 auto;
            text-align: center;
            box-shadow: none;
            padding: 20px 0;
            border-radius: 0 !important;

            img {
                width: 25px;
            }

            strong {
                display: none;
            }
        }

        .customer-profile-menu.active {
            border-bottom: 2px solid $primary-color;
        }
    }

    // .scroll-top.scrool-top-profile {
    //     bottom: 85px;
    // }

    .scroll-top.dashboard-scrool-top-profile {
        bottom: 20px;
    }

    .right-section {
        .vnd-navbar {
            justify-content: flex-end;
        }
    }

    .dashboard-menu-wrapper {
        display: none !important;
    }

    .layout-wrapper {
        .dashboard-menu-wrapper {
            display: block !important;
        }
    }

    .right-section {
        .vnd-navbar {
            justify-content: space-between;
        }
    }

    .top-section-steps {
        margin: 100px 0 200px 0;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

    .vnd-navbar-brand {
        img {
            max-width: 120px !important;
        }
    }

    .position-button {
        width: 20px;
        height: 20px;
    }

    .vnd--inline-info-list {
        justify-content: center;
    }

    .create-ts-btn {
        margin: auto;
        margin-top: 15px;
    }

    .vnd-block {
        padding: 1.5rem !important;
    }

    .price-card-price {
        font-size: $font-size-30 !important;
    }

    .vnd--upp-text-16 {
        font-size: $font-size-14;
        text-transform: uppercase;
        @extend .vnd--title;
    }

    .vnd--upp-text-20 {
        margin: 0;
        font-size: $font-size-16;
        text-transform: uppercase;
        @extend .vnd--title;
    }

    .vnd--upp-text-22 {
        margin: 0;
        font-size: $font-size-18 !important;
        text-transform: uppercase;
        @extend .vnd--title;
    }

    .vnd--text-24 {
        margin: 0;
        font-size: $font-size-20 !important;
        text-transform: uppercase;
        @extend .vnd--title;
    }

    .vnd--text-50 {
        font-size: $font-size-35;
        text-transform: capitalize !important;
        @extend .vnd--title;
    }
}