.nav-tabs {
    border: none;

    .nav-link {
        border: none !important;
        color: var(--vnd-black-color) !important;
        opacity: 0.5 !important;
        text-transform: uppercase !important;
        border-bottom: 3px solid transparent !important;
    }

    .nav-link.active {
        border-radius: 0 !important;
        color: var(--vnd-black-color) !important;
        opacity: 0.8 !important;
        border-bottom: 3px solid var(--vnd-color-orange-100) !important;
        background-color: transparent !important;
    }
}