/*#region buttons style*/
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.link-button {
    cursor: pointer;
    @extend .font-semi-bold;
    transition: cubic-bezier(0.18, 0.89, 0.32, 1.28) $duration-30;
    text-decoration: none;
    border-radius: $border-radius-5;
    padding: 13px 45px;
    display: block;

    &::first-letter {
        display: block;
        text-transform: capitalize;
    }

    img {
        height: 25px;
        // margin-right: 10px;
    }
}

@media (min-width: 768px) {

    button,
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    .link-button {
        font-size: $font-size-14 !important;
    }
}

@media (max-width: 767.98px) {

    button,
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    .link-button {
        font-size: $font-size-14 !important;
    }
}

@media (max-width: 575.98px) {

    button,
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    .link-button {
        width: 100%;
    }
}

button {
    .sweet-loading {
        min-height: 25px !important;
        display: flex;
        align-items: center;
    }
}

.vnd-copy-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.link-button {
    text-align: center;

    &:hover {
        color: $white-color;
    }
}

.vnd-btn {
    color: $white-color;
    border: none;
    display: block;
    text-transform: capitalize;
    border-radius: $border-radius-10 !important;
    font-size: $font-size-18;
    text-align: center;
    @extend .font-semi-bold;
    transition: ease 0.3s;
    background-size: 200% auto;

    &:hover {
        background-position: right center;
        // border-radius: $border-radius-30 !important;
        // text-decoration: none;
    }
}

.vnd-small-btn {
    padding: .5rem 1.5rem;
    font-size: $font-size-14 !important;
    font-weight: 400;
}

/* Basic Buttons */
.vnd-primary-btn {
    background-image: $primary-gradient;
}

.vnd-dark-btn {
    background-image: $dark-gradient;
}

.vnd-secondary-btn {
    background-image: $secondary-gradient;
}

.vnd-danger-btn {
    background-image: $danger-gradient;
}

.vnd-success-btn {
    background-image: $success-gradient;
}

.vnd-warning-btn {
    background-image: $warning-gradient;
}


/* Outline Buttons */
.vnd-outline-primary-btn {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
        background-color: $primary-color;
        color: $white-color;
    }
}

.vnd-outline-dark-btn {
    background-color: transparent;
    border: 1px solid $dark-color;
    color: $dark-color;

    &:hover {
        background-color: $dark-color;
        color: $white-color;
    }
}

.vnd-outline-secondary-btn {
    background-color: transparent;
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &:hover {
        background-color: $secondary-color;
        color: $white-color;
    }
}

.vnd-outline-danger-btn {
    background-color: transparent;
    border: 1px solid $danger-color;
    color: $danger-color;

    &:hover {
        background-color: $danger-color;
        color: $white-color;
    }
}

.vnd-outline-success-btn {
    background-color: transparent;
    border: 1px solid $success-color;
    color: $success-color;

    &:hover {
        background-color: $success-color;
        color: $white-color;
    }
}

.vnd-outline-warning-btn {
    background-color: transparent;
    border: 1px solid $warning-color;
    color: $warning-color;

    &:hover {
        background-color: $warning-color;
        color: $white-color;
    }
}

.vnd-gradient-btn {
    background-image: linear-gradient(to right, #fc00ff 0%, #00dbde 51%, #fc00ff 100%);
    transition: 0.5s;
    background-size: 200% auto;
    color: $white-color !important;
    // box-shadow: 0 0 20px #eee;
    display: block;

    &:hover {
        background-position: right center;
        /* change the direction of the change here */
    }
}

.vnd-outline-btn {
    .vnd-warning-btn {
        background-color: $warning-color !important;
        color: $dark-color !important;
    }

    border: 2px solid $primary-color;
    background-color: $white-color;
    display: block;
    border-radius: $border-radius-10;
    text-transform: capitalize;

    &:hover {
        color: $white-color;
    }
}

.vnd-primary-outline-btn {
    color: $primary-color;
    border-color: $primary-color;

    &:hover {
        background-color: $primary-color;
    }
}

.vnd-dark-outline-btn {
    color: $dark-color;
    border-color: $dark-color;

    &:hover {
        background-color: $dark-color;
    }
}

.vnd-danger-outline-btn {
    color: $danger-color;
    border-color: $danger-color;

    &:hover {
        background-color: $danger-color;
    }
}

.vnd-success-outline-btn {
    color: $success-color;
    border-color: $success-color;

    &:hover {
        background-color: $success-color;
    }
}

.vnd-warning-outline-btn {
    color: $warning-color;
    border-color: $warning-color;

    &:hover {
        background-color: $warning-color;
    }
}

.vnd--back-textbtn {
    margin-right: 20px;
    color: $dark-color;
    @extend .font-semi-bold;
    opacity: 0.5;
}

.vnd-input-button {
    position: relative;

    button {
        position: absolute;
        padding: 6px 15px;
        border-radius: $border-radius-5 !important;
        font-size: $font-size-12;
        right: 10px;
        top: 8.5px;
        margin: 0;
        width: auto;

        &:hover {
            box-shadow: none;
        }
    }

    .copied {
        background-image: $success-gradient !important;
    }
}

/*#endregion*/