.parners-wrapper {
    display: flex;
    flex-wrap: wrap;

    .to-partner {
        display: flex;
        transition: $duration-20;
        border-radius: $border-radius-15;
        padding: 10px 15px;
        height: 100px;
        align-items: center;
        justify-content: center;
        flex: 50%;
        img{
            opacity: .8;
            filter: grayscale(100%);
            max-width: 200px;
            width: 100%;
            max-height: 75px;
        }

        &:hover {
            background-color: $warning-color;
            img{
                opacity: 1;
            }
        }
    }
}