:root {
  --primary-color: #00266c;
  --primary-color-50: #7f92b5;
  --primary-color-30: #b2bdd3;
  --primary-color-20: #ccd4e2;
  --primary-color-10: #e5e9f0;
  --dark-color: #222F3E;
  --dark-color-50: #90979e;
  --dark-color-30: #bcc0c5;
  --dark-color-20: #d3d5d8;
  --dark-color-10: #e8eaeb;
  --message-color: #011625;
  --message-color-10: #f5f5f5;
  --white-color: #FFFFFF;
  --black-color: #000000;
  --success-color: #05B32E;
  --danger-color: #B30505;
  --warning-color: #ffcc00;
  --info-color: #63c0df;
  --primary-gradient: "";
}
:root {
  --vnd-font-size-10: 10px;
  --vnd-font-size-12: 12px;
  --vnd-font-size-14: 14px;
  --vnd-font-size-16: 16px;
  --vnd-font-size-18: 18px;
  --vnd-font-size-20: 20px;
}
:root {
  --vnd-transition-3: 0.3s;
  --vnd-transition-5: 0.5s;
}
:root {
  --vnd-bottom: 120px;
  --vnd-none: 'none';
  --vnd-block: 'block';
  --vnd-4em: 4em;
}
:root {
  --vnd-border-radius-5: 5px;
  --vnd-border-radius-10: 10px;
  --vnd-border-radius-8: 8px;
  --vnd-border-radius-20: 20px;
}
:root {
  --vnd-bottom: 110px;
  --vnd-none: 'none';
  --vnd-block: 'block';
  --vnd-transform-50: translate(-50%, -50%);
  --vnd-size-4: 4em;
  --vnd-size-6: 6em;
  --vnd-size-1: 1em;
  --vnd-widget-transform: scale(6);
  --vnd-progress-value: 0;
  --vnd-progress-value-arrow: 0;
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes vibration {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
/*#region fonts*/
.font-default {
  font-family: var(--vnd-font-famely);
  font-weight: normal;
  font-style: normal;
}
.font-default-itralic {
  font-family: var(--vnd-font-famely);
  font-weight: normal;
  font-style: italic;
}
.font-black {
  font-family: var(--vnd-font-famely);
  font-weight: 900;
  font-style: normal;
}
.font-bold {
  font-family: var(--vnd-font-famely) !important;
  font-weight: bold !important;
  font-style: normal;
}
.font-semi-bold {
  font-family: var(--vnd-font-famely);
  font-weight: 600;
  font-style: normal;
}
.font-medium {
  font-family: var(--vnd-font-famely);
  font-weight: 500;
  font-style: normal;
}
.font-light {
  font-family: var(--vnd-font-famely);
  font-weight: 300;
  font-style: normal;
}
.font-light-italic {
  font-family: var(--vnd-font-famely);
  font-weight: 300;
  font-style: italic;
}
/*#endregion*/
.vnd--background-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.vnd--btn-disabled {
  background-color: var(--dark-color-20) !important;
  cursor: default !important;
}
.vnd--btn-disabled:hover {
  background-color: var(--dark-color-20) !important;
}
@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}
.vnd--chatbox-popup {
  font-family: 'Montserrat', sans-serif;
  display: block;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  height: auto;
  background-color: var(--white-color);
}
.vnd--chatbox-popup .vnd--chatbox-header {
  box-sizing: border-box;
  display: block;
  width: 100%;
  color: var(--white-color);
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--brand-aside {
  padding: 15px 25px;
  display: block;
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
  min-height: 90px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--brand-aside .vnd--header-default-text {
  font-size: var(--vnd-font-size-12);
  display: block;
  font-weight: normal;
  margin-top: 3px;
  opacity: 0.7;
  color: var(--white-color);
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--brand-aside .vnd--header-hi-text {
  font-size: var(--vnd-font-size-16);
  font-weight: bold;
  display: flex;
  align-items: center;
  color: var(--white-color);
  padding-right: 40px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--brand-aside .vnd--header-hi-text img {
  max-width: 25px;
  margin: 0 10px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #ffffff54;
  justify-content: space-between;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside {
  display: flex;
  align-items: center;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--back-arrow-wrapper {
  position: relative;
  padding: 10px;
  display: block;
  width: fit-content;
  cursor: pointer;
  margin-right: 10px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--back-arrow-wrapper .vnd--right-arrow,
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--back-arrow-wrapper .vnd--back-arrow {
  width: 30px;
  height: 30px;
  display: block;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--back-arrow-wrapper::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  opacity: 0.15;
  border-radius: var(--vnd-border-radius-10);
  transition: var(--vnd-transition-3);
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--back-arrow-wrapper:hover::after {
  opacity: 0.3;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--header-brand-wrapper {
  display: block;
  max-width: 240px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--header-brand-wrapper .vnd--brand-name {
  font-size: var(--vnd-font-size-14);
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--top-header-leftside .vnd--header-brand-wrapper .vnd--header-default-text {
  font-size: var(--vnd-font-size-12);
  display: block;
  font-weight: normal;
  margin-top: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0.8;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--right-arrow {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNS41LDExLjNMOS45LDUuNmMtMC40LTAuNC0xLTAuNC0xLjQsMGMwLDAsMCwwLDAsMEM4LjEsNiw4LjEsNi43LDguNSw3bDQuOSw1bC00LjksNWMtMC40LDAuNC0wLjQsMSwwLDEuNA0KCWMwLjIsMC4yLDAuNCwwLjMsMC43LDAuM2MwLjMsMCwwLjUtMC4xLDAuNy0wLjNsNS43LTUuNkMxNS45LDEyLjQsMTYsMTEuOCwxNS41LDExLjNDMTUuNiwxMS4zLDE1LjYsMTEuMywxNS41LDExLjN6Ii8+DQo8L3N2Zz4NCg==');
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--back-arrow {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik04LjUsMTIuOGw1LjcsNS42YzAuNCwwLjQsMSwwLjQsMS40LDBsMCwwYzAuNC0wLjQsMC40LTEsMC0xLjRsLTQuOS01bDQuOS01YzAuNC0wLjQsMC40LTEsMC0xLjQNCgljLTAuMi0wLjItMC40LTAuMy0wLjctMC4zcy0wLjUsMC4xLTAuNywwLjNsLTUuNyw1LjZDOC4xLDExLjcsOC4xLDEyLjMsOC41LDEyLjhDOC41LDEyLjcsOC41LDEyLjcsOC41LDEyLjh6Ii8+DQo8L3N2Zz4NCg==');
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-btn-block {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--header-btn-block .vnd--header-icon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 4px;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--close-chatbox {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMy40LDEybDYuMy02LjNjMC40LTAuNCwwLjQtMSwwLTEuNHMtMS0wLjQtMS40LDBMMTIsMTAuNkw1LjcsNC4zYy0wLjQtMC40LTEtMC40LTEuNCwwcy0wLjQsMSwwLDEuNGw2LjMsNi4zDQoJbC02LjMsNi4zYy0wLjQsMC40LTAuNCwxLDAsMS40YzAsMCwwLDAsMCwwYzAuNCwwLjQsMSwwLjQsMS40LDBjMCwwLDAsMCwwLDBsNi4zLTYuM2w2LjMsNi4zYzAuNCwwLjQsMSwwLjQsMS40LDBjMCwwLDAsMCwwLDANCgljMC40LTAuNCwwLjQtMSwwLTEuNGMwLDAsMCwwLDAsMEwxMy40LDEyeiIvPg0KPC9zdmc+DQo=');
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--fold-chatbox {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7fQ0KCS5zdDF7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwwaDI0djI0SDBWMHoiLz4NCjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik00LjksMTAuNmgxNC4yYzAuOCwwLDEuNCwwLjYsMS40LDEuNHMtMC42LDEuNC0xLjQsMS40SDQuOWMtMC44LDAtMS40LTAuNi0xLjQtMS40UzQuMSwxMC42LDQuOSwxMC42eiIvPg0KPC9zdmc+DQo=');
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--agent-container {
  display: flex;
  align-items: center;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--agent-container .vnd--user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border: 2px solid var(--white-color);
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  background-size: cover !important;
  background-color: var(--white-color);
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--agent-container .vnd--user-avatar::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: var(--success-color);
  border: 2px solid var(--white-color);
  box-sizing: content-box;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--agent-container .vnd--agent-info-wrapper .vnd--agent-name {
  display: block;
  font-size: var(--vnd-font-size-16);
  font-weight: 600;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--agent-container .vnd--agent-info-wrapper .vnd--agent-usertype {
  font-size: var(--vnd-font-size-12);
  opacity: 0.7;
}
.vnd--chatbox-popup .vnd--chatbox-header .vnd--agent-container .vnd--user-less-avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-color: var(--white-color);
  color: var(--primary-color);
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid var(--dark-color-20);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}
.vnd--chatbox-popup .vnd--chatbox-header.disabled .vnd--brand-aside {
  visibility: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-header.disabled .vnd--header-top-menu {
  visibility: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-footer {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0;
  border-top: 1px solid var(--dark-color-20);
  align-items: center;
  background-color: var(--white-color);
  border-bottom-right-radius: var(--vnd-border-radius-20);
  border-bottom-left-radius: var(--vnd-border-radius-20);
  position: absolute;
  bottom: 0;
  overflow: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper {
  width: 100%;
  line-height: 0;
  position: relative;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 45px 10px 50px !important;
  font-family: inherit;
  font-size: var(--vnd-font-size-14);
  line-height: calc(16px + 16px / 2);
  color: #333333;
  font-weight: 300;
  border: 0;
  outline: 0 !important;
  resize: none;
  overflow: auto;
  background-color: var(--white-color);
  max-height: 44px;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview {
  display: block;
  position: relative;
  width: 33.33%;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview .vnd-uploaded-file {
  display: block;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 5px;
  padding: 5px;
  border: 1px solid var(--dark-color-20);
  border-radius: var(--vnd-border-radius-5);
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview .vnd-uploaded-file .vnd--file-data-wrapper {
  display: block;
  border-top: 1px solid var(--dark-color-10);
  padding-top: 5px;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview .vnd-uploaded-file .vnd--file-data-wrapper .vnd-uploaded-file-name {
  font-size: var(--vnd-font-size-12);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  line-height: initial;
  margin-bottom: 2px;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview .vnd-uploaded-file .vnd--file-data-wrapper .vnd--uploaded-file-size {
  font-size: 11px;
  display: block;
  line-height: initial;
  font-weight: 600;
  color: var(--dark-color);
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview .vnd-uploaded-file img {
  height: 50px;
  margin-bottom: 5px;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container .vnd-uploaded-file-preview .vnd-preview-file-remove {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  top: 3px;
  right: 3px;
  padding: 5px;
  position: absolute;
  background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5jbG9zZS1kYXJrLTI8L3RpdGxlPjxjaXJjbGUgY2xhc3M9ImNscy0xIiBjeD0iMTIiIGN5PSIxMiIgcj0iMTAuNTYiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xMiwuOUExMS4xNywxMS4xNywwLDEsMCwyMy4xLDEyLDExLjE0LDExLjE0LDAsMCwwLDEyLC45Wk0xMiwyMkE5LjksOS45LDAsMCwxLDIsMTIsOS45LDkuOSwwLDAsMSwxMiwyLDkuOSw5LjksMCwwLDEsMjIsMTIsOS45LDkuOSwwLDAsMSwxMiwyMlptLjgtMTAsMy42LTMuNmEuNTcuNTcsMCwxLDAtLjgtLjhMMTIsMTEuMiw4LjQsNy43YS41Ny41NywwLDEsMC0uOC44bDMuNiwzLjZMNy42LDE1LjdhLjM3LjM3LDAsMCwwLS4xLjMuNTguNTgsMCwwLDAsLjYuNmMuMSwwLC4zLS4xLjQtLjFsMy42LTMuNiwzLjYsMy42Yy4xLjEuMi4xLjQuMWgwYy4xLDAsLjMtLjEuNC0uMWEuNjEuNjEsMCwwLDAsMC0uOFoiLz48L3N2Zz4=');
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container::-webkit-scrollbar {
  height: 5px;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container::-webkit-scrollbar-thumb {
  background: #cccccc;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper .vnd-uploaded-file-preview-container::-webkit-scrollbar-track {
  background: var(--dark-color-20);
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--send-message {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1 !important;
  margin: 0 !important;
  padding: 8px !important;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--messagearea-wrapper.disabled {
  border-radius: 0 !important;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--file-upload-progress-wrapper {
  height: 5px;
  width: 100%;
  position: relative;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--file-upload-progress-wrapper .vnd--file-upload-progress {
  width: 0;
  height: 5px;
  display: block;
  transition: 0.5s;
  background-color: var(--primary-color);
  position: relative;
  border-radius: var(--vnd-border-radius-10);
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--file-upload-progress-wrapper::after {
  content: attr(alt);
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 11px;
  display: block;
  box-sizing: content-box;
  transition: 0.3s;
  color: var(--dark-color);
  border-radius: 5px;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--fotter-aside,
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--send-message,
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--emoji {
  cursor: pointer;
  padding: 12px;
  display: flex;
  margin: 2.5px;
  transition: 0.2s;
  border-radius: var(--vnd-border-radius-10);
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--fotter-aside .vnd-chatbox-footer-icon,
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--send-message .vnd-chatbox-footer-icon,
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--emoji .vnd-chatbox-footer-icon {
  width: 25px;
  height: 20px;
  opacity: 0.4;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--fotter-aside:hover,
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--send-message:hover,
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--emoji:hover {
  background-color: #f1f1f1;
}
.vnd--chatbox-popup .vnd--chatbox-footer .vnd--send-btn {
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
}
.vnd--chatbox-popup .vnd--chatbox-footer #vnd_emoji {
  position: absolute;
  left: 0;
  margin: 0;
  bottom: 0;
}
.vnd--chatbox-popup .vnd--chatbox-body {
  overflow: auto;
  display: block;
  background-color: var(--white-color);
  position: relative;
  border-radius: var(--vnd-border-radius-20);
  height: 400px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--alert-animation {
  transform: translateY(-120%);
  transition: cubic-bezier(0.18, 0.89, 0.32, 1.5) 0.4s;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--alert-animation.show {
  transform: translateY(0);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper {
  margin: 5px;
  border-radius: var(--vnd-border-radius-20);
  padding: 10px;
  position: absolute;
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 15px);
  z-index: 10;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-caller-wrapper {
  display: flex;
  align-items: center;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-caller-wrapper .vnd--call-modal-coller-default-image {
  width: 40px;
  height: 40px;
  background-color: var(--white-color);
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-size: cover !important;
  font-size: var(--vnd-font-size-16);
  font-weight: 600;
  color: var(--primary-color);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-caller-wrapper .vnd--call-modal-coller-image {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--white-color);
  border-radius: 50%;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-caller-wrapper .vnd--call-modal-coller-name-wrapper {
  margin: 0 10px;
  color: var(--white-color);
  max-width: 155px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-caller-wrapper .vnd--call-modal-coller-name-wrapper .vnd--call-modal-caller-name {
  display: block;
  font-size: var(--vnd-font-size-14);
  font-weight: 600;
  color: var(--white-color);
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-caller-wrapper .vnd--call-modal-coller-name-wrapper .vnd--call-modal-status {
  font-size: var(--vnd-font-size-12);
  opacity: 0.6;
  display: block;
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group {
  display: flex;
  align-items: center;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-reject-bnt,
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-accept-bnt {
  padding: 10px;
  box-sizing: content-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-reject-bnt img,
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-accept-bnt img {
  width: 20px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-reject-bnt {
  background-color: var(--danger-color);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-accept-bnt {
  background-color: var(--success-color);
  position: relative;
  margin-left: 10px;
  animation: vibration 1s linear infinite;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-accept-bnt::before {
  content: "";
  position: absolute;
  border: 1px solid var(--white-color);
  width: calc(100% + 5px);
  height: calc(100% + 4px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--call-modal-container .vnd--call-modal-wrapper .vnd--call-modal-btn-group .vnd--call-modal-accept-bnt::after {
  content: "";
  position: absolute;
  border: 1px solid var(--white-color);
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: var(--vnd-transition-3);
}
.vnd--chatbox-popup .vnd--chatbox-body .remote-stream.vnd--media-container {
  height: auto;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  border-radius: var(--vnd-border-radius-10);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.vnd--chatbox-popup .vnd--chatbox-body .remote-stream.vnd--media-container video {
  border-radius: var(--vnd-border-radius-10);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-body-content {
  padding: 0 10px;
  position: absolute;
  box-sizing: content-box;
  display: block;
  width: calc(100% - 20px);
  max-height: calc(100% - 117px);
  overflow: auto;
  overflow-x: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-body-content .vnd--faq-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;
  padding: 10px 0;
  margin-top: 50px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-body-content .vnd--faq-container .vnd--faq-wrapper {
  padding: 10px 15px;
  display: block;
  margin: 3px 0;
  margin-left: 5px;
  border-radius: var(--vnd-border-radius-10);
  cursor: pointer;
  transition: var(--vnd-transition-3);
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-body-content .vnd--faq-container .vnd--faq-wrapper:hover {
  border-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-body-content .vnd--faq-container .vnd--faq-wrapper .vnd--faq.disabled {
  visibility: hidden;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container {
  display: block;
  margin: 15px 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container {
  display: block;
  margin: 5px 0;
  max-width: 270px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--message-wrapper {
  padding: 15px 20px;
  border-radius: var(--vnd-border-radius-10);
  display: flex;
  width: fit-content;
  line-height: 1.3;
  font-size: var(--vnd-font-size-14);
  font-weight: 300;
  word-wrap: break-word;
  position: relative;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--message-wrapper img {
  width: 20px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--message-wrapper .vnd--send-date {
  font-size: var(--vnd-font-size-10);
  position: absolute;
  bottom: 5px;
  right: 15px;
  opacity: 0.8;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--message-wrapper .vnd--message {
  max-width: 230px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--message-wrapper .vnd--message .vnd--emoji-message {
  font-size: 60px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--media-message-wrapper {
  background-color: var(--white-color) !important;
  border: 1px solid var(--dark-color-20);
  align-items: flex-start;
  padding: 15px;
  width: 100%;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--media-message-wrapper img {
  width: 30px !important;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--media-message-wrapper .vnd--media-message {
  margin-left: 10px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--media-message-wrapper .vnd--media-message .vnd--message {
  display: block;
  color: var(--vnd-black-color);
  font-weight: 600;
  font-size: var(--vnd-font-size-12);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--media-message-wrapper .vnd--media-message .vnd--media-message-time {
  font-size: var(--vnd-font-size-10);
  color: var(--vnd-black-color) !important;
  opacity: 0.9;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd-emoji-message-wrapper {
  background-color: var(--white-color) !important;
  padding: 5px 0;
  position: relative;
  font-size: 25px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd-emoji-message-wrapper .vnd--send-date {
  color: var(--dark-color);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd-emoji-message-wrapper::after {
  display: none;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--message-wrapper-effect::after {
  content: '';
  width: 15px;
  height: 10px;
  position: absolute;
  bottom: 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--agent-username {
  display: block;
  max-width: 200px;
  opacity: 0.7;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: var(--vnd-font-size-10);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-image-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: right;
  flex-wrap: wrap;
  margin-left: auto;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-image-wrapper img {
  width: 100%;
  display: block;
  max-height: 150px;
  box-sizing: border-box;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-image {
  display: block;
  text-align: right;
  position: relative;
  flex: 0 1 calc(50% - 2px);
  margin: 1px;
  border-radius: var(--vnd-border-radius-10);
  border: 0.5px solid var(--dark-color-10);
  transition: 0.3s;
  max-width: 50%;
  margin-left: auto;
  padding: 2px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-image .vnd--background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: var(--vnd-border-radius-10);
  cursor: pointer;
  width: 100%;
  height: 120px;
  display: block;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-image .vnd--send-date {
  font-size: var(--vnd-font-size-10);
  position: absolute;
  bottom: -10px;
  right: 0;
  opacity: 0.8;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-file {
  border: none;
  padding: 7px 15px 7px 7px;
  width: 270px;
  display: flex;
  align-items: center;
  border-radius: var(--vnd-border-radius-10);
  background-color: var(--white-color);
  border: 1px solid var(--dark-color-10);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-file a {
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #333333;
  font-size: var(--vnd-font-size-14);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container .vnd--attached-file img {
  width: 25px;
  margin-right: 10px;
  padding: 10px;
  box-sizing: content-box;
  background-color: var(--dark-color);
  border-radius: 10px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--date-wrapper {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid var(--dark-color-10);
  margin: 20px 0;
  opacity: 0.6;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--date-wrapper .vnd--date {
  position: absolute;
  background-color: var(--white-color);
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  font-size: var(--vnd-font-size-12);
  padding: 0 10px;
  min-width: 175px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--time-wrapper {
  display: block;
  text-align: center;
  margin: 15px 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--time-wrapper .vnd-time {
  opacity: 0.6;
  font-size: var(--vnd-font-size-12);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-files .vnd--message-wrapper,
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-file .vnd--message-wrapper {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-files .vnd--attached-image-wrapper {
  border: 0.5px solid var(--dark-color-10);
  border-radius: 10px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -5px !important;
  padding: 3px;
  padding-top: 5px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-files .vnd--attached-image-wrapper .vnd--attached-image {
  margin: 1px;
  border: none;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-file {
  max-width: 200px;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-file .vnd--attached-image {
  border-radius: var(--vnd-border-radius-10);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  margin-top: -2px;
  display: flex;
  border: none;
  padding: 0;
  justify-content: center;
  max-width: 100%;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container .vnd--message-container.vnd--message-contain-file .vnd--attached-image .vnd--background-image {
  width: 200px;
  height: 200px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0.5px solid var(--dark-color-10);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--message-container {
  margin-right: auto;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--message-contain-files,
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--message-contain-file {
  margin-right: auto;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--attached-image-wrapper {
  margin: 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--attached-image {
  text-align: left !important;
  margin-left: 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--message-wrapper {
  margin: 2px 0;
  margin-right: auto;
  background-color: #f1f1f1;
  color: var(--vnd-black-color);
  border-bottom-left-radius: 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--message-wrapper .vnd--message {
  opacity: 0.7;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--remote .vnd--message-wrapper::after {
  left: -8px;
  background-color: #f1f1f1;
  transform: rotate3D(25, -40, 10, 45deg) translateX(6px);
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--local .vnd--message-container {
  margin-left: auto;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--local .vnd--message-contain-files,
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--local .vnd--message-contain-file {
  margin-left: auto;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--local .vnd--message-wrapper {
  margin: 2px 0;
  margin-left: auto;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-bottom-right-radius: 0;
}
.vnd--chatbox-popup .vnd--chatbox-body .vnd--chatbox-messages-container.vnd--local .vnd--message-wrapper::after {
  right: 0;
  background-color: var(--dark-color);
  transform: rotate3D(25, 40, -10, 45deg) translateX(3px);
}
.vnd--chatbox-popup .vnd--chatbox-body::after {
  content: '';
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  display: block;
  position: absolute;
}
.vnd--chatbox-popup .vnd--client-form-container {
  padding: 15px;
  display: block;
  text-align: center;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-title {
  color: #697582;
  display: inline-block;
  font-size: 15px;
  padding-bottom: 15px;
  text-align: center;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  word-break: break-word;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper {
  display: block;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper .vnd--form-control-wrapper {
  display: block;
  padding: 10px 0;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper .vnd--form-control-wrapper .vnd--form-control {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid var(--dark-color-10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper .vnd--form-control-wrapper .vnd--form-control:focus {
  outline: none;
  border-color: var(--primary-color);
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper .vnd--client-form-submit-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: var(--white-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
  background-color: var(--primary-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: var(--vnd-border-radius);
  margin-top: 20px;
  transition: 0.3s;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper .vnd--client-form-submit-btn:hover {
  filter: contrast(90%) brightness(100%);
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--client-form-wrapper .vnd--client-form-submit-btn:focus {
  outline: none !important;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--loader {
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  position: relative;
  margin: 0 auto;
  width: 100px;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--loader::before {
  content: "";
  display: var(--vnd-none);
  padding-top: 10px;
  margin: 5px 0;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--loader .circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 30px;
  height: 30px;
}
.vnd--chatbox-popup .vnd--client-form-container .vnd--loader .circular .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 899, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  0% {
    stroke: var(--white-color);
  }
  40% {
    stroke: var(--white-color);
  }
  60% {
    stroke: var(--white-color);
  }
  90% {
    stroke: var(--white-color);
  }
}
.vnd--chatbox-popup ::-webkit-scrollbar {
  width: 5px;
}
.vnd--chatbox-popup ::-webkit-scrollbar-track {
  background: var(--dark-color-20);
}
.vnd--chatbox-popup ::-webkit-scrollbar-thumb {
  background: #cccccc;
}
.vnd--alert {
  margin: 5px;
  border-radius: 5px;
  padding: 10px 20px;
  position: absolute;
  min-width: calc(100% - 15px);
  color: var(--white-color);
  z-index: 150;
}
.vnd--error-alert {
  background-color: var(--danger-color);
}
.vnd--success-alert {
  background-color: var(--success-color);
}
.vnd--warning-alert {
  background-color: var(--warning-color);
}
.vnd--info-alert {
  background-color: var(--info-color);
}
.vnd-demo-widget-container .vnd--circular-menu {
  position: absolute;
  bottom: 1.2rem;
  right: 1.2rem;
  z-index: 1000;
  background-color: var(--primary-color);
  border-radius: 50%;
  width: var(--vnd-4em);
  height: var(--vnd-4em);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn {
  display: block;
  width: var(--vnd-4em);
  height: var(--vnd-4em);
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: 0 2px 5px 0 hsla(0, 0%, 0%, 0.26);
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn:active {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, 0.4);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn .vnd--main-icon {
  width: 100%;
  transition: transform 0.2s;
  position: relative;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn .vnd--main-icon::after,
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn .vnd--main-icon::before {
  content: '';
  position: absolute;
  background-color: var(--white-color);
  top: 50%;
  left: 50%;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn .vnd--main-icon::after {
  width: 3px;
  height: calc(100% - 30px);
  transform: translate(-50%, -50%);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn .vnd--main-icon::before {
  height: 3px;
  width: calc(100% - 30px);
  transform: translate(-50%, -50%);
}
.vnd-demo-widget-container .vnd--circular-menu::after {
  display: block;
  content: ' ';
  width: var(--vnd-4em);
  height: var(--vnd-4em);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  background-color: var(--primary-color);
  transition: all 0.3s ease;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--items-wrapper {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translate(30px, -30px);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--menu-item {
  position: absolute;
  top: -26px;
  right: -26px;
  z-index: -1;
  text-decoration: none;
  color: hsl(0, 0%, 100%);
  font-size: 1em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  text-align: center;
  line-height: 3;
  background-color: hsla(0, 0%, 0%, 0.1);
  transition: transform 0.5s ease, background 0.2s ease;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--menu-item .vnd-service-icon {
  width: 25px;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--menu-item i {
  font-size: 24px;
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--menu-item:hover {
  background-color: hsla(0, 0%, 0%, 0.3);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn {
  background-color: hsla(0, 0%, 0%, 0.1);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd--floating-btn:hover {
  background-color: hsla(0, 0%, 0%, 0.3);
}
.vnd-demo-widget-container .vnd--circular-menu .vnd-widget-close-icon {
  font-size: 27px;
  width: 35px;
  height: 35px;
  top: -13px;
  right: -13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, 0.3);
}
.vnd-demo-widget-container .vnd--circular-menu-container {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -15px;
  right: 0;
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .vnd--floating-btn {
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, 0.3);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .vnd--floating-btn img {
  transform: rotate(-45deg);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active::after {
  transform: scale3d(6, 6, 1);
  transition-timing-function: cubic-bezier(0.68, 1.55, 0.265, 1);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .widget_medium_size .vnd--menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .widget_medium_size .vnd--menu-item:nth-child(1) {
  transform: translate(0.5rem, -9rem);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .widget_medium_size .vnd--menu-item:nth-child(2) {
  transform: translate(-3.4rem, -8.4rem);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .widget_medium_size .vnd--menu-item:nth-child(3) {
  transform: translate(-6.8rem, -6.2rem);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .widget_medium_size .vnd--menu-item:nth-child(4) {
  transform: translate(-8.8rem, -2.8rem);
}
.vnd-demo-widget-container .vnd--circular-menu-container .vnd--circular-menu.active .widget_medium_size .vnd--menu-item:nth-child(5) {
  transform: translate(-9.5rem, 1rem);
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd-service-icon {
  width: 20px !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd--menu-item {
  width: 40px !important;
  height: 40px !important;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd--menu-item:nth-child(1) {
  transform: translate(0.4rem, -5.8rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd--menu-item:nth-child(2) {
  transform: translate(-2.7rem, -5.6rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd--menu-item:nth-child(3) {
  transform: translate(-5.6rem, -3.9rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd--menu-item:nth-child(4) {
  transform: translate(-7.3rem, -1rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_small_size .vnd--menu-item:nth-child(5) {
  transform: translate(-7.5rem, 2rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_large_size .vnd--menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_large_size .vnd--menu-item:nth-child(1) {
  transform: translate(1.4rem, -11.8rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_large_size .vnd--menu-item:nth-child(2) {
  transform: translate(-3.8rem, -10.7rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_large_size .vnd--menu-item:nth-child(3) {
  transform: translate(-8rem, -7.8rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_large_size .vnd--menu-item:nth-child(4) {
  transform: translate(-10.2rem, -3.6rem) !important;
}
.vnd--circular-menu-container .vnd--circular-menu.active .widget_large_size .vnd--menu-item:nth-child(5) {
  transform: translate(-11rem, 1rem) !important;
}
.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  border: none;
  color: var(--vnd-black-color);
  opacity: 0.5;
  font-family: var(--vnd-font-famely) !important;
  font-weight: bold !important;
  font-style: normal;
  text-transform: uppercase;
}
.nav-tabs .nav-link.active {
  border-radius: 0;
  color: var(--vnd-black-color);
  opacity: 0.8;
  border-bottom: 3px solid var(--primary-color);
}
@media (min-width: 992px) {
  .vnd--chatbox-popup {
    position: absolute;
    width: calc(100% - 6rem);
    max-width: 350px;
    height: fit-content;
    border-radius: var(--vnd-border-radius);
    bottom: 1rem;
    right: 6rem;
  }
  .vnd--chatbox-popup .vnd--chatbox-header {
    border-top-right-radius: var(--vnd-border-radius);
    border-top-left-radius: var(--vnd-border-radius);
  }
  .vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--close-chatbox {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .vnd--chatbox-popup {
    height: 500px;
    margin-bottom: 100px;
  }
  .vnd--chatbox-popup .vnd--chatbox-header .vnd--header-top-menu .vnd--fold-chatbox {
    display: none;
  }
}
@media (min-width: 576px) {
  .vnd--circular-menu {
    z-index: 10000;
  }
  .vnd--chatbox-popup {
    height: fit-content;
    border-radius: var(--vnd-border-radius-20);
  }
  .vnd--chatbox-popup .vnd--chatbox-header {
    border-top-right-radius: var(--vnd-border-radius-20);
    border-top-left-radius: var(--vnd-border-radius-20);
    margin-bottom: -15px;
  }
}
@media (max-width: 575.98px) {
  .vnd--chatbox-popup {
    border-top-right-radius: var(--vnd-border-radius-20);
    border-top-left-radius: var(--vnd-border-radius-20);
  }
  .vnd--chatbox-popup .vnd--chatbox-header {
    border-top-right-radius: var(--vnd-border-radius-20);
    border-top-left-radius: var(--vnd-border-radius-20);
  }
  .vnd--chatbox-popup .vnd--chatbox-header .vnd--fold-chatbox {
    display: none;
  }
}
