/*#region text styles*/
.trnasaction-completed {
    color: $success-color;
    @extend .font-semi-bold;
}

.trnasaction-panding {
    color: $warning-color;
    @extend .font-semi-bold;
}

.trnasaction-canceled {
    color: $primary-color;
    @extend .font-semi-bold;
}

.trnasaction-faild {
    color: $danger-color;
    @extend .font-semi-bold;
}

/*#endregion*/