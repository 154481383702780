.vnd--custom-carousel {
  .react-multi-carousel-dot-list {
    display: flex;

    .react-multi-carousel-dot {
      button {
        width: 8px;
        height: 8px;
        border: none;
        background-color: $primary-color;
      }
    }

    .react-multi-carousel-dot--active {
      button {
        width: 30px;
        border-radius: 10px;
        background-color: $secondary-color;
      }
    }
  }
}

.dots-start {
  .react-multi-carousel-dot-list {
    justify-content: start;
  }
}

.dots-center {
  .react-multi-carousel-dot-list {
    justify-content: center;
  }
}

.dots-end {
  .react-multi-carousel-dot-list {
    justify-content: end;
  }
}



.vnd--carousel-wrapper {
  position: relative;
  padding-bottom: 3rem;

  ul {
    display: flex;
    align-items: center;

    .vnd--carousel-partner-logo {
      margin: 0 2rem;
      text-align: center;

      a {
        display: block;

        img {
          max-height: 40px;
        }
      }

    }
  }
}