.vnd-adv-wrapper{
    border: 2px solid $primary-color;
    border-radius: $border-radius-20;
    padding: 20px 25px;
    margin: 10px 0;
    display: flex;
    align-items: flex-start;
    transition: $duration-30;
    min-height: 134px;
    background-color: $white-color;
    min-width: 100%;
    img{
        max-width: 40px;
    }
    @media screen and (max-width: 992px) {
        min-height: auto;
        padding: 15px 20px;
        img{
            max-width: 40px;
        }
    }
    .vnd-adv-body{
        margin-left: 25px;
        h5{
            @extend .font-bold;
            text-transform: capitalize;
            font-size: $font-size-20;
        }
        p{
            font-style: italic;
            line-height: normal;
            font-size: $font-size-18 !important;
            transition: all 0.8s;
        }
    }
    &:hover{
        border-color: transparent;
        // position: absolute;
        transform-origin: center center;
        transform: scale(1.02);
        z-index: 10;
        box-shadow: 0 0 30px rgba($color: $primary-color, $alpha: 0.15);
        p{
            -webkit-line-clamp: 5;
        }
    }
}