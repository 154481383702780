.vnd-header {
    background-color: $white-color;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001 !important;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: end;

    .vnd-navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        @extend .font-semi-bold;

        .vnd-navbar-brand {
            text-decoration: none;
            color: $primary-color;
            text-transform: uppercase;
            font-size: $font-size-22;
            font-weight: 800;
        }

        .vnd-navbar-nav {
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            margin: 0;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            margin-left: 20px;

            .vnd-nav-item {
                .vnd-nav-link {
                    display: block;
                    padding: 1rem 1.5rem;
                    text-decoration: none;
                    text-transform: capitalize;
                    font-size: $font-size-16;
                    transition: $duration-20;
                    color: $dark-color;
                    @extend .font-light;

                    &:hover {
                        color: $primary-color;
                        @extend .font-semi-bold;
                    }
                }
            }

            >.active {

                .vnd-nav-link {
                    color: $primary-color;
                    font-weight: 600;
                }
            }
        }

        .current-language {
            margin: 0 10px;
            width: 25px;
            height: auto;
            position: relative;

            img {
                width: 25px;
                opacity: 1 !important;
            }

            input {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
                height: 30px;
                opacity: 0;
                cursor: pointer;
            }

            input:checked~.falges {
                transform: scale(1);
            }
        }

        .falges {
            padding: 0;
            position: absolute;
            top: 35px;
            transform: scale(0);
            transform-origin: top;
            list-style: none;
            transition: $duration-20;
            background-color: $white-color;
            padding: 8px;
            border-radius: $border-radius-5;
            left: -10px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            z-index: 100;

            li {
                margin: 0;

                .flag {
                    height: auto;
                    position: relative;

                    img {
                        width: 25px;
                        margin: 5px 0;
                        opacity: 1 !important;
                        cursor: pointer;
                        position: relative;
                    }

                    &:hover {
                        &::after {
                            content: attr(rel);
                            position: absolute;
                            right: -100%;
                            top: 50%;
                            background-color: $dark-color;
                            transform: translate(50%, -50%);
                            font-size: 10px;
                            padding: 3px 5px;
                            border-radius: $border-radius-5;
                            text-align: center;
                            color: $white-color;
                            z-index: 1;
                            width: max-content;
                        }
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                transform: rotate(45deg) translateX(-50%);
                background-color: $white-color;
                top: 0;
                left: 50%;
            }
        }
    }
}