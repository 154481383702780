.footer-wrapper {
    .footer-menu {
        flex: 1;
        color: $white-color;

        li {
            word-break: break-word;

            a {
                padding: 5px 0;
                display: block;
                text-decoration: none;
                color: $white-color;

                i {
                    font-size: $font-size-45;
                }

                &:hover {
                    text-decoration: none;
                    opacity: 0.8;
                }
            }
        }

        .fotter-menu-title {
            font-size: $font-size-18;
            letter-spacing: 2px;
            margin-bottom: 15px;
            color: $white-color;
            @extend .font-bold;
            text-transform: uppercase;
        }
    }

    .contact-us-title {
        color: $white-color;
        text-transform: uppercase;
        line-height: 1.3;
        font-size: $font-size-30;
        @extend .font-black;
    }

    form {
        margin-top: 1.5rem;

        .footer-form-group {
            margin-bottom: 20px;

            input,
            textarea {
                background-color: transparent;
                border: none;
                color: $white-color;
                padding-left: 0;
                border-bottom: 1px solid transparentize($color: $white-color, $amount: .5);
                border-radius: 0;
                resize: none;

                &::placeholder {
                    color: $white-color;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .contact-us-title {
            font-size: $font-size-35;
        }
    }

    .service,
    .community {
        li:not(:first-child) {
            display: inline;

            a {
                display: inline !important;
                padding-right: 10px;
            }
        }
    }

    .community {
        li {
            display: block !important;
            position: relative;

            img {
                width: auto;
                opacity: 0.8;
            }
        }
    }

}

.contact-card {

    .contact-card-item {
        margin-bottom: 0;

        a {
            margin-right: 1.25rem;
            transition: 0.3s;

            i {
                font-size: $font-size-45;
                color: $dark-color;
            }

            &:hover {
                text-decoration: none;
                opacity: 0.8;
            }
        }
    }
}

.vnd--poor-footer-contaiber {
    border-top: 1px solid $dark-color-20;
    height: 65px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $body-color;
    position: absolute;
    bottom: 0;

    .vnd--poor-footer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.25rem;
        width: 100%;

        .vnd--poor-footer-item {

            p,
            ul {
                margin: 0;
                font-size: $font-size-14 !important;
                @extend .font-default;

                a {
                    color: $dark-color;

                    &:hover {
                        color: $light-blue;
                        text-decoration: underline !important;
                    }
                }
            }

            ul {
                display: flex;
                list-style: none;
                gap: 0.5rem;
                margin: 0;
            }
        }
    }
}

.vnd--steps-footer {
    border-top: 1px solid $dark-color-20;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    background-color: $white-color;
    z-index: 1000;

    .vnd--steps-footer-description {
        color: $primary-color;
        font-weight: 900;
    }

    .vnd--steps-footer-logo {
        color: $secondary-color;
        // font-weight: 900;
    }
}

.copyright {
    background-color: darken($color: $dark-color, $amount: 5);
    padding: 10px 0;
    text-align: center;

    small {
        margin: 0;
        opacity: 0.6;
        color: $white-color;
        font-size: $font-size-12;
    }
}