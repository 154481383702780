// 
// Sidemenu
// 
.side-menu {
    min-width: 75px;
    max-width: 75px;
    height: 100vh;
    min-height: 570px;
    background-color: $sidebar-bg;
    display: flex;
    z-index: 9;
    box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
    position: fixed;
    left: 0;
    transition: .3s;

    .large-icon {
        display: none !important;
    }

    .small-icon {
        display: block !important;
    }

    .link {
        display: none;
    }

    .pin {
        color: #fff;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;
        background-color: $sidebar-menu-item-active-bg;
        box-sizing: content-box;
    }

    @media (max-width: 1199.98px) {
        bottom: 0;
        height: 60px;
        min-width: 100%;
        min-height: auto;
        display: block;
        border-top: 1px solid $border-color;

        .large-icon,
        .small-icon {
            display: none !important;
        }
    }

    .navbar-brand-box {
        text-align: center;


        @media (max-width: 1199.98px) {
            display: none;
        }

        .logo {
            line-height: 65px;

        }

        .logo-dark {
            display: $display-block;
        }

        .logo-light {
            display: $display-none;
        }
    }

    .theme-mode-icon {
        &:before {
            content: $theme-mode-icon;
        }
    }

    .side-menu-nav {

        .nav-item {
            margin: 7px 0;

            @media (max-width: 1199.98px) {
                flex-basis: 0;
                flex-grow: 1;
                margin: 8px 0;
            }

            .nav-link {
                text-align: center;
                color: $sidebar-menu-item-color;
                margin: 0px auto;
                border-radius: 8px;
                padding: 15px;
                line-height: 1;


                @media (max-width: 1199.98px) {
                    max-width: 80px;
                    padding: 10px 15px;
                }

                &.active {
                    background-color: $sidebar-menu-item-active-bg;
                    color: $primary;
                }
            }

            &.show>.nav-link {
                background-color: $sidebar-menu-item-active-bg;
                color: $primary;
            }
        }

        .profile-user {
            height: 36px;
            width: 36px;
            background-color: $gray-300;
            padding: 3px;
        }
    }



    @media (min-width: 1199.98px) {

        &:hover,
        &.open {
            min-width: 300px;
            transition: .2s;

            .large-icon {
                display: block !important;
            }

            .small-icon {
                display: none !important;
            }

            .nav-item {
                width: 100%;

                .nav-link {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    text-align: start;

                    .link {
                        display: block;
                        color: #FFFFFF;
                        text-transform: uppercase;
                        font-size: 16px;
                        transition: 0.3s;
                        font-weight: bold;
                        line-height: 1.3;
                    }
                }
            }



        }
    }

}