// 
//  User profile details.scss
//

// User profile details

.user-profile-sidebar {
    height: 100vh;
    background-color: $card-bg;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 0px 10px rgba(0 0 0 / 15%);
    border-left: 1px solid $border-color;
    position: relative;
    
    @media (max-width: 1399.98px) {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
    }

    @media (max-width: 575.98px) {
        min-width: 100%;
    }
}

.user-profile-desc {
    height: calc(100vh - 100px);
    max-height: 100%;

    @media (max-width: 991.98px) {
        height: calc(100vh - 124px);
    }
}