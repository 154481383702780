@import url('vars-color.css');
body input::placeholder {
  text-transform: capitalize !important;
}
.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
input::placeholder,
textarea::placeholder {
  text-transform: capitalize;
}
.underline {
  text-decoration: underline !important;
}
.border-initial {
  border: initial !important;
}
.text-initial {
  text-transform: initial;
}
.capitalize:first-letter {
  text-transform: capitalize;
}
.border-radius-10 {
  border-radius: var(--vnd-border-radius-10);
}
.h-100vh {
  height: 100vh !important;
}
button,
a {
  text-transform: capitalize;
}
.box-shadow-none {
  box-shadow: none;
}
.vnd--link-message {
  text-transform: inherit !important;
}
.step-spinner-layer {
  position: absolute !important;
  left: 0;
  border-radius: var(--vnd-border-radius-20);
}
.error-border {
  border: 1px solid var(--vnd-color-danger-100) !important;
}
.error-border-bottom {
  border-bottom: 1px solid var(--vnd-color-danger-100) !important;
}
.red-color {
  color: var(--vnd-color-danger-100) !important;
}
.inbox-users-title,
.my-users-title {
  cursor: pointer;
  text-transform: capitalize;
  padding: 10px 20px;
  margin: 0;
  border-bottom: 2px solid transparent;
  transition: 0.2s;
  border-radius: var(--vnd-border-radius-5);
  margin-right: 10px;
  opacity: 0.7;
  position: relative;
  text-align: center;
}
.inbox-users-title:hover,
.my-users-title:hover {
  border-bottom: 2px solid var(--vnd-color-brand-2-100);
}
.inbox-users-title .title-notification,
.my-users-title .title-notification {
  position: absolute;
  top: 10px;
  right: 3px;
  transform: translateY(-50%);
  background-color: transparent !important;
}
.active-users-blobk-title {
  font-weight: 600;
  opacity: 1;
  color: var(--vnd-color-brand-100) !important;
  background-color: var(--vnd-color-brand-2-100);
}
.alice-carousel__stage-item {
  margin-left: 0 !important;
}
.title-notification {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-notification-item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--vnd-color-success-100);
  position: relative;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes example {
  0% {
    background-color: var(--vnd-color-success-100);
    left: 0px;
    top: 0px;
  }
  25% {
    background-color: white;
    left: 0px;
    top: 0px;
  }
  50% {
    background-color: var(--vnd-color-success-100);
    left: 0px;
    top: 0px;
  }
  75% {
    background-color: white;
    left: 0px;
    top: 0px;
  }
  100% {
    background-color: var(--vnd-color-success-100);
    left: 0px;
    top: 0px;
  }
}
.uploaded-files-wrapper {
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
  overflow-x: hidden;
  max-height: 200px;
  padding: 5px 0;
}
.uploaded-files-wrapper .uploaded-file-item {
  max-width: 25%;
  min-width: 100px;
  margin: 0 5px;
}
.uploaded-files-wrapper .uploaded-file-item span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.uploaded-files-wrapper .uploaded-file {
  max-width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  flex: 14%;
  border-radius: var(--vnd-border-radius-5);
  border: 1px solid var(--vnd-color-gray-100);
  padding: 10px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
}
.uploaded-files-wrapper .uploaded-file .close-icon-block {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--vnd-color-danger-100);
  border: 2px solid var(--vnd-color-white);
  transition: 0.3s;
  cursor: pointer;
  line-height: 1;
  padding: 4px;
}
.uploaded-files-wrapper .uploaded-file .close-icon-block i {
  font-size: 10px;
  color: var(--vnd-color-white);
}
.uploaded-files-wrapper .uploaded-file .close-icon-block svg {
  width: 100%;
  max-height: 100%;
}
.chat-main-container {
  display: flex;
  flex-flow: column;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.circle-picker {
  align-items: center;
  width: auto !important;
}
.circle-picker span div span div {
  border: 1px solid var(--vnd-color-gray-100);
}
.resize-none {
  resize: none;
}
.right-section-spinner-layer {
  width: calc(100% - 300px);
  position: fixed;
  right: 0;
}
.call-message-block .call-message-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.call-message-block svg {
  width: 30px;
  margin-right: 10px;
}
.call-message-block img {
  width: 25px;
  height: 25px;
  position: absolute;
  left: -10px;
  top: -10px;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.call-animation {
  height: 6rem;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 1.5s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes play {
  0% {
    transform: scale(1.1);
  }
  15% {
    box-shadow: 0 0 0 5px #deebf7;
  }
  25% {
    box-shadow: 0 0 0 10px #deebf7, 0 0 0 20px #f4f7fa;
  }
  25% {
    box-shadow: 0 0 0 15px #deebf7, 0 0 0 30px #f4f7fa;
  }
}
.modal-close-block {
  text-align: right;
}
.modal-close-block img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.2s;
}
.modal-close-block img:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
.client-attached-file {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.collapse .card-body {
  max-height: 500px;
}
.collapse .card-body::-webkit-scrollbar {
  width: 6px;
  border-radius: var(--vnd-border-radius-5);
}
.collapse .card-body::-webkit-scrollbar-thumb {
  background-color: var(--vnd-color-gray-150);
  border-radius: var(--vnd-border-radius-5);
}
.vnd-edit-tols img {
  display: block;
  border-radius: var(--vnd-border-radius-50);
  padding: 3px;
  box-sizing: content-box;
  width: 28px;
  cursor: pointer;
}
.vnd-supporter-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid var(--vnd-color-white);
}
.info-alert-container {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-alert-container .info-alert-block {
  padding: 40px;
  z-index: 10;
  background: var(--vnd-color-white);
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
}
.info-alert-container .layer {
  position: absolute;
  background: #000000;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
.info-alert-container .close-icon-block {
  position: absolute;
  right: -20px;
  top: -25px;
}
.info-alert-container .close-icon-block i {
  font-size: 30px;
  cursor: pointer;
  transition: 0.2s;
}
.info-alert-container .close-icon-block i:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
.info-alert-container .payment-modal-status-icon-block {
  display: flex;
  justify-content: center;
}
.info-alert-container .transaction-successful {
  font-size: 80px;
  color: var(--vnd-color-success-150);
  line-height: 1;
}
.info-alert-container .transaction-unsuccessful {
  font-size: 80px;
  color: var(--vnd-color-danger-150);
}
.typing-block {
  position: absolute;
  display: flex;
  align-items: center;
}
.no-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data-container .no-data-wrapper {
  display: block;
  text-align: center;
}
.no-data-container .no-data-wrapper img {
  width: 400px;
}
.active-payment-package {
  border: 1px solid var(--vnd-color-brand-100) !important;
}
.add-domain-plus-block {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none;
}
.add-domain-plus-block img {
  padding: 0;
  margin: 0 6px;
}
.disabled {
  opacity: 0.2;
  pointer-events: none !important;
}
.disabled-month {
  opacity: 0.4;
  pointer-events: none !important;
}
.disabled-month:hover {
  border: 1px solid transparent !important;
}
.ReactModalPortal > div {
  box-sizing: 1005;
}
.issues-block {
  border: 1px solid var(--vnd-color-gray-100);
  border-radius: var(--vnd-border-radius-5);
  margin-bottom: 10px;
}
.issues-block textarea {
  resize: none;
}
.scroll-bottom-icon-block {
  position: absolute;
  max-width: 170px;
  width: fit-content;
  border: 1px solid;
  bottom: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  transition: cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.5s;
  border-color: var(--vnd-color-brand-100);
  background-color: var(--vnd-color-brand-100);
  padding: 3px 15px;
  z-index: 1;
  box-shadow: 0 0 2px 1px var(--vnd-color-white);
}
.scroll-bottom-icon-block:hover {
  transform: scale(1.05);
}
.scroll-bottom-icon-block p {
  color: var(--vnd-color-white);
  margin: 0;
  margin-right: 10px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.scroll-bottom-icon-block i {
  font-size: 18px;
  color: var(--vnd-color-white);
}
.dot-dark {
  background-color: var(--vnd-color-brand-150);
}
.ticket-modal-title-block {
  display: flex;
  justify-content: space-between;
}
.week-days-block {
  border: 1px solid var(--vnd-color-gray-100);
  border-radius: var(--vnd-border-radius-5);
}
.week-days-block .week-days-block-item {
  padding: 10px 20px;
}
.week-days-block .week-days-block-item span {
  font-size: var(--vnd-font-size-16) !important;
}
@media screen and (min-width: 768px) {
  .week-days-block .week-days-block-item {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 768px) {
  .week-days-block .custom-checkbox {
    margin-top: 10px;
  }
}
.join-button {
  width: calc(100% - 30px);
  margin: 30px 0;
}
.user-profile-sidebar {
  max-height: 100%;
  overflow-y: hidden;
}
.vnd-added-item-container {
  max-height: 140px;
  overflow-y: auto;
}
.vnd--avarage-rate {
  color: var(--vnd-color-brand-100);
  text-transform: uppercase;
  font-size: var(--vnd-font-size-18);
}
.emojis-block {
  display: flex;
  flex-wrap: wrap;
}
.emojis-block .active {
  border: 2px solid var(--vnd-color-brand-2-100) !important;
}
.emojis-block .current-emoji-block {
  border-radius: var(--vnd-border-radius-5);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 25px;
  margin: 15px 5px;
  cursor: pointer;
  flex: 1;
  position: relative;
  min-width: 100px;
}
.emojis-block .current-emoji-block .vnd-rating-values {
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px 10px;
}
.emojis-block .current-emoji-block .vnd--rating-count {
  position: absolute;
  bottom: -15px;
  border-radius: 5px;
  background-color: var(--vnd-color-brand-100);
  color: var(--vnd-color-white);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--vnd-color-gray-100);
  padding: 0 11px;
  box-sizing: content-box;
  margin: 0;
  font-size: var(--vnd-font-size-16);
}
.emojis-block .current-emoji-block img {
  width: 50px;
}
.emojis-block .small-emoji-block {
  border-radius: var(--vnd-border-radius-5);
  padding: 15px 10px;
  margin: 15px 5px;
  position: relative;
  justify-content: center;
}
.emojis-block .small-emoji-block .vnd--rating-count-block {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.emojis-block .small-emoji-block .vnd--rating-count {
  border-radius: 5px;
  background-color: var(--vnd-color-brand-100);
  color: var(--vnd-color-white);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--vnd-color-gray-100);
  padding: 0 10px;
  box-sizing: content-box;
  margin: 0;
  font-size: var(--vnd-font-size-16);
}
.emojis-block .small-emoji-block img {
  width: 45px;
}
.sclosed-session-block {
  background-color: var(--vnd-color-brand-100);
}
.sclosed-session-block small {
  color: var(--vnd-color-white);
}
.feedbacks-block img {
  width: 20px;
}
.feedbacks-block .feedback-block {
  border-radius: var(--vnd-border-radius-5);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.rotate-360 {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.modal-content {
  max-height: calc(100vh - 50px);
  max-width: calc(100vw - 50px);
  margin: 0 auto;
  overflow-y: auto;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#draggable {
  position: absolute;
  z-index: 1080;
}
#videoContainer {
  cursor: move;
}
.right .ctext-wrap-content {
  background-color: var(--vnd-color-brand-100) !important;
}
.right .ctext-wrap-content p {
  color: var(--vnd-color-white) !important;
}
.sender .ctext-wrap-content {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--vnd-color-white) !important;
}
#chat-container {
  background-color: #f7f7ff;
  height: 100%;
  overflow: hidden;
  background-image: url('../assets/images/chat-background/chat-background-12.png');
  background-size: 400px 400px;
  background-repeat: repeat;
}
#chat-container .chat-conversation {
  max-height: 100%;
  overflow-y: scroll;
}
.vnd-modal-window .vnd-modal-window-wrapper {
  overflow-y: auto;
}
.custom-accordion .collapse {
  max-height: 500px;
  overflow-y: auto;
}
.custom-accordion .collapse .chat-list {
  padding: 0;
}
.not-connected-supoprter-block {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--vnd-color-brand-2-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 20px;
  z-index: 1080;
}
.not-connected-supoprter-block p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}
.not-connected-supoprter-block i {
  font-size: 20px;
  cursor: pointer;
}
.scale-up-ver-top {
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@media (max-width: 1199.98px) {
  .right-section-spinner-layer {
    width: calc(100% - 75px);
  }
}
@media (max-width: 991.98px) {
  .contact-card {
    margin-top: 20px !important;
  }
}
@media (max-width: 767.98px) {
  .right-section-spinner-layer {
    width: 100%;
  }
  .widget-settings .dropdown-menu-end {
    transform: translate3d(20px, -61px, 0px) !important;
  }
  .dropdown-menu-end {
    transform: translate3d(100px, -61px, 0px) !important;
  }
}
