/*#region form styles*/
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="datetime"],
input[type="date"],
input[type="tel"],
textarea,
.vnd-text-input {
    margin: 0;
    display: block;
    width: 100%;
    color: lighten($black-color, 10%);
    font-size: $font-size-16;
    border-radius: $border-radius-10;
    padding: 14px 20px;
    border: 1px solid $dark-color-10;
    @extend .font-light;

    &::placeholder {
        color: $black-color;
        opacity: 0.3;
        text-transform: initial;
    }

    &:hover {
        border: 1px solid rgba(16, 128, 232, 0.384);
    }

    &:focus {
        outline: none;
        border: 2px solid rgba(16, 128, 232, 0.384);
    }

    &:disabled {
        color: lighten($dark-color, 40%) !important;
        background-color: lighten($dark-color, 96%);
    }
}

.vnd--select {
    .relamy-select__control {
        padding: 9px 10px;
        @extend .font-light;
        border-radius: $border-radius-10;
        border: 1px solid $dark-color-10;

        .relamy-select__single-value {
            color: lighten($black-color, 10%);
        }

        &::placeholder {
            color: $black-color;
            opacity: 0.3;
            text-transform: initial;
        }

        &:hover {
            border: 1px solid rgba(16, 128, 232, 0.384);
            box-shadow: none !important;
        }

        &:focus {
            outline: none;
            border: 2px solid rgba(16, 128, 232, 0.384);
        }

        &:disabled {
            color: lighten($dark-color, 40%) !important;
            background-color: lighten($dark-color, 96%);
        }
    }
}

.vnd-text-input {
    width: 100%;
    background-color: $dark-color;
    border: none;
    @extend .font-default;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $dark-color-30;
    box-shadow: inset 3px 3px 5px rgba($color: $primary-color, $alpha: .15);
    padding-right: 80px;
    border: 1px solid transparent;
}

.invalid-value {
    border: 1.5px solid $danger-color !important;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
}

.form-control-label {
    color: $dark-color;
    font-size: $font-size-13 !important;
    font-weight: 500 !important;
    @extend .font-semi-bold;
}

.vnd-input-description {
    @extend .font-default-itralic;
    font-size: $font-size-12;
}

.form-group {
    label {
        // margin-left: 10px;
        @extend .font-medium;
        font-size: $font-size-14 !important;

        img {
            width: 20px;
            margin-left: 5px;
        }
    }

    .disabled-text {
        margin: 0;
        display: block;
        width: 100%;
        font-size: $font-size-16;
        border-radius: $border-radius-10;
        padding: 13px 20px;
        background-color: lighten($dark-color, 96%);
        border: 2px solid transparent;
        position: relative;

        p,
        pre {
            @extend .font-default;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
            color: $black-color;
            opacity: 0.7;
            font-size: $font-size-16;
            margin-right: 30px;
        }

        img {
            position: absolute;
            background-color: lighten(#000000, 96%);
            padding: 10px;
            box-sizing: content-box;
            cursor: pointer;
            width: 20px;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        #copy {
            cursor: pointer;
        }
    }

    small {
        font-style: italic;
        display: block;
        margin-top: 10px;
        margin-left: 10px;
    }

    .vnd-complexe-input {
        position: relative;

        .vnd-complexe-btn {
            background-color: $primary-color;
            padding: 3px 10px;
            display: block;
            width: 100%;
            position: absolute;
            top: 50%;
            right: 9px;
            width: auto;
            transform: translateY(-50%);
            border-radius: $border-radius-5;
            cursor: pointer;

            img {
                width: 15px;
                display: block;
            }

            &:nth-child(1) {
                background-color: $success-color;
            }
        }

        input {
            padding-right: 100px;
        }
    }

    .faq {
        &.vnd-added-item-container {
            overflow: auto !important;

            .vnd-added-item-wrapper {
                width: fit-content !important;
            }
        }
    }

    .vnd-added-item-container {
        margin-top: 10px;
        display: flex;
        // align-items: flex-start;
        flex-wrap: wrap;
        gap: 5px;

        @media (max-width: 991.98px) and (min-width: 576px) {
            width: 100%;
        }

        .vnd-added-item-inline-wrapper {
            @media (min-width: 992px) {
                width: calc(33.33% - 10px) !important;
            }

            @media (min-width: 768px) {
                width: calc(50% - 10px) !important;
            }
        }

        .vnd-added-item-wrapper {
            // max-width: 400px;
            padding: 15px 20px;
            border: 1px solid $primary-color;
            // margin: 5px;
            border-radius: $border-radius-5;
            color: $primary-color;
            position: relative;
            width: 100%;
            background-color: $white-color;
            // @media (min-width: 992px) {
            //     width: calc(50% - 10px);
            // }

            @media (min-width: 1440px) {
                width: calc(50% - 10px);
            }

            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
            // flex-basis: content;
            // max-width: calc(50% - 10px);
            .option-icons-wrapper {
                // position: absolute;
                // top: 8px;
                // right: 10px;
            }

            img {
                position: absolute;
                width: 12px;
                padding: 5px;
                box-sizing: content-box;
                left: 0px;
                top: 0px;
                cursor: pointer;
            }

            .ri-delete-bin-line {
                color: $danger-color;
            }

            p {
                color: $primary-color;
                @extend .font-default;
                margin-bottom: 0;
            }

            .text {
                margin-right: 2.5rem;
            }

            .group-name {
                // margin-right: 5.5rem;
            }

            small {
                display: block;
                color: $dark-color-50;
                font-weight: 300;
                font-style: normal;
            }
        }
    }
}

.vnd--auth-image {
    max-width: calc(12vw + 150px);
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%);
}

.vnd--alternative-auth-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .vnd--alternative-auth-item {
        @extend .light-brand-background;
        margin: 0.25rem;
        padding: 0.75rem 1.25rem;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        flex: 1;
        border-radius: $border-radius-10;
        cursor: pointer;
        transition: $transition-3;
        border: 1px solid transparent;
        justify-content: center;

        &:hover {
            border: 1px solid $primary-color;
            background-color: transparent !important;
        }

        span {
            margin-left: 0.5rem;
            font-size: $font-size-14;
        }
    }
}

.emoji-block {
    position: absolute;
    right: 0;
    top: 30px;
}

/*#endregion*/