.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}


.loader-line {
    width: 100%;
    height: 3px;
    position: fixed;
    overflow: hidden;
    background-color: $dark-color-10;
    z-index: 10000;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 100%;
    width: 40%;
    background-color: $primary-color;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.active-technical-assistants-cost {
    border-radius: $border-radius-10;
    background-color: $primary-color;
    @extend .font-semi-bold;

    span {
        color: $white-color;
    }
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}

.help-right-menu {
    img {
        max-width: 100%;
    }
}

.change-gradient-colors {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    transform: rotate(45deg);
    cursor: pointer;

    &::after {
        content: "" !important;
        z-index: -1;
    }
}

.user-chat-nav {
    .nav-btn {
        transition: 0.2s;
        border: 1px solid transparent;

        &:hover {
            border-color: $dark-color-50;
        }
    }
}
