@import 'app';

/*#region global styles*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-padding-top: 80px;
}

body {
    margin: 0;
    padding: 0;
    @extend .font-default;
    font-size: $font-size-16;
    background-color: $body-color !important;
}

::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
}

::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
}

.text-underline {
    text-decoration: underline !important;
}

.list-style-disc {
    list-style-type: disc;
}

.list-style-circle {
    list-style-type: circle;
}

.z-index-10 {
    z-index: 10;
}

.capitalize {
    text-transform: capitalize;

    * {
        text-transform: capitalize;
    }
}

.max-h-initial {
    max-height: initial;
}

.vnd-marked {
    display: inline-block;
    width: fit-content;
    position: relative;
    z-index: 2;

    &::before {
        content: '';
        height: fit-content;
        border: 3px solid $warning-color;
        border-top: none;
        display: block;
        width: 80%;
        position: absolute;
        height: 10px;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
        z-index: -1;
    }
}

.cookie-modal {
    .cookie-modal-block {
        background-color: $white-color;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1003;
    }

    &:after {
        content: " ";
        background: $black-color;
        opacity: 0.4;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1002;
    }
}

#main {
    p {
        font-size: $font-size-16;
    }
}

p {
    color: $dark-color;
    margin: 0.5rem 0;
    line-height: 1.7;
    font-size: $font-size-16;
    @extend .font-light;
}

label {
    font-size: $font-size-16 !important;
    @extend .font-default;
}

b {
    font-size: $font-size-18;
    @extend .font-bold;
}

a {
    @extend .font-default;
}

.border-radius-5 {
    border-radius: $border-radius-5 !important;
}

.vnd--icon-wrapper {
    background-color: #EDF4F9;
    padding: 5px 20px;
    border-radius: $border-radius-5;

    svg {
        opacity: 0.5;
        transition: $transition-3;

        * {
            fill: $primary-color;
        }
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
}

@media (max-width: 575.98px) {
    p {
        font-size: $font-size-16;
    }
}

table {
    th {
        @extend .font-semi-bold;
        text-transform: capitalize;
    }

    td {
        min-width: 65px;
    }
}

::-webkit-calendar-picker-indicator {
    filter: invert(86%) sepia(19%) saturate(7499%) hue-rotate(183deg) brightness(108%) contrast(101%);
}

.scroll-top {
    position: fixed;
    right: 20px;
    bottom: 65px;
    padding: 10px;
    border-radius: $border-radius-10;
    box-sizing: content-box;
    background-color: $primary-color;
    z-index: 1002;
    box-shadow: 0 0 2px 1px $white-color;
    cursor: pointer;

    img {
        width: 30px;
    }
}

.vnd-list-menu {
    a {
        color: $dark-color;
        @extend .font-default;
        margin: 0;
        transition: $duration-20;
        display: block;

        &:hover {
            opacity: 0.8;
            transform: translateX(5px)
        }
    }
}

.avatar {
    border: 1px solid $primary-color;
    border-radius: $border-radius-50;
    box-sizing: content-box;
    padding: 4px;

    .vnd-customer-avatar-img {
        border-radius: $border-radius-50;
    }
}

.top-section {
    margin: 100px 0;
    min-height: 550px;
}

.location-to {
    display: flex;
    align-items: center;
    text-decoration: underline;
    display: block;

    img {
        width: 15px;
        margin-left: 5px;
        transition: $border-radius-30;
    }

    &:hover {
        text-decoration: underline;

        img {
            transform: translateX(5px);
        }
    }

    &::first-letter {
        text-transform: capitalize;
    }
}

.backspace {
    padding: 5px 10px;
    @extend .light-brand-background;
    border-radius: $border-radius-5;
    cursor: pointer;
    width: fit-content;

    img {
        width: 10px;
        margin-right: 5px;
    }

    span {
        color: $primary-color;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        font-size: $font-size-14 !important;
        @extend .font-semi-bold;
        opacity: 0.5;
        transition: $transition-3;
    }

    &:hover {

        span {
            opacity: 1;
            text-decoration: none;
        }
    }
}

.flex-1 {
    flex: 1;
}

.text-brand {
    color: $primary-color !important;
}

.position-relative {
    position: relative;
}

.vnd-box-shadow {
    box-shadow: 0px 3px 10px rgba($color: $primary-color, $alpha: .15);
}

.vnd-header-shadow {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    // border-bottom: 0.5px solid rgba(0,0,0,0.1);
}

.vnd-inset-box-shadow {
    box-shadow: inset 3px 3px 5px rgba($color: $primary-color, $alpha: .15);
}

.vnd-body-inset-card {
    @extend .vnd-inset-box-shadow;
    padding: 15px;
    border-radius: $border-radius-10;
    background-color: $body-color;
}

.vnd-p-block {
    padding: 25px;
}

.vnd--list-info {
    list-style: none;
    padding: 0;

    .vnd--list-info-item-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        font-size: $font-size-14;

        .vnd--user-info-item {
            &:first-child {
                @extend .font-light;
            }

            &:last-child {
                @extend .font-bold;
                text-align: end;
            }
        }
    }
}

.vnd-p2-block {
    padding: 10px 20px;
}

.vnd-section-name {
    @extend .font-semi-bold;
    color: $primary-color;
    text-transform: uppercase;
    position: relative;
    margin-top: 15px;
    z-index: 2;

    &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 50px;
        background-color: $primary-color;
        top: -8px;
        left: 0;
    }
}

.vnd-section-title,
.vnd-section-sub-title {
    @extend .font-black;
    color: $dark-color;
    text-transform: uppercase;
    display: block;
    position: relative;
    z-index: 2;
    word-break: break-word;
}



.vnd-block-subtitle {
    text-transform: uppercase;
    color: $dark-color;
    @extend .font-black;
    font-size: $font-size-18;
    opacity: 0.3;
}

.vnd-step {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: $font-size-20;
        text-transform: uppercase;
        @extend .font-black;
        opacity: 0.3;
    }

    a {
        font-weight: 600;
        color: $primary-color;

        &:hover {
            opacity: 0.8;
        }
    }
}

.vnd-laptop-container {
    text-align: center;

    .vnd-laptop-wrapper {
        position: relative;
        width: fit-content;
        margin: 0 auto;

        .position-button {
            background-color: $primary-color;
            position: absolute;
            border-radius: $border-radius-50;
            cursor: pointer;
            opacity: 0.3;
        }

        .active {
            opacity: 1;
            background-color: $secondary-color;
        }
    }
}

.rc-slider {
    .rc-slider-track {
        background-color: $light-blue;
    }

    .rc-slider-handle {
        border-color: $light-blue;

        &.rc-slider-handle-dragging {
            border-color: $secondary-color;
            box-shadow: 0 0 0 5px $secondary-color;
        }
    }
}

.vnd--sample-html {
    width: fit-content;
    text-align: center;
    display: block;
    padding: 15px;
    background-color: $body-color;
    border-radius: 10px;
    box-shadow: inset 3px 3px 5px rgba($color: $primary-color, $alpha: .15);

    img {
        width: 100%;
        max-width: 600px;
        border-radius: 10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .vnd-laptop-wrapper {
        img {
            width: 450px;
        }

        .top-left {
            top: 30px;
            left: 30px;
        }

        .top-center {
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
        }

        .top-right {
            top: 30px;
            right: 30px;
        }

        .bottom-left {
            bottom: 135px;
            left: 30px;
        }

        .bottom-center {
            bottom: 135px;
            left: 50%;
            transform: translate(-50%);
        }

        .bottom-right {
            bottom: 135px;
            right: 30px;
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .vnd-laptop-wrapper {
        img {
            width: 300px;
        }

        .top-left {
            top: 20px;
            left: 20px;
        }

        .top-center {
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
        }

        .top-right {
            top: 20px;
            right: 20px;
        }

        .bottom-left {
            bottom: 90px;
            left: 20px;
        }

        .bottom-center {
            bottom: 90px;
            left: 50%;
            transform: translate(-50%);
        }

        .bottom-right {
            bottom: 90px;
            right: 20px;
        }
    }
}

@media (max-width: 767.98px) {
    .vnd--upp-text-20 {
        font-size: $font-size-18;
    }
}

.vnd-bkg-cover-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.vnd-bkg-contain-img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.vnd-image-dark-layer {
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $dark-color;

        // opacity: 0.1;
        // z-index: -1;
    }
}

.vnd-blur-block {
    position: relative;
    top: 0;
    z-index: 1;
    background: rgba($color: #EDF4F9, $alpha: 0.5);
    backdrop-filter: blur(2px);
    border-radius: $border-radius-10;

    .vnd-blur-block-text-wrapper {
        padding: 10px;
    }
}

@media (min-width: 992px) {
    .group-side-width {
        width: 400px !important;
        position: sticky;
    }
}

.dash-line {
    max-width: 300px;
}

.order-list {
    li {
        position: relative;
        margin-left: 25px;
        font-size: $font-size-16;

        img {
            width: 15px;
            margin-right: 7px;
            position: absolute;
            left: -25px;
            top: 5px;
        }
    }
}

.vnd-side-image-wrapper {
    img {
        width: 100%;
    }
}

.vnd-freelancer-image-wrapper {
    position: relative;

    .vnd-freelancer-left,
    .vnd-freelancer-right,
    .vnd-freelancer-center {
        position: absolute;
        font-size: $font-size-15;
        max-width: 180px;
        text-align: center;
        color: $primary-color;
        font-weight: bold;
    }

    @media screen and (min-width: 992px) {

        .vnd-freelancer-left,
        .vnd-freelancer-right,
        .vnd-freelancer-center {
            font-size: $font-size-20;
        }
    }

    .vnd-freelancer-left {
        left: 0;
        bottom: 35%;
    }

    .vnd-freelancer-center {
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
    }

    .vnd-freelancer-right {
        right: 0;
        top: 30%;
    }
}

/*#endregion*/

/*#region color picker*/
.vnd--color-picker {
    width: 100%;

    .varied-colors {
        border: 1px solid $dark-color-10;
        border-radius: $border-radius-10;
        padding: 10px;
    }

    .base-colors,
    .text-colors,
    .varied-colors {
        overflow: hidden;
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        margin: 10px 0;

        .color,
        .color-var {
            float: left;
            border-radius: 50%;
            cursor: pointer;
        }

        .color {
            transition: all .2s;
            width: 45px;
            height: 45px;
            margin: 10px;
            border: 0.5px solid $dark-color-10;

            &.active {
                transform: scale(1.2, 1.2);
            }
        }

        .color-var {
            transform: scale(0, 0);
            width: 25px;
            height: 25px;
            margin: 10px;

            &.visible {
                transform: scale(1, 1);
            }

            &.active {
                transform: scale(1.2, 1.2);
            }
        }
    }

    .color-picker {
        border-radius: 50%;
        overflow: hidden;
        width: 45px;
        height: 45px;
        margin: 10px 15px;
        position: relative;
        border: 2px solid $dark-color-10;

        &::after {
            content: url(/src/assets/icons/color-picker.svg);
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $white-color;
            border-radius: 50%;
            top: 50%;
            padding: 7px;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }

        input {
            height: 40px;
            padding: 0;
            border: none;
            cursor: pointer;
            visibility: hidden;
        }
    }
}

/*#endregion*/

/*#region bootstrap overrides*/
.dropdown {
    button {
        width: 100%;
        padding: 10px 20px;
        text-align: left;
        background-color: $white-color;
        color: lighten($black-color, 10%);
        border: 1px solid $dark-color-10;

        &:hover {
            background-color: transparent;
            color: lighten($black-color, 10%);
            border: 1px solid rgba(16, 128, 232, 0.384);
        }

        &::after {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        &:focus {
            outline: none;
            border: 1px solid rgba(16, 128, 232, 0.384);
        }
    }
}

.create-ts-btn {
    flex: 0 0 auto;
}

/*#endregion*/

.opacity-0 {
    opacity: 0;
}

.disabled {
    opacity: 0.4;
    pointer-events: none !important;
}

.disabled_04 {
    opacity: 0.4;
    pointer-events: none !important;
}

.react-confirm-alert-svg {
    display: none;
}

.vnd-accordion-left-menu {
    margin: 0;

    .accordion-item {
        background-color: transparent;

        .accordion-collapse {
            margin: 0;
            padding-left: 1.25rem;
            border-radius: $border-radius-10;
            @extend .light-brand-background;

            .accordion-body {
                padding: 0.5rem 1.25rem 0.5rem 0 !important;
            }
        }

        .accordion-header {
            position: relative;
            transition: 0.2s;

            button {
                padding: 0.6em 0;
                background-color: transparent;
                font-size: $font-size-18;
                @extend .font-default;
            }

            &:hover {
                button {
                    color: $primary-color;
                    @extend .font-medium;
                }

                margin-left: 10px;
            }

            &.active-content {
                button {
                    color: $primary-color;
                    @extend .font-medium;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    background-color: $primary-color;
                    border-radius: 50%;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -1rem;
                    line-height: 0;
                }

                margin-left: 10px;
            }

        }

        .without-arrow {
            button {
                &::after {
                    display: none !important;
                }
            }
        }

        .vnd-nav-accordion-body {
            padding-top: 0;

            .vnd-nav-burger-item {
                margin: 7px 0;
            }
        }
    }
}

.custom-accordion {
    h5 {
        @extend .font-default;
    }
}

.session-hide-btn {
    a {
        display: flex;
        background-color: $secondary-color;
        border-radius: $border-radius-5;
        margin-right: 10px;

        i {
            color: $primary-color;
        }
    }
}

.left-bar-header {
    @media (min-width: 767.98px) {
        display: flex;
        justify-content: space-between;
        // max-width: 370px;
    }
}

.vnd--list-group-horizontal {
    list-style: none;
    padding-left: 50px;

    small {
        opacity: 0.7;
    }

    @media (min-width: 1199.98px) {
        display: flex;
        width: 100%;
        padding: inherit;
        justify-content: space-around;
        margin: 0 10px;
    }
}

.page-link {
    border-radius: $border-radius-5;
}

.left-navigation-menu-dropdown {
    position: absolute;
    z-index: 1;
}

.nowrap {
    white-space: nowrap;
}

.content-icon {
    padding: 2px 10px;

    &:hover {
        transform: scale(1.1);
        // transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.2s;
    }
}

.vnd--body-container {
    margin-bottom: 60px;
    overflow: auto;
}

.position-sticky {
    position: sticky;
    top: 0;
}

.vnd--ticket-form {
    form {
        position: absolute;
        width: calc(100% - 48px);
        background-color: $white-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        box-shadow: 0 0 10px rgba($color: $primary-color, $alpha: 0.15);
    }

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $dark-color;
        position: fixed;
        z-index: 1;
        opacity: 0.9;
    }
}

.vnd-added-item-container {
    overflow: initial !important;
    width: 100%;

    @media (max-width: 991.98px) and (min-width: 576px) {
        width: calc(50% - 0.25rem);
        gap: 5px;
    }

    @media (max-width: 1400px) and (min-width: 992px) {
        width: 100%;
    }

    .vnd--primary-bordered-block {
        padding: 10px 25px;
        border: 1px solid $primary-color;
        border-radius: $border-radius-5;
        color: $primary-color;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;

        &.active {
            background-color: $primary-color;
            color: $white-color;
        }
    }
}

.vnd--styled-icon {
    padding: 0px 10px;
    border-radius: $border-radius-5;

    &.vnd--styled-secondary-icon {
        background-color: $secondary-color;
    }

    &.vnd--styled-primary-icon {
        background-color: $primary-color;
        color: $white-color;
    }

    &.vnd--styled-success-icon {
        background-color: $success-color;
        color: $white-color;
    }

    &.vnd--styled-danger-icon {
        background-color: $danger-color;
        color: $white-color;
    }

    &.vnd--styled-warning-icon {
        background-color: $warning-color;
    }
}

.card-body {
    padding: .5rem !important;
}

.user-profie-top-menu {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
}

.vnd--widget-bottom-icon {
    color: $dark-color-30;
}

.attached-session {
    border-color: transparent;
    animation: pulse 1.5s ease-in-out infinite;

    @keyframes pulse {
        0% {
            border-color: transparent;
        }

        50% {
            border-color: $success-color;
        }

        100% {
            border-color: transparent;
        }
    }

}

.schedule-dates-block {
    height: 110px;
    overflow-y: auto;
    padding: 10px 20px !important;
    border-radius: $border-radius-10;
    background-color: #EDF4F9;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    & {
        scrollbar-width: auto;
        scrollbar-color: $dark-color-20 $white-color;
    }

    &::-webkit-scrollbar {
        width: 10px;
        border-radius: $border-radius-5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $dark-color-20;
        border-radius: $border-radius-5;
    }
}

.vnd--fixed-list-menu {
    list-style: none;

    li {
        position: relative;
        transition: .2s;

        a {
            text-decoration: none !important;
            display: block;
            padding: 0.3em 0;
        }

        &:hover {
            margin-left: 1rem;

            a {
                @extend .font-medium;
            }

            &::after {
                content: "";
                position: absolute;
                width: 7px;
                height: 7px;
                background-color: $primary-color;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%);
                left: -1rem;
                line-height: 0;
            }

        }
    }
}

.vnd--global-service-container {
    .vnd--global-services {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .vnd--global-service-item {
            @extend .dark-light-background;
            margin: 0.25rem;
            padding: 0.75rem 1.25rem;
            display: flex;
            flex: 1;
            align-items: center;
            text-transform: capitalize;
            border-radius: $border-radius-10;
            cursor: pointer;
            transition: $transition-3;
            border: 1px solid transparent;
            justify-content: start;
            min-width: 230px;

            @media (min-width: 992px) {
                min-width: calc(33% - 0.5rem);
            }

            .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
                background-color: $success-color;
            }

            .custom-control-label {
                &::before {
                    background-color: $white-color;
                }

                &::after {}
            }

            &:hover {
                background-color: $primary-color !important;
                color: $white-color;
            }

            &.active {
                background-color: $primary-color !important;
                color: $white-color;
            }

            span {
                margin-left: 0.5rem;
                font-size: $font-size-14;
            }
        }
    }
}