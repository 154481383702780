.vnd--title {
    color: $dark-color;
    @extend .font-black;
}

.vnd--upp-text-16 {
    font-size: $font-size-16;
    text-transform: uppercase;
    @extend .vnd--title;
}

.vnd--upp-text-20 {
    margin: 0;
    font-size: $font-size-20;
    text-transform: uppercase;
    @extend .vnd--title;
}

.vnd--upp-text-22 {
    margin: 0;
    font-size: $font-size-22;
    text-transform: uppercase;
    @extend .vnd--title;
}

.vnd--text-24 {
    margin: 0;
    font-size: $font-size-24;
    text-transform: uppercase;
    @extend .vnd--title;
}

.vnd--text-50 {
    font-size: $font-size-50;
    text-transform: capitalize;
    @extend .vnd--title;
}

.vnd--opacity-50 {
    opacity: .5;
}

.vnd--opacity-30 {
    opacity: .3;
}

.vnd--feedback-name {
    font-size: $font-size-20;
    @extend .font-bold;
}

.vnd--text-primary {
    color: $primary-color;
}

.vnd--text-dark {
    color: $dark-color;
}

.vnd--text-success {
    color: $success-color;
}

.vnd--text-warning {
    color: $warning-color;
}

.vnd--text-danger {
    color: $danger-color;
}

.vnd--text-info {
    color: $info-color;
}

.vnd--text-secondary {
    color: $secondary-color;
}

.vnd--section-title-wrapper {
    margin-bottom: 1rem;
}

.word-break-break-all {
    word-break: break-all;
}

.word-break-break-word {
    word-break: break-word
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.white-space-nowrap {
    white-space: nowrap;
}

.inline-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*#region text styles*/
// .vnd--text-primary {
//     color: $primary-color !important;
// }

// .vnd--text-secondary {
//     color: $secondary-color !important;
// }

// .text-white {
//     color: $white-color !important;
// }

// .text-danger {
//     color: $danger-color !important;
// }

// .text-success {
//     color: $success-color !important;
// }

// .text-warning {
//     color: $warning-color !important;
// }

.text-for-copy {
    padding-right: 160px;
    position: relative;
    padding-right: 110px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    button {
        width: fit-content !important;
    }
}

.overflow-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/*#endregion */