.vnd-cover-wrapper {
    position: relative;
    @extend .vnd-bkg-cover-img;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 1;
    background-color: $white-color;

    .vnd-cover-content {
        position: absolute;
        backdrop-filter: blur(3px);
        border-radius: $border-radius-20;
        max-width: 1140px;
        padding: 30px 40px;
        background-color: #ffffff73;
        width: calc(100% - 60px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid $dark-color-20;

        h1,
        p {
            color: $dark-color;
        }

        h1 {
            @extend .font-black;
            line-height: 1.5;

            mark {
                color: $dark-color;
                background-color: $warning-color !important;
                border-radius: $border-radius-15;
            }
        }
    }
}

.vnd-cover-list {
    list-style: none;
    margin-top: 1rem;
    text-align: left;
    padding: 0;
    display: flex;
    align-items: center;

    li {
        margin-right: 1.5rem;
        margin-top: .5rem;
        font-size: $font-size-18;
        @extend .font-light;

        img {
            width: 15px;
            margin-right: 0.25rem;
        }
    }
}