    /*#region partner dsahboard*/
    .vnd--pp-assitents-count-wrapper {
        margin-top: 10px;

        .form-group {
            flex: 0 1 auto;
            margin-right: 15px;

            input {
                width: 100px;
            }
        }

        p {
            margin: 0;
        }

        small {
            flex: 1;
        }
    }

    .vnd--aside-title-block {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        // margin: 0 0 0.5rem 0;

        .slash {
            display: block;
            margin: 0 10px;
        }
    }

    .dashboard-menu-brand-name {
        img {
            max-height: 35px;
        }
    }

    .dashboard-mobile-menu-brand-name {
        text-align: center;

        img {
            max-height: 35px;
        }
    }

    .right-section {
        .vnd-header {
            .vnd-navbar {
                padding: 0 15px;
            }
        }

        //comment by Harut 13.10.2023
        // .top-section {
        //     margin: 75px 0;
        // }

        // .vnd--aside-title-block {
        //     display: flex;
        //     align-items: center;
        //     margin: 0 0 0.5rem 0;

        //     .slash {
        //         display: block;
        //         margin: 0 10px;
        //     }
        // }

        .avatar {
            border: none;
        }

        .vnd-customer-edit-avatar {
            max-width: 500px;
            width: auto;
            height: auto;

            .vnd-customer-avatar-img {
                width: 100%;
                height: 200px;
                margin: auto;
                border-radius: $border-radius-10;
            }

            .vnd-edit-tols {
                position: absolute;
                transform: none;
                // right: 10px;
                // top: 10px;
                right: 0;
                top: 0;
            }
        }

        .vnd--upload-image-btn {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            span {
                margin: 10px;
            }
        }

        .vnd--user-info-block {
            .vnd--user-info-title {
                margin-bottom: 15px;
                @extend .font-medium;

                &::first-letter {
                    text-transform: uppercase;
                    font-size: $font-size-16;
                }
            }
        }

        .vnd--inline-info-list {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 0;

            li {
                // margin-right: 40px;

                span {
                    display: block;
                    line-height: 1;
                    @extend .font-bold;
                    font-size: $font-size-45;
                }
            }
        }
    }

    .dashboard-menu-container {
        .dashboard-menu-wrapper {
            position: fixed;
            background-color: $primary-color;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 1003;
            display: flex;
            flex-direction: column;
            min-width: 75px;

            .dashboard-menu-brand {
                height: 65px;

                .dashboard-mobile-menu-brand-name {
                    text-align: center;

                    img {
                        max-height: 35px;
                    }
                }
            }
        }
    }


    aside.dashboard-menu-container {
        display: block;

        .dashboard-menu-wrapper {
            .dashboard-menu-brand {
                display: block;
                padding: 20px 15px;

            }

            .dashboard-menu {
                list-style: none;
                padding: 0 10px;
                margin: 10px 0;


                .dashboard-menu-item {

                    .dashboard-menu-link,
                    .dashboard-mobile-menu-link {
                        padding: 10px 15px;
                        color: $white-color;
                        text-transform: uppercase;
                        @extend .font-bold;
                        font-size: $font-size-16;
                        transition: 0.3s;
                        border-radius: $border-radius-10;
                        margin: 5px 0;

                        // img {
                        //     width: 25px;
                        // }
                        i {
                            font-size: 24px;
                            color: #6face3;
                            font-weight: 200;
                        }

                        &:hover {
                            background-color: $light-blue;
                        }
                    }

                    .dashboard-menu-link {
                        align-items: center;

                        i {
                            margin-right: 20px;
                        }
                    }

                    .dashboard-mobile-menu-link.active {
                        background-color: $light-blue;
                    }

                    // &:last-child{
                    //     position: absolute;
                    //     bottom: 0;
                    //     width: calc(100% - 20px);
                    // }
                }

                .dashboard-menu-link.active {
                    background-color: $light-blue;
                }
            }
        }
    }

    #agent {
        .dashboard-menu-wrapper {
            .dashboard-menu-brand {
                display: block;
                padding: 20px 15px;
                // margin: 0 auto;

                .dashboard-mobile-menu-brand-name {
                    max-height: 35px;
                }
            }

            .dashboard-menu {
                list-style: none;
                padding: 0 10px;
                text-align: center;

                .dashboard-menu-item {
                    .dashboard-menu-link {
                        // padding: 20px 15px;
                        // color: $white-color;
                        // transition: 0.3s;
                        // border-radius: $border-radius-10;
                        // margin: 5px 0;
                        // display: block;

                        i {
                            font-size: $font-size-24;
                            color: #6face3;
                        }

                        &:hover {
                            background-color: $light-blue;
                        }
                    }
                }

                .dashboard-menu-link.active {
                    background-color: $light-blue;
                }
            }

            .profile-user {
                height: 100% !important;
                width: 100% !important;
                background-color: $icon-color;
                padding: 2px;
            }
        }

        .dropdown-bottom {
            transform: translateY(-45px) !important;
        }

        .chat-search-box {
            .input-group {
                border: 1px solid $dark-color-30;
                border-radius: 5px;

                .form-group {
                    margin: 0;
                    flex: 1;

                    input {
                        border: none;
                    }
                }

                span {
                    padding: 5px 15px;
                    line-height: 2;
                    cursor: pointer;

                    .search-icon-btn {
                        font-size: 16px;
                        position: absolute;
                        right: 13px;
                        top: 0;
                    }
                }
            }

            &.disabled {
                display: none;
            }
        }

        .chat-leftsidebar {
            top: 0;

            .chat-list {

                .cl-chat-wrappers,
                .sup-companies {
                    transition: 0.3s;
                    margin-bottom: 5px;
                    position: relative;
                    background-color: $white-color !important;
                    border-radius: 5px;
                    transform: 0.3s;
                    border: 1px solid $dark-color-10;

                    &:hover {
                        box-shadow: 0 4px 6px -1px rgba($color: $dark-color, $alpha: 0.1), 0 2px 4px -2px rgba($color: $dark-color, $alpha: 0.1);
                    }
                }

                .cl-chat-wrappers {
                    &.active {
                        .cl-chat-wrapper-item {
                            border-left: 4px solid $success-color;
                        }
                    }

                    .vnd--link-message {
                        padding: 3px 0 0 0;
                    }

                    .cl-chat-wrapper-item {
                        border-radius: 0;
                        border-left: 2px solid transparent;
                    }

                    .menu-item-wrapper {

                        // h5{
                        //     text-transform: capitalize;
                        //     font-size: $font-size-16 !important;
                        //     margin-bottom: 0;
                        // }
                        .chat-user-message {
                            margin: 0;
                            opacity: 0.6;
                            font-size: 13px;
                        }
                    }

                    .cl-info-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .sup-companies {
                    .custom-control-wrapper {
                        padding: 10px 20px;
                        border-bottom-left-radius: $border-radius-5;
                        border-bottom-right-radius: $border-radius-5;

                        .custom-control {
                            .custom-control-label {
                                @extend .font-default;
                                font-size: $font-size-14;
                            }
                        }

                        a {
                            font-size: $font-size-14;
                            color: $white-color;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                        }
                    }

                    &.active {
                        .border-bottom {
                            border-bottom: 2px solid $success-color !important;
                        }
                    }

                    // a {
                    //     border-radius: 0;
                    //     border-top: 2px solid transparent;
                    // }
                }
            }

            .card {
                .card-header {
                    padding: 15px 20px;

                    h5 {
                        color: $dark-color;
                    }
                }
            }
        }

        .empty-block-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 700px;
            margin: 0 auto;

            rect {
                fill: transparent;
            }
        }

        @media screen and (max-width: 992px) {
            .empty-block-wrapper {
                display: none !important;
            }
        }

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
        }

        ::-webkit-scrollbar-thumb {
            background-color: $dark-color-20;
            border-radius: $border-radius-5;
        }

        .card {
            .card-header {
                border: 1px solid $dark-color-10;
            }

            .card-body {
                input {
                    padding: 10px 20px;
                    font-size: $font-size-14;
                    border-radius: $border-radius-5;
                }
            }
        }
    }

    /*#endregion*/

    /*#region support dsahboard*/
    .side-menu {
        background-color: $primary-color !important;
    }

    .vw-300 {
        @media (min-width: 1199.98px) {
            width: calc(100vw - 300px) !important;
        }
    }


    /*#endregion*/